export const GET_LIST_REQUEST = "Settlements/GET_LIST_REQUEST";
export const GET_LIST_SUCCESS = "Settlements/GET_LIST_SUCCESS";
export const GET_LIST_FAILURE = "Settlements/GET_LIST_FAILURE";

export const GET_ITEM_REQUEST = "Settlements/GET_REQUEST";
export const GET_ITEM_SUCCESS = "Settlements/GET_SUCCESS";
export const GET_ITEM_FAILURE = "Settlements/GET_FAILURE";

export const ADD_REQUEST = "Settlements/ADD_REQUEST";
export const ADD_SUCCESS = "Settlements/ADD_SUCCESS";
export const ADD_FAILURE = "Settlements/ADD_FAILURE";

export const DELETE_REQUEST = "Settlements/DELETE_REQUEST";
export const DELETE_SUCCESS = "Settlements/DELETE_SUCCESS";
export const DELETE_FAILURE = "Settlements/DELETE_FAILURE";

export const EDIT_REQUEST = "Settlements/EDIT_REQUEST";
export const EDIT_SUCCESS = "Settlements/EDIT_SUCCESS";
export const EDIT_FAILURE = "Settlements/EDIT_FAILURE";