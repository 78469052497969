import { compose, lifecycle, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import { getUsersList } from '../../../../../Store/Actions/Users';
import { getRole, addRole, editRole } from '../../../../../Store/Actions/Roles';
import { getPermissionsList } from '../../../../../Store/Actions/Permissions';

import View from './View';

export default compose(
    connect(
        state => ({
            permissions: state.auth.permissions,
            isAuthenticated: state.auth.isAuthenticated,
            listUsers: state.users.list,
            isLoadingUsers: state.users.isLoading,
            listPermissions: state.permissions.list,
            isLoadingPermissions: state.permissions.isLoading,
            isLoadingItem: state.roles.isLoadingItem,
            error: state.roles.error
        }), { 
            getRole, addRole, editRole,
            getPermissionsList, 
            getUsersList },
    ),
    withState("role", "setRole", {
        id: null,
        name: '',
        users: [],
        permissions: []
    }),
    withState("formType", "setFormType", "show"),
    withHandlers({
        handleSubmit: props => () => {
            switch(props.formType) {
                case 'add':
                    props.addRole(props.role).then(res => { if(res.status == 200 || res.status == 201) window.location.href = `/settings/roles/${res.data.id}` });
                    break;
                case 'edit':
                    props.editRole(props.role).then(res => { if(res.status == 200 || res.status == 201) props.setFormType('show') });
                    break;
            }
        }
    }),
    lifecycle({
        componentDidMount() {
            if(this.props.id){
                this.props.getRole(this.props.id).then(data => {
                    if(data.status == 200) {
                        this.props.setRole({
                        id: data.data.id,
                        name: data.data.name,
                        users: data.data.users.map(item => item.id ),
                        permissions: data.data.permissions.map(item => item.id)
                    });
                }
                });
            } else {
                this.props.setFormType('add');
            }
            this.props.getUsersList({ sort: { column: null, direction: null }, page: 1},true);
            this.props.getPermissionsList();
        }
    })
)(View);