import React from 'react'
import { Button, Form, Menu, Dropdown, Message, Dimmer, Loader, Icon, Container, Header, Breadcrumb } from 'semantic-ui-react'

function CreateWelder(props) {
    if (props.welder == null && props.current != null) {
        props.setWelder(props.current);
        if (props.current.user) props.setUser(props.current.user)
    }
    return (
        <Container>
            <Menu secondary>

                <Breadcrumb>
                    <Breadcrumb.Section link href="/">Главная</Breadcrumb.Section>
                    <Breadcrumb.Divider />
                    <Breadcrumb.Section link href="/welders">Сварочники</Breadcrumb.Section>
                    <Breadcrumb.Section active>{props.welder.name}</Breadcrumb.Section>
                </Breadcrumb>
            </Menu>
            <Menu secondary>
                <Header as='h2'>Редактирование {props.welder.name}</Header>
            </Menu>
            { props.isLoading &&
                <Dimmer active>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
            }
            { props.error && props.error.list &&
                <Message
                    error
                    header='Произошла ошибка:'
                    list={props.error.list}
                />
            }
            { props.welder &&
                <Form>
                    <Form.Input
                        label='Наименование:'
                        placeholder='Наименование:'
                        name='name'
                        value={props.welder.name}
                        onChange={(e, { name, value }) => props.setWelder({ ...props.welder, [name]: value })}
                    />
                    <Form.Input
                        label='Серийный номер:'
                        placeholder='S/N:'
                        name='serial'
                        value={props.welder.serial}
                        onChange={(e, { name, value }) => props.setWelder({ ...props.welder, [name]: value })}
                    />
                    <Form.Field>
                        <label>Ответственный:</label>
                        <Dropdown
                            name='id_user'
                            loading={props.isLoadingUsers}
                            selection
                            search
                            placeholder="Ответственный:"
                            fluid
                            options={props.listUsers.map(item => {
                                return ({
                                    key: item.id,
                                    value: item.id,
                                    text: item.name
                                })
                            })}
                            onChange={(e, { name, value }) => props.setWelder({ ...props.welder, [name]: value })}
                            value={props.welder.id_user}
                        >
                        </Dropdown>
                    </Form.Field>
                    <Button type='submit' onClick={() => { props.handleSubmit() }}><Icon name='save' />Применить</Button>
                </Form>
            }
        </Container>
    )
}

export default CreateWelder