import axios from 'axios';
import {
  GET_LIST_REQUEST,
  GET_LIST_SUCCESS,
  GET_LIST_FAILURE,
  ADD_REQUEST,
  ADD_SUCCESS,
  ADD_FAILURE
} from '../Constants/Comments';

export const getVehicleCommentsList = (id_vehicle, filter = {
  page: 1
}) => dispatch => {
  dispatch({type: GET_LIST_REQUEST});
  axios.get(`/api/vehicles/${id_vehicle}/comments`, {
    params: {
      page: filter.page
    }
  }).then(res => {
        dispatch({
          type: GET_LIST_SUCCESS, 
          data: res.data
        });
      }).catch(function (error) {
        if(error.request.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        dispatch({type: GET_LIST_FAILURE});
    });
};

export const addVehicleComment = (id_vehicle, data) => dispatch => {
  dispatch({type: ADD_REQUEST});
  return axios.post(`/api/vehicles/${id_vehicle}/comments`, data)
      .then(res => {
        dispatch({type: ADD_SUCCESS, item: res.data});
        return res
      }).catch(function (error) {
        if(error.response && error.response.data)
          dispatch({type: ADD_FAILURE, error: error.response.data});
        if(error.response && error.response.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        return error.response
    });
};