import { compose, lifecycle, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import { getVehiclesHistory } from '../../../../Store/Actions/History';

import View from './View';

export default compose(
    connect(
        state => ({
            isAuthenticated: state.auth.isAuthenticated,
            list: state.history.list,
            isLoading: state.history.isLoading,
            total: state.history.total,
            current_page: state.history.current_page,
            last_page: state.history.last_page,
            error: state.history.error
        }), { getVehiclesHistory },
    ),
    withState("filter", "setFilter", {page: 1, dtfrom: null, dtto:null}),
    withState("diffItem", "setDiffItem", null),
    withState("openFilter", "setOpenFilter", false),
    withState("openDiffDialog", "setOpenDiffDialog", false),
    withHandlers({
        handleSubmitFilter: props => () => {
            
        },
        handleShowDiffDialog: props => (item) => {
            props.setDiffItem({
                ...item,
                old_value: JSON.parse(item.old_value),
                new_value: JSON.parse(item.new_value)
            });
            props.setOpenDiffDialog(true);
        },
        handlePaginationChange: props => (e, { activePage }) => {
            props.setFilter({...props.filter, page: activePage})
            props.getVehiclesHistory(props.id, {...props.filter, page: activePage})
        }
    }),
    lifecycle({
        componentDidMount() {
            if(this.props.id){
                this.props.getVehiclesHistory(this.props.id, this.props.filter)
            } 
        }
    })
)(View);