import React from 'react'
import { Button, Form, Message, Icon, Container, Header, List, Segment, Divider, Pagination, Modal } from 'semantic-ui-react'
import DiffTable from './DiffTable'
import moment from 'moment';

function VehiclesHistoryTable(props) {
    return (
        <Segment.Group>
            <Message
                attached
                header={
                    props.openFilter ?
                        <>
                            <Message.Header>
                                <Button circular color='red' icon='cancel' onClick={() => props.setOpenFilter(false)} floated='right' />
                                    История
                            </Message.Header>
                            <Form size="mini" >
                                <Form.Group widths='equal'>
                                    <label>Дата</label>
                                    <Form.Input
                                        type='date'
                                        label='c:'
                                        name='dtfrom'
                                        value={props.filter.dtfrom}
                                        onChange={(e, { name, value }) => props.setFilter({
                                            ...props.filter,
                                            [name]: value
                                        })}
                                    />
                                    <Form.Input
                                        type='date'
                                        label='до:'
                                        name='dtto'
                                        value={props.filter.dtto}
                                        onChange={(e, { name, value }) => props.setFilter({
                                            ...props.filter,
                                            [name]: value
                                        })}
                                    />
                                    
                                </Form.Group>
                                <Button color='blue' icon='search' type='submit' onClick={()=>{props.getWaybillsList(props.id, props.filter)}}>Поиск</Button>
                                <Divider hidden />
                            </Form>
                        </>
                        :
                        <>
                            <Message.Header>
                                <Button circular color='blue' icon='filter' onClick={() => props.setOpenFilter(true)} floated='right' />
                                    История
                            </Message.Header>
                            Всего: {props.total}
                        </>
                }
            />
            { props.error && props.error.list &&
                <Message
                    error attached
                    header='Произошла ошибка:'
                    list={props.error.list}
                />
            }

            { (props.total == 0) &&
                <Segment textAlign='center' loading={props.isLoading} >
                    <Header icon>
                        <Icon name='ban' />
                        Список пуст.
                    </Header>
                </Segment>
            }


            {props.list && (props.total != 0) &&
            <>
            <Segment loading={props.isLoading} >
                <List relaxed>
                    { props.list.map(item =>
                        <List.Item>
                            <List.Content>
                                <List.Header>
                                    {item.user.name}
                                    { (item.new_value || item.old_value) &&
                                        <Button circular icon="search" onClick={() => {props.handleShowDiffDialog(item)}} floated='right' />
                                    }
                                </List.Header>
                                <List.Description>
                                    { moment(item.created_at).format('LLLL') }
                                </List.Description>
                                { item.message }
                            </List.Content>
                            
                        </List.Item>                        
                    )}
                </List>
            </Segment>
            <Message
                attached='bottom'
            >
                <Container textAlign='center'>
                    <Pagination 
                        activePage={props.filter.page} 
                        totalPages={props.last_page}
                        onPageChange={props.handlePaginationChange} 
                        pointing
                        secondary
                    />
                </Container>
            </Message>
            </>
            }
            <Modal
                onClose={() => props.setOpenDiffDialog(false)}
                onOpen={() => props.setOpenDiffDialog(true)}
                open={props.openDiffDialog}
                closeIcon
            >
                <Modal.Header>
                    <Icon name='archive' />
                    Подробно
                </Modal.Header>
                <Modal.Content>
                    { props.openDiffDialog &&
                        <DiffTable old_value={ props.diffItem.old_value } new_value={ props.diffItem.new_value } />
                    }
                </Modal.Content>
            </Modal>
        </Segment.Group>
    )
}

export default VehiclesHistoryTable