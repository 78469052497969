import { compose, lifecycle, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import { getVehiclesList, deleteVehicle, getVehicle, createMaintenance, createRepair, attachPhoto } from '../../../Store/Actions/Vehicles';
import { getUsersList } from '../../../Store/Actions/Users';

import View from './Components';


export default compose(
  connect(
    state => ({
      permissions: state.auth.permissions,
      authUserId: state.auth.id,
      isAuthenticated: state.auth.isAuthenticated,
      listUsers: state.users.list,
      isLoadingUsers: state.vehicles.isLoading,
      current_page: state.vehicles.current_page,
      current: state.vehicles.current,
      list: state.vehicles.list,
      total: state.vehicles.total,
      isLoading: state.vehicles.isLoading,
      isLoadingItem: state.vehicles.isLoadingItem,
      error: state.vehicles.error
    }), { getVehiclesList, deleteVehicle, getVehicle, getUsersList, createMaintenance, createRepair, attachPhoto },
  ),
  withState("filter", "setFilter", {
    sort: {
      column: 'name',
      direction: 'ascending'
    }
  }),
  withState("repair", "setRepair", {
    vehicle: null,
    data: {
      date: new Date(),
      description: '',
      id_user:-1
    }
  }),
  withState("maintenance", "setMaintenance", {
    vehicle: null,
    data: {
      date: new Date(),
      description: '',
      odometer:'',
      id_user:-1
    }
  }),
  withState("photos", "setPhotos", {
    vehicle: null,
    data: null
  }),
  withState("openHistoryDialog", "setOpenHistoryDialog", false),
  withState("openCreateMaintenanceDialog", "setOpenCreateMaintenanceDialog", false),
  withState("openCreateRepairDialog", "setOpenCreateRepairDialog", false),
  withState("openPhotoDialog", "setOpenPhotoDialog", false),
  withState("openAddPhotoDialog", "setOpenAddPhotoDialog", false),
  withState("openAutographMapDialog", "setOpenAutographMapDialog", false),
  withState("AutographMapDialogItem", "setAutographMapDialogItem", null),

  withState("openConfirmDeleteDialog", "setOpenConfirmDeleteDialog", false),
  withState("deletedId", "setDeletedId", -1),
  withHandlers({
    handleSortTableColumn: props => (column) => {
        props.setFilter({
            ...props.filter,
            sort: {
                ...props.filter.sort,
                column: column,
                //direction: props.filter.sort.direction === 'ascending' ? 'descending' : 'ascending'
            }
        });
        props.getVehiclesList({
          ...props.filter,
          sort: {
              ...props.filter.sort,
              column: column,
              //direction: props.filter.sort.direction === 'ascending' ? 'descending' : 'ascending'
          }
      });
    },
    handleSortTableDir: props => (dir) => {
      props.setFilter({
          ...props.filter,
          sort: {
              ...props.filter.sort,
              //column: column,
              direction: dir//props.filter.sort.direction === 'ascending' ? 'descending' : 'ascending'
          }
      });
      props.getVehiclesList({
        ...props.filter,
        sort: {
            ...props.filter.sort,
            //column: column,
            direction: dir//props.filter.sort.direction === 'ascending' ? 'descending' : 'ascending'
        }
    });
    },
    handleCreateRepair: props => () => {
      props.createRepair(props.repair.vehicle.id, props.repair.data).then((ret)=>{
        if(ret) props.setOpenCreateRepairDialog(false)
      })
    },
    handleCreateMaintenance: props => () => {
      props.createMaintenance(props.maintenance.vehicle.id, props.maintenance.data).then((ret)=>{
        if(ret) props.setOpenCreateMaintenanceDialog(false)
      })
    },
    handleAttachPhoto: props => () => {
      props.attachPhoto(props.photos.vehicle.id, props.photos.data).then((ret)=>{
        if(ret.status == 200) { props.setOpenPhotoDialog(false); props.setOpenAddPhotoDialog(false) }
      })
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.getVehiclesList(this.props.filter);
      this.props.getUsersList();
    }
  })
)(View);