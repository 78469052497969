import React from 'react';
import axios from 'axios';
import { Button, Confirm, Container, Header, Icon, Image, Loader, Modal } from 'semantic-ui-react';
import NoImage from '../../../../../Components/Images/no-image.png'

class AxiosImage extends React.Component {
    constructor(props) {
        super(props)
        this.state = { image: null, error: false, isLoading: true, openModal: false, openDeleteConfirm: false };
    }
    componentDidMount() {
        if (!!this.props.id)
            axios.get(`${process.env.REACT_APP_API_URL}/api/attachments/${this.props.id}`, {
                responseType: 'arraybuffer'
            })
                .then(res => { this.setState({ ...this.state, image: Buffer.from(res.data, 'binary').toString('base64'), isLoading: false }) })
                .catch(res => { this.setState({ ...this.state, image: null, isLoading: false, error: true }) })
        else this.setState({ ...this.state, image: null, isLoading: false, error: true })
    }

    deleteAttachment() {
        if (!!this.props.id) {
            this.setState({ ...this.state, isLoading: true })
            axios.delete(`${process.env.REACT_APP_API_URL}/api/attachments/${this.props.id}`)
                .then(res => {
                    this.setState({ ...this.state, image: null, isLoading: false, error: true })
                }).catch(function (error) {
                    this.setState({ ...this.state, isLoading: false })
                });
        }
    }

    render() {
        const { image, error, isLoading, openModal, openDeleteConfirm } = this.state;
        const { openingOnClick } = this.props;
        return (
            <div {...this.props}>
                <Container fluid textAlign='center'>
                    {isLoading &&
                        <Loader active inline />
                    }
                    {error &&
                        <Image
                            src={NoImage}
                            centered
                            verticalAlign='middle'
                        />
                    }
                    {!!image &&
                        <Image
                            src={`data:image/png;base64,${image}`}
                            centered
                            verticalAlign='middle'
                            //size='medium'
                            onClick={() => this.setState({ ...this.state, openModal: true })}
                        />
                    }
                </Container>
                {!!image && openModal && openingOnClick && !error &&
                    <Modal
                        basic
                        onClose={() => this.setState({ ...this.state, openModal: false })}
                        onOpen={() => this.setState({ ...this.state, openModal: true })}
                        open={openModal}
                        closeIcon
                    >
                        <Modal.Content>
                            <Button color='red' inverted onClick={() => this.setState({ ...this.state, openDeleteConfirm: true })}>
                                <Icon name='remove' /> Удалить
                            </Button>
                            <Image
                                src={`data:image/png;base64,${image}`}
                                centered
                                verticalAlign='middle'
                                fluid
                            />
                        </Modal.Content>
                        <Modal.Actions>
                            
                        </Modal.Actions>
                    </Modal>
                }
                {!!image && openDeleteConfirm && !error &&
                    <Confirm
                        open={openDeleteConfirm}
                        content='Вы уверены, что хотите удалить?'
                        onCancel={() => this.setState({ ...this.state, openDeleteConfirm: false })}
                        onConfirm={() => this.deleteAttachment()}
                    />
                }
            </div>
        )

    }
}

export default AxiosImage;