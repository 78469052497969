import { compose, lifecycle, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import { getWaybillAutographData } from '../../../../Store/Actions/Waybills';

import View from './View';

export default compose(
    connect(
        state => ({
            isAuthenticated: state.auth.isAuthenticated,
            permissions: state.auth.permissions
        }), { getWaybillAutographData },
    ),
    withState("isLoading", "setIsLoading", true),
    withState("error", "setError", null),
    withState("autographData", "setAutographData", null),
    lifecycle({
        componentDidMount() {
            if(this.props.id){
                this.props.getWaybillAutographData(this.props.id).then(ret => 
                    { if( ret.status == 200 ) {
                        this.props.setAutographData({...ret.data}); 
                        this.props.setIsLoading(false) }
                      else {
                          this.props.setError(`Error ${ret.status}`)
                          this.props.setIsLoading(false)
                      }
                    })
            } 
        }
    })
)(View);