import { compose, lifecycle, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import { getAutographServersList, deleteAutographServer, syncSchemas, syncDevices } from '../../../../Store/Actions/AutographServers';

import View from './Components';


export default compose(
  connect(
    state => ({
      permissions: state.auth.permissions,
      isAuthenticated: state.auth.isAuthenticated,
      current_page: state.autographServers.current_page,
      current: state.autographServers.current,
      list: state.autographServers.list,
      total: state.autographServers.total,
      last_page: state.autographServers.last_page,
      isLoading: state.autographServers.isLoading,
      error: state.autographServers.error
    }), { getAutographServersList, deleteAutographServer, syncSchemas, syncDevices },
  ),
  withState("filter", "setFilter", {
    sort: {
      column: 'updated_at',
      direction: 'ascending'
    },
    page: 1
  }),

  withState("openConfirmDeleteDialog", "setOpenConfirmDeleteDialog", false),
  withState("deletedId", "setDeletedId", -1),
  withState("loadedServerId", "setLoadedServerId", -1),
  withState("loadedSchemaId", "setLoadedSchemaId", -1),

  withHandlers({
    handleSortTableColumn: props => (column) => {
      props.setFilter({
        ...props.filter,
        sort: {
          ...props.filter.sort,
          column: column,
          //direction: props.filter.sort.direction === 'ascending' ? 'descending' : 'ascending'
        }
      });
      props.getAutographServersList(props.filter);
    },
    handleSortTableDir: props => (dir) => {
      props.setFilter({
        ...props.filter,
        sort: {
          ...props.filter.sort,
          //column: column,
          direction: dir//props.filter.sort.direction === 'ascending' ? 'descending' : 'ascending'
        }
      });
      props.getAutographServersList({
        ...props.filter,
        sort: {
          ...props.filter.sort,
          //column: column,
          direction: dir//props.filter.sort.direction === 'ascending' ? 'descending' : 'ascending'
        }
      });
    },
    handlePaginationChange: props => (e, { activePage }) => {
      props.setFilter({ ...props.filter, page: activePage }, () => { props.getRolesList({ ...props.filter, page: activePage }) })
    },
    syncAutographSchema: props => (id) => {
      props.setLoadedServerId(id);
      props.syncSchemas(id).then(()=>props.setLoadedServerId(-1));
    },
    syncAutographDevices: props => (id) => {
      props.setLoadedSchemaId(id);
      props.syncDevices(id).then(()=>props.setLoadedSchemaId(-1));
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.getAutographServersList(this.props.filter);
    }
  })
)(View);