import { compose, lifecycle, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import { editWelder, getWelder } from '../../../Store/Actions/Welders';
import { getUsersList } from '../../../Store/Actions/Users';

import View from './Components';

export default compose(
  connect(
    state => ({
      isAuthenticated: state.auth.isAuthenticated,
      current: state.welders.current,
      listUsers: state.users.list,
      isLoading: state.welders.isLoading,
      isLoadingUsers: state.users.isLoading,
      error: state.welders.error
    }), { editWelder, getWelder, getUsersList },
  ),
  withState("welder", "setWelder", {
    id: null,
    name: null,
    serial: null,
    countAllWelds: 0,
    growth: 0,
    id_user: null,
    countWeldsElectrode: 0,
    dateReplacementElectrode: null,
    user: {
        id: null,
        name: null,
        email: null,
    },
    history: []
  }),
  withState("user", "setUser", null),
  withHandlers({
    handleSubmit: props => () => {
      props.editWelder(props.welder);
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.getWelder(this.props.match.params.id).then((res)=>{ if(res.status==200 ||res.status==201) this.props.setWelder({...res.data})});
      this.props.getUsersList();
    }
  })
)(View);