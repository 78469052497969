import React from 'react'
import { Button, Form, Menu, Dropdown, Message, Select, Icon, Container, Header, List, Grid, Segment } from 'semantic-ui-react'
import moment from 'moment';

function FormUser(props) {
    return (
        <Segment.Group>
            <Message
                attached
                header={
                    props.formType == 'edit' ?
                        <>
                            <Message.Header>
                                <Button circular color='red' icon='cancel' onClick={() => props.setFormType('show')} floated='right' />
                                    Редактирование
                                </Message.Header>
                            {'id: ' + props.user.id + ' ' + props.user.name}
                        </>
                        : props.formType == 'add' ?
                            <Message.Header>Добавление</Message.Header> :
                            <>
                                <Message.Header>
                                    {(props.permissions.indexOf('update-user') != -1) &&
                                        <Button circular color='blue' icon='edit' onClick={() => props.setFormType('edit')} floated='right' />
                                    }
                                        Просмотр
                                    </Message.Header>
                                {'id: ' + props.user.id + ' ' + props.user.name}
                            </>}
            />
            { props.error && props.error.list &&
                <Message
                    error attached
                    header='Произошла ошибка:'
                    list={props.error.list}
                />
            }
            {props.formType == 'show' ?
                <Segment>
                    <List divided relaxed>
                        <List.Item>
                            <List.Content>
                                <List.Header>Имя (для входа)</List.Header>
                                <List.Description>{props.user.name}</List.Description>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content>
                                <List.Header>ФИО</List.Header>
                                <List.Description>{props.user.fio}</List.Description>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content>
                                <List.Header>Email</List.Header>
                                <List.Description>{props.user.email}</List.Description>
                            </List.Content>
                        </List.Item>

                        <List.Item>
                            <List.Content>
                                <List.Header>Роли</List.Header>
                                <List.Description>
                                    <Dropdown
                                        disabled
                                        name='roles'
                                        value={props.user.roles}
                                        fluid
                                        multiple
                                        placeholder='Роли'
                                        noResultsMessage={
                                            <>
                                                <label>Список пуст. </label>
                                            </>
                                        }
                                        loading={props.isLoadingRoles}
                                        options={props.listRoles.map(item => {
                                            return ({
                                                key: item.id,
                                                value: item.id,
                                                text: item.name
                                            })
                                        })}
                                    />
                                </List.Description>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content>
                                <List.Header>Права</List.Header>
                                <List.Description>
                                    <Dropdown
                                        disabled
                                        name='permissions'
                                        value={props.user.permissions}
                                        fluid
                                        multiple
                                        placeholder='Права'
                                        noResultsMessage={
                                            <>
                                                <label>Список пуст. </label>
                                            </>
                                        }
                                        loading={props.isLoadingPermissions}
                                        options={props.listPermissions.map(item => {
                                            return ({
                                                key: item.id,
                                                value: item.id,
                                                text: item.name
                                            })
                                        })}
                                    />
                                </List.Description>
                            </List.Content>
                        </List.Item>

                        {props.user.driving_licence &&
                            <List.Item>
                                <List.Content>
                                    <List.Header>Водительское удостоверение</List.Header>
                                    <List.Description>
                                        {`№ ${props.user.driving_licence.number} (${moment(props.user.driving_licence.from).format('DD.MM.YYYY')} - ${moment(props.user.driving_licence.to).format('DD.MM.YYYY')})`}
                                    </List.Description>
                                </List.Content>
                            </List.Item>
                        }
                    </List>
                </Segment>
                :
                <Form loading={props.isLoadingItem} className='attached fluid segment'>
                    <Form.Input
                        label='Имя(для входа):'
                        placeholder='Имя:'
                        name='name'
                        value={props.user.name}
                        onChange={(e, { name, value }) => props.setUser({ ...props.user, [name]: value })}
                    />
                    <Form.Input
                        label='ФИО:'
                        placeholder='ФИО:'
                        name='fio'
                        value={props.user.fio}
                        onChange={(e, { name, value }) => props.setUser({ ...props.user, [name]: value })}
                    />
                    <Form.Input
                        label='Email:'
                        placeholder='Email:'
                        name='email'
                        value={props.user.email}
                        onChange={(e, { name, value }) => props.setUser({ ...props.user, [name]: value })}
                    />
                    {(props.formType == 'add' || props.user.changePassword) &&
                        <>
                        <Form.Input
                            label={(props.formType == 'edit')?'Новый пароль:':'Пароль:'}
                            placeholder='Пароль:'
                            name='password'
                            value={props.user.password}
                            onChange={(e, { name, value }) => props.setUser({ ...props.user, [name]: value })}
                            action={<Button
                                icon='random'
                                onClick={() => { props.genPassword() }}
                            />}
                            actionPosition='rigth'
                        />
                        { props.formType == 'edit' &&
                            <Button basic color='red' onClick={() => {props.setUser({...props.user, changePassword: false})}}>Отмена</Button>
                        }
                        </>
                    }
                    { props.formType == 'edit' && !props.user.changePassword &&
                        <Button basic color='green' onClick={() => {props.setUser({...props.user, changePassword: true})}}>Изменить пароль</Button>
                    }
                    <Form.Field>
                        <label>Роли:</label>
                        <Dropdown
                            name='roles'
                            loading={props.isLoadingRoles}
                            multiple
                            selection
                            search
                            placeholder="Роли:"
                            fluid
                            options={props.listRoles.map(item => {
                                return ({
                                    key: item.id,
                                    value: item.id,
                                    text: item.name
                                })
                            })}
                            onChange={(e, { name, value }) => props.setUser({ ...props.user, [name]: value })}
                            value={props.user.roles}
                        >
                        </Dropdown>
                    </Form.Field>

                    <Form.Field>
                        <label>Права:</label>
                        <Dropdown
                            name='permissions'
                            loading={props.isLoadingRoles}
                            multiple
                            selection
                            search
                            placeholder="Права:"
                            fluid
                            options={props.listPermissions.map(item => {
                                return ({
                                    key: item.id,
                                    value: item.id,
                                    text: item.name
                                })
                            })}
                            onChange={(e, { name, value }) => props.setUser({ ...props.user, [name]: value })}
                            value={props.user.permissions}
                        >
                        </Dropdown>
                    </Form.Field>

                    <Form.Field>
                        <label>Водительское удостоверение</label>

                        {props.user.driving_licence == null &&
                            < Button floated='right' color='green' circular icon='add' onClick={() => {
                                props.setUser({
                                    ...props.user,
                                    driving_licence: {
                                        from: null,
                                        to: null,
                                        number: ''
                                    }
                                })
                            }} />
                        }
                    </Form.Field>

                    {props.user.driving_licence &&
                        <>
                            <Form.Input
                                label='Номер:'
                                placeholder='00 00 000000'
                                name='number'
                                value={props.user.driving_licence.number}
                                onChange={(e, { name, value }) => props.setUser({ ...props.user, driving_licence: { ...props.user.driving_licence, [name]: value } })}
                            />
                            <label>Срок действия</label>
                            <Form.Input
                                label='С:'
                                type='date'
                                name='from'
                                value={props.user.driving_licence.from}
                                onChange={(e, { name, value }) => props.setUser({ ...props.user, driving_licence: { ...props.user.driving_licence, [name]: value } })}
                            />
                            <Form.Input
                                label='До:'
                                type='date'
                                name='to'
                                value={props.user.driving_licence.to}
                                onChange={(e, { name, value }) => props.setUser({ ...props.user, driving_licence: { ...props.user.driving_licence, [name]: value } })}
                            />
                        </>
                    }

                    <Button color='blue' type='submit' fluid onClick={() => { props.handleSubmit() }}> <Icon name='save' />Применить</Button>
                </Form>
            }
        </Segment.Group>
    )
}

export default FormUser