import { compose, lifecycle, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import { getUser, addUser, editUser } from '../../../../../Store/Actions/Users';
import { getRolesList } from '../../../../../Store/Actions/Roles';
import { getPermissionsList } from '../../../../../Store/Actions/Permissions';

import View from './View';
import { useHistory } from 'react-router-dom';

export default compose(
    connect(
        state => ({
            permissions: state.auth.permissions,
            isAuthenticated: state.auth.isAuthenticated,
            listRoles: state.roles.list,
            isLoadingRoles: state.roles.isLoading,
            listPermissions: state.permissions.list,
            isLoadingPermissions: state.permissions.isLoading,
            isLoadingItem: state.users.isLoadingItem,
            error: state.users.error
        }), { getUser, addUser, editUser, getRolesList, getPermissionsList },
    ),
    withState("user", "setUser", {
        id: null,
        name: '',
        fio: '',
        email: '',
        password: '',
        roles: [],
        permissions: [],
        driving_licence: null,
        changePassword: false,
    }),
    withState("formType", "setFormType", "show"),
    withHandlers({
        handleSubmit: props => () => {
            switch (props.formType) {
                case 'add':
                    props.addUser(props.user).then(res => { if (res.status == 200 || res.status == 201) window.location.href = `/settings/users/${res.data.id}` });
                    break;
                case 'edit':
                    props.editUser(props.user).then(res => { if (res.status == 200 || res.status == 201) window.location.href = `/settings/users/${res.data.id}` });
                    break;
            }
        },
        genPassword: props => () => {
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for (var i = 0; i < 10; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            props.setUser({ ...props.user, password: result })
        }
    }),
    lifecycle({
        componentDidMount() {
            if (this.props.id) {
                this.props.getUser(this.props.id).then(data => {
                    if (data.status == 200) {
                        this.props.setUser({
                            ...this.props.user,
                            id: data.data.id,
                            name: data.data.name,
                            fio: data.data.fio,
                            email: data.data.email,
                            roles: data.data.roles.map(role => role.id),
                            permissions: data.data.permissions.map(item => item.id),
                            driving_licence: data.data.driving_licence,
                        });
                    }
                });
            } else {
                this.props.setFormType('add');
            }
            this.props.getRolesList();
            this.props.getPermissionsList();
        }
    })
)(View);