import {
    GET_LIST_REQUEST,
    GET_LIST_SUCCESS,
    GET_LIST_FAILURE,
    ADD_REQUEST,
    ADD_SUCCESS,
    ADD_FAILURE,
    DELETE_SUCCESS,
    GET_ITEM_SUCCESS,
    GET_ITEM_REQUEST,
    GET_ITEM_FAILURE
} from '../Constants/AutographServers';

export const initialState = {
    current_page: 1,
    last_page: 1,
    list: [],
    total: 0,
    isLoading: false,
    error: {
        message: null,
        list: null
    }
};

export default function AutographServersReducer(state = initialState, action) {
    switch (action.type) {
        case GET_LIST_SUCCESS:
            return {
                ...state,
                current_page: action.data.current_page,
                last_page: action.data.last_page,
                list: action.data.data,
                total: action.data.total,
                isLoading: false
            };
        case GET_LIST_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case GET_LIST_FAILURE:
            return {
                ...state,
                current_page: 1,
                last_page: 1,
                list: [],
                total: 0,
                isLoading: false,
                error: action.data.error
            };

        case GET_ITEM_SUCCESS:
            return {
                ...state,
                current: action.data,
                isLoadingItem: false
            };
        case GET_ITEM_REQUEST:
            return {
                ...state,
                isLoadingItem: true
            };
        case GET_ITEM_FAILURE:
            return {
                ...state,
                isLoadingItem: false,
                error: action.data.error
            };

        case ADD_SUCCESS:
            state.list.push(action.item);
            return {
                ...state,
                list: state.list,
                isLoading: false
            };
        case ADD_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case ADD_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };

        case DELETE_SUCCESS:
            var dev = [];
            state.list.map(item => {
                if (Number(item.id) !== Number(action.id))
                    dev.push(item);
            });
            return {
                ...state,
                isLoading: false,
                list: dev,
            };

        default:
            return state;
    }
}