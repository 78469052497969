import { compose, lifecycle, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import { addWelder } from '../../../Store/Actions/Welders';
import { getUsersList } from '../../../Store/Actions/Users';

import View from './Components';

export default compose(
  connect(
    state => ({
      isAuthenticated: state.auth.isAuthenticated,
      listUsers: state.users.list,
      isLoadingUsers: state.welders.isLoading,
      error: state.welders.error
    }), { addWelder, getUsersList },
  ),
  withState("welder", "setWelder", {
    name: '',
    serial: '',
    id_user: null
  }),
  withState("user", "setUser", null),
  withHandlers({
    handleSubmit: props => () => {
      props.addWelder(props.welder);
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.getUsersList();
    }
  })
)(View);