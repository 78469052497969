import { compose, lifecycle, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import { createWaybill } from '../../../Store/Actions/Waybills';
import { getVehicle } from '../../../Store/Actions/Vehicles';
import { addSettlement, getSettlementsList } from '../../../Store/Actions/Settlements';

import View from './Components';

export default compose(
  connect(
    state => ({
      isAuthenticated: state.auth.isAuthenticated,
      isLoading: state.vehicles.isLoading,
      error: state.waybills.error,

      listSettlements: state.settlements.list,
      isLoadingSettlements: state.settlements.isLoading,
    }), { createWaybill, addSettlement, getSettlementsList, getVehicle },
  ),
  withState("waybill", "setWaybill", {
    fuel: '',
    odometer: '',
    fuel_add: 0,
    idle: 0,
    comment: '',
    isCity: false,
    date: new Date(), 
    route_list: []
  }),
  withState("openWaybillRouteForm", "setOpenWaybillRouteForm", null),
  withState("newRoute", "setNewRoute", null),
  withState("vehicle", "setVehicle", null),
  withHandlers({
    handleSubmit: props => () => {
      props.createWaybill(props.match.params.id, props.waybill)
    },
    handleOpenAddWaybillRoute: props => () => {

    },
  }),
  lifecycle({
    componentDidMount(props) {
      this.props.getVehicle(this.props.match.params.id).then(data => {
        data.status == 200 && this.props.setVehicle(data.data)
      });
    }
  })
)(View);

/*
{
    date: null,
    id_settlement_from: null, 
    id_settlement_to: null, 
    description: '',
    mileage: 0, 
    time_from_hour: 0,
    time_from_min: 0,
    time_to_hour: 0,
    time_to_min: 0
  }
*/