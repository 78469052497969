import React from 'react'
import { Menu, Container, Breadcrumb } from 'semantic-ui-react'
import UserForm from '../../Components/Form'

function CreateVehicle(props) {

    return (
        <Container>
            <Menu secondary>
                <Breadcrumb>
                    <Breadcrumb.Section link href="/">Главная</Breadcrumb.Section>
                    <Breadcrumb.Divider />
                    <Breadcrumb.Section link href="/settings/roles">Роли</Breadcrumb.Section>
                    <Breadcrumb.Divider />
                    <Breadcrumb.Section active>Добавление роли</Breadcrumb.Section>
                </Breadcrumb>
            </Menu>
            <UserForm />
        </Container>
    )
}

export default CreateVehicle