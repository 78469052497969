import { compose, lifecycle, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import { getWaybillsList, getWaybillAutographData } from '../../../../Store/Actions/Waybills';

import View from './View';

export default compose(
    connect(
        state => ({
            isAuthenticated: state.auth.isAuthenticated,
            permissions: state.auth.permissions,
            list: state.waybills.list,
            isLoading: state.waybills.isLoading,
            total: state.waybills.total,
            current_page: state.waybills.current_page,
            last_page: state.waybills.last_page,
            error: state.waybills.error
        }), { getWaybillsList },
    ),
    withState("filter", "setFilter", {page: 1, dtfrom: null, dtto:null}),
    withState("openFilter", "setOpenFilter", false),
    withHandlers({
        handleSubmitFilter: props => () => {
            props.getWaybillsList(props.id, props.filter)
        },
        handleWaybillsPaginationChange: props => (e, { activePage }) => {
            props.setFilter({...props.filter, page: activePage})
            props.getWaybillsList(props.id, {...props.filter, page: activePage})
        }
    }),
    lifecycle({
        componentDidMount() {
            if(this.props.id){
                this.props.getWaybillsList(this.props.id, this.props.filter)
            } 
        }
    })
)(View);