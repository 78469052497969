import { compose, lifecycle, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import { getWelder, getWeldersList, deleteWelder } from '../../../Store/Actions/Welders';

import View from './Components';


export default compose(
  connect(
    state => ({
      permissions: state.auth.permissions,
      authUserId: state.auth.id,
      isAuthenticated: state.auth.isAuthenticated,
      current_page: state.welders.current_page,
      current: state.welders.current,
      list: state.welders.list,
      total: state.welders.total,
      isLoading: state.welders.isLoading,
      error: state.welders.error
    }), { getWeldersList, deleteWelder, getWelder },
  ),
  withState("filter", "setFilter", {
    sort: {
      column: 'updated_at',
      direction: 'ascending'
    }
  }),
  withState("openHistoryDialog", "setOpenHistoryDialog", false),

  withState("openConfirmDeleteDialog", "setOpenConfirmDeleteDialog", false),
  withState("deletedId", "setDeletedId", -1),
  withHandlers({
    handleSortTableColumn: props => (column) => {
        props.setFilter({
            ...props.filter,
            sort: {
                ...props.filter.sort,
                column: column,
                //direction: props.filter.sort.direction === 'ascending' ? 'descending' : 'ascending'
            }
        });
        props.getWeldersList(props.filter);
    },
    handleSortTableDir: props => (dir) => {
      props.setFilter({
          ...props.filter,
          sort: {
              ...props.filter.sort,
              //column: column,
              direction: dir//props.filter.sort.direction === 'ascending' ? 'descending' : 'ascending'
          }
      });
      props.getWeldersList(props.filter);
  }
  }),
  lifecycle({
    componentDidMount() {
      this.props.getWeldersList(this.props.filter);
    }
  })
)(View);