import React from 'react'
import { Button, Form, Menu, Dropdown, Message, Select, Icon, Container, Header, Breadcrumb } from 'semantic-ui-react'
import VehicleForm from '../../Components/Form'

function CreateVehicle(props) {

    return (
        <Container>
            <Menu secondary>
                <Breadcrumb>
                    <Breadcrumb.Section link href="/">Главная</Breadcrumb.Section>
                    <Breadcrumb.Divider />
                    <Breadcrumb.Section link href="/vehicles">Транспорт</Breadcrumb.Section>
                    <Breadcrumb.Divider />
                    <Breadcrumb.Section active>Добавление транспорта</Breadcrumb.Section>
                </Breadcrumb>
            </Menu>
            <VehicleForm />
        </Container>
    )
}

export default CreateVehicle