import React from 'react'
import { Button, Form, Menu, Dropdown, Message, Select, Icon, Container, Header, List, Grid, Segment } from 'semantic-ui-react'

function FormVehicle(props) {
    const dayOptions = [
        { key: '01', value: '01', text: '1' },
        { key: '02', value: '02', text: '2' },
        { key: '03', value: '03', text: '3' },
        { key: '04', value: '04', text: '4' },
        { key: '05', value: '05', text: '5' },
        { key: '06', value: '06', text: '6' },
        { key: '07', value: '07', text: '7' },
        { key: '08', value: '08', text: '8' },
        { key: '09', value: '09', text: '9' },
        { key: '10', value: '10', text: '10' },
        { key: '11', value: '11', text: '11' },
        { key: '12', value: '12', text: '12' },
        { key: '13', value: '13', text: '13' },
        { key: '14', value: '14', text: '14' },
        { key: '15', value: '15', text: '15' },
        { key: '16', value: '16', text: '16' },
        { key: '17', value: '17', text: '17' },
        { key: '18', value: '18', text: '18' },
        { key: '19', value: '19', text: '19' },
        { key: '20', value: '20', text: '20' },
        { key: '21', value: '21', text: '21' },
        { key: '22', value: '22', text: '22' },
        { key: '23', value: '23', text: '23' },
        { key: '24', value: '24', text: '24' },
        { key: '25', value: '25', text: '25' },
        { key: '26', value: '26', text: '26' },
        { key: '27', value: '27', text: '27' },
        { key: '28', value: '28', text: '28' },
        { key: '29', value: '29', text: '29' },
        { key: '30', value: '30', text: '30' },
        { key: '31', value: '31', text: '31' },
    ]
    const monthOptions = [
        { key: '01', value: '01', text: 'Январь' },
        { key: '02', value: '02', text: 'Февраль' },
        { key: '03', value: '03', text: 'Март' },
        { key: '04', value: '04', text: 'Апрель' },
        { key: '05', value: '05', text: 'Май' },
        { key: '06', value: '06', text: 'Июнь' },
        { key: '07', value: '07', text: 'Июль' },
        { key: '08', value: '08', text: 'Август' },
        { key: '09', value: '09', text: 'Сентябрь' },
        { key: '10', value: '10', text: 'Октябрь' },
        { key: '11', value: '11', text: 'Ноябрь' },
        { key: '12', value: '12', text: 'Декабрь' }
    ]
    
    return (
        <Segment.Group>
            <Message
                attached
                header={
                    props.formType == 'edit' ?
                        <>
                            <Message.Header>
                                <Button circular color='red' icon='cancel' onClick={() => props.setFormType('show')} floated='right' />
                                    Редактирование
                                </Message.Header>
                            {'id: ' + props.vehicle.id + ' ' + props.vehicle.vehicle_name}
                        </>
                        : props.formType == 'add' ?
                            <Message.Header>Добавление</Message.Header> :
                            <>
                                <Message.Header>
                                    {(props.permissions.indexOf('update-vehicle') != -1 || (props.authUserId == props.vehicle.id && props.permissions.indexOf('update-own-vehicle') != -1)) &&
                                        <Button circular color='blue' icon='edit' onClick={() => props.setFormType('edit')} floated='right' />
                                    }
                                        Просмотр
                                    </Message.Header>
                                {'id: ' + props.vehicle.id + ' ' + props.vehicle.vehicle_name}
                            </>}
            />
            { props.error && props.error.list &&
                <Message
                    error attached
                    header='Произошла ошибка:'
                    list={props.error.list}
                />
            }
            {props.formType == 'show' ?
                <Segment>
                    <List divided relaxed>
                        {!!props.autograph &&
                            <List.Item>
                                <List.Content>
                                    <List.Header>Autograph</List.Header>
                                    <List.Description>{`${props.autograph.name} (S/N ${props.autograph.serial})`}</List.Description>
                                </List.Content>
                            </List.Item>
                        }
                        <List.Item>
                            <List.Content>
                                <List.Header>Наименование</List.Header>
                                <List.Description>{props.vehicle.vehicle_name}</List.Description>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content>
                                <List.Header>Рег. номер</List.Header>
                                <List.Description>{props.vehicle.regNumber}</List.Description>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content>
                                <List.Header>Вид топлива</List.Header>
                                <List.Description>{props.vehicle.fuel_type}</List.Description>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content>
                                <List.Header>Ответственный</List.Header>
                                <List.Description>
                                    {props.user.name}
                                </List.Description>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content>
                                <List.Header>НП</List.Header>
                                <List.Description>
                                    {props.settlement && props.settlement.name ? props.settlement.name : <><Icon name='attention' color='red'/>Не заполнено</>}
                                </List.Description>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content>
                                <List.Header>Собственник</List.Header>
                                <List.Description>
                                    {props.organization && props.organization.name ? props.organization.name : <><Icon name='attention' color='red'/>Не заполнено</>}
                                </List.Description>
                            </List.Content>
                           
                        </List.Item>
                        {props.permissions.indexOf('read-norm') != -1 &&
                            <>
                                <List.Item>
                                    <List.Content>
                                        <List.Header>Наименование нормы</List.Header>
                                        <List.Description>{props.vehicle.norm_name}</List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Content>
                                        <List.Header>Зима</List.Header>
                                        <List.Description>с {props.vehicle.winter_from_day}.{props.vehicle.winter_from_month} до {props.vehicle.winter_to_day}.{props.vehicle.winter_to_month}</List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Content>
                                        <List.Header>B зимний период л/100 км в городском цикле</List.Header>
                                        <List.Description>{props.vehicle.winter_city}</List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Content>
                                        <List.Header>B зимний период л/100 км в загородном цикле</List.Header>
                                        <List.Description>{props.vehicle.winter_highway}</List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Content>
                                        <List.Header>B летний период л/100 км в городском цикле</List.Header>
                                        <List.Description>{props.vehicle.summer_city}</List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Content>
                                        <List.Header>B летний период л/100 км в загородном цикле</List.Header>
                                        <List.Description>{props.vehicle.summer_highway}</List.Description>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Content>
                                        <List.Header>Холостой ход(л/ч)</List.Header>
                                        <List.Description>{props.vehicle.idle}</List.Description>
                                    </List.Content>
                                </List.Item>
                            </>
                        }
                    </List>
                </Segment>
                :
                <Form loading={props.isLoadingItem} className='attached fluid segment'>
                    <Form.Field>
                        <label>Autograph:</label>
                        <Dropdown
                            name='AutographDevice_id'
                            loading={props.isLoadingAutographDevices}
                            selection
                            search
                            placeholder="Autograph"
                            fluid
                            options={props.listAutographDevices.map(item => {
                                return ({
                                    key: item.id,
                                    value: item.id,
                                    text: `${item.name} (S/N ${item.serial})`
                                })
                            })}
                            onChange={(e, { name, value }) => props.setVehicle({ ...props.vehicle, [name]: value })}
                            value={props.vehicle.AutographDevice_id}
                        >
                        </Dropdown>
                    </Form.Field>
                    <Form.Input
                        label='Наименование:'
                        placeholder='Наименование:'
                        name='vehicle_name'
                        value={props.vehicle.vehicle_name}
                        onChange={(e, { name, value }) => props.setVehicle({ ...props.vehicle, [name]: value })}
                    />
                    <Form.Input
                        label='Рег. номер:'
                        placeholder='Рег. номер:'
                        name='regNumber'
                        value={props.vehicle.regNumber}
                        onChange={(e, { name, value }) => props.setVehicle({ ...props.vehicle, [name]: value })}
                    />
                    <Form.Input
                        label='Вид топлива:'
                        placeholder='АИ-92, ДТ...'
                        name='fuel_type'
                        value={props.vehicle.fuel_type}
                        onChange={(e, { name, value }) => props.setVehicle({ ...props.vehicle, [name]: value })}
                    />
                    <Form.Field>
                        <label>Ответственный:</label>
                        <Dropdown
                            name='id_user'
                            loading={props.isLoadingUsers}
                            selection
                            search
                            placeholder="Ответственный:"
                            fluid
                            options={props.listUsers.map(item => {
                                return ({
                                    key: item.id,
                                    value: item.id,
                                    text: item.name
                                })
                            })}
                            onChange={(e, { name, value }) => {
                                props.setVehicle({ ...props.vehicle, [name]: value }); 
                                props.setUser(props.listUsers.filter(item => item.id == value)[0]);
                            }}
                            value={props.vehicle.id_user}
                        >
                        </Dropdown>
                    </Form.Field>

                    <Form.Field inline>
                        <label>НП:</label>

                        <Dropdown
                            name='id_settlement'
                            value={props.vehicle.id_settlement}
                            fluid
                            search
                            selection
                            placeholder='НП'
                            noResultsMessage={
                                <>
                                    <label>Список пуст. </label>
                                    {props.dropdownSettlements.searchQuery !== '' && <Button basic onClick={props.createSettlement}><Icon name='plus' />Добавить '{props.dropdownSettlements.searchQuery}'</Button>}
                                </>
                            }
                            loading={props.isLoadingSettlements}
                            options={props.listSettlements.map(item => {
                                return ({
                                    key: item.id,
                                    value: item.id,
                                    text: item.name
                                })
                            })}
                            onChange={(e, { name, value }) => props.setVehicle({ ...props.vehicle, [name]: value })}
                            onSearchChange={(e, { searchQuery }) => props.setDropdownSettlements({ ...props.dropdownSettlements, searchQuery: searchQuery })}
                            value={props.vehicle.id_settlement}
                        >
                        </Dropdown>
                    </Form.Field>

                    <Form.Field inline>
                        <label>Собственник:</label>

                        <Dropdown
                            name='id_organization'
                            value={props.vehicle.id_organization}
                            fluid
                            search
                            selection
                            placeholder='Собственник'
                            noResultsMessage={
                                <>
                                    <label>Список пуст. </label>
                                    {props.dropdownOrganizations.searchQuery !== '' && <Button basic onClick={props.createOrganization}><Icon name='plus' />Добавить '{props.dropdownOrganizations.searchQuery}'</Button>}
                                </>
                            }
                            loading={props.isLoadingOrganizations}
                            options={props.listOrganizations.map(item => {
                                return ({
                                    key: item.id,
                                    value: item.id,
                                    text: item.name
                                })
                            })}
                            onChange={(e, { name, value }) => props.setVehicle({ ...props.vehicle, [name]: value })}
                            onSearchChange={(e, { searchQuery }) => props.setDropdownOrganizations({ ...props.dropdownOrganizations, searchQuery: searchQuery })}
                            value={props.vehicle.id_organization}
                        >
                        </Dropdown>
                    </Form.Field>

                    {props.permissions.indexOf('update-norm') != -1 &&
                        <>
                            <Form.Input
                                label='Наименование нормы:'
                                placeholder='Наименование нормы:'
                                name='norm_name'
                                value={props.vehicle.norm_name}
                                onChange={(e, { name, value }) => props.setVehicle({ ...props.vehicle, [name]: value })}
                            />

                            <Form.Field inline>
                                <label>Зима с: </label>
                                <Select
                                    name='winter_from_month'
                                    fluid
                                    placeholder='Месяц'
                                    options={monthOptions}
                                    onChange={(e, { name, value }) => props.setVehicle({ ...props.vehicle, [name]: value })}
                                    value={props.vehicle.winter_from_month}
                                />
                                <Select
                                    name='winter_from_day'
                                    fluid
                                    placeholder='День'
                                    options={dayOptions}
                                    onChange={(e, { name, value }) => props.setVehicle({ ...props.vehicle, [name]: value })}
                                    value={props.vehicle.winter_from_day}
                                />
                            </Form.Field>

                            <Form.Field inline>
                                <label>Зима до:</label>
                                <Select
                                    name='winter_to_month'
                                    fluid
                                    placeholder='Месяц'
                                    options={monthOptions}
                                    onChange={(e, { name, value }) => props.setVehicle({ ...props.vehicle, [name]: value })}
                                    value={props.vehicle.winter_to_month}
                                />
                                <Select
                                    name='winter_to_day'
                                    fluid
                                    placeholder='День'
                                    options={dayOptions}
                                    onChange={(e, { name, value }) => props.setVehicle({ ...props.vehicle, [name]: value })}
                                    value={props.vehicle.winter_to_day}
                                />
                            </Form.Field>

                            <Form.Input
                                label='B зимний период л/100 км в городском цикле:'
                                placeholder='B зимний период л/100 км в городском цикле:'
                                name='winter_city'
                                value={props.vehicle.winter_city}
                                onChange={(e, { name, value }) => props.setVehicle({ ...props.vehicle, [name]: value })}
                            />

                            <Form.Input
                                label='B зимний период л/100 км в загородном цикле:'
                                placeholder='B зимний период л/100 км в загородном цикле:'
                                name='winter_highway'
                                value={props.vehicle.winter_highway}
                                onChange={(e, { name, value }) => props.setVehicle({ ...props.vehicle, [name]: value })}
                            />

                            <Form.Input
                                label='B летний период л/100 км в городском цикле:'
                                placeholder='B летний период л/100 км в городском цикле:'
                                name='summer_city'
                                value={props.vehicle.summer_city}
                                onChange={(e, { name, value }) => props.setVehicle({ ...props.vehicle, [name]: value })}
                            />

                            <Form.Input
                                label='B летний период л/100 км в загородном цикле:'
                                placeholder='B летний период л/100 км в загородном цикле:'
                                name='summer_highway'
                                value={props.vehicle.summer_highway}
                                onChange={(e, { name, value }) => props.setVehicle({ ...props.vehicle, [name]: value })}
                            />

                            <Form.Input
                                label='Холостой ход(л/ч):'
                                placeholder='Холостой ход(л/ч):'
                                name='idle'
                                value={props.vehicle.idle}
                                onChange={(e, { name, value }) => props.setVehicle({ ...props.vehicle, [name]: value })}
                            />
                        </>
                    }
                    <Button color='blue' type='submit' fluid onClick={() => { props.handleSubmit() }}> <Icon name='save' />Применить</Button>
                </Form>
            }
        </Segment.Group>
    )
}

export default FormVehicle