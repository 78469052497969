import React from 'react'
import { Button, Form, Grid, Header, Image, Message, Segment, Table, Icon, Card, Label, Input, Select, Dropdown, Dimmer, Loader, Confirm, Menu, Modal, List, Container, Pagination } from 'semantic-ui-react'
import { Link, NavLink } from 'react-router-dom';

const ListView = (props) => {
  var date = new Date(); // сегодня
  var dtms = date.valueOf();
  var datered = new Date(dtms - ((24 * 60 * 60 * 1000) * 7));
  return (
    <>
      <Dimmer.Dimmable as={Segment} dimmed={props.isLoading} page>
        <Dimmer active={props.isLoading} inverted>
          <Loader></Loader>
        </Dimmer>


        <Menu secondary>
          <Menu.Menu position='left'>
            {props.permissions.indexOf('create-user') != -1 &&
              <Menu.Item
                as={NavLink}
                to='users/create'
                name='Добавить'
              />
            }
          </Menu.Menu>

          <Menu.Menu position='right'>
            <Menu.Item>
              <Dropdown floating inline pointing options={[
                { key: 'name', text: 'Наименование', value: 'name' }
              ]}
                onChange={(e, data) => { props.handleSortTableColumn(data.value) }}
                defaultValue='name' />
              <Dropdown floating inline pointing options={[
                { key: 'ascending', text: <Icon name='sort content ascending' />, value: 'ascending' },
                { key: 'descending', text: <Icon name='sort content descending' />, value: 'descending' }
              ]}
                onChange={(e, data) => { props.handleSortTableDir(data.value) }}
                defaultValue='ascending' />
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        {!!props.error &&
          <Message negative compact stackable>
            <Message.Header>{props.error}</Message.Header>
          </Message>
        }

        <Card.Group centered doubling stackable itemsPerRow={4}>
          {props.list.map(item => (
            <Card>
              <Card.Content>
                <Card.Header>
                  <Icon name='user' />
                  <a href={`/settings/users/${item.id}`}>{item.name}</a>
                  {item.roles.map(role =>
                    <Label size='mini'>{role.name}</Label>
                  )}
                  {props.permissions.indexOf('delete-user') != -1 &&
                    <Button icon='delete' color='red' floated='right'
                      text='Удалить'
                      onClick={() => { props.setOpenConfirmDeleteDialog(true); props.setDeletedId(item.id) }}
                    />
                  }
                </Card.Header>
                <Card.Meta>{item.email}</Card.Meta>
                {item.permissions.map(permission =>
                  <Label size='mini'>{permission.name}</Label>
                )}
              </Card.Content>
              <Card.Content extra>
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
        <Container textAlign='center'>
          <Pagination
            activePage={props.filter.page}
            totalPages={props.last_page}
            onPageChange={props.handlePaginationChange}
            pointing
            secondary
          />
        </Container>
        <Confirm
          open={props.openConfirmDeleteDialog}
          content="Вы уверены?"
          cancelButton='Отмена'
          confirmButton="Удалить"
          onCancel={() => props.setOpenConfirmDeleteDialog(false)}
          onConfirm={() => { props.deleteUser(props.deletedId); props.setOpenConfirmDeleteDialog(false); }}
        />


      </Dimmer.Dimmable>
    </>
  )
}

export default ListView