import React from 'react'
import { Button, Form, Grid, Header, Image, Message, Segment, Table, Icon, Card, Label, Input, Select, Dropdown, Dimmer, Loader, Confirm, Menu, Modal, List, Container, Pagination } from 'semantic-ui-react'
import { Link, NavLink } from 'react-router-dom';

const ListView = (props) => {
  var date = new Date(); // сегодня
  var dtms = date.valueOf();
  var datered = new Date(dtms - ((24 * 60 * 60 * 1000) * 7));
  return (
    <>
      <Dimmer.Dimmable as={Segment} dimmed={props.isLoading} page>
        <Dimmer active={props.isLoading} inverted>
          <Loader></Loader>
        </Dimmer>


        <Menu secondary>
          <Menu.Menu position='left'>
            {props.permissions.indexOf('create-autograph-server') != -1 &&
              <Menu.Item
                as={NavLink}
                to='autograph/create'
                name='Добавить'
              />
            }
          </Menu.Menu>

          <Menu.Menu position='right'>
            <Menu.Item>
              <Dropdown floating inline pointing options={[
                { key: 'name', text: 'Наименование', value: 'name' }
              ]}
                onChange={(e, data) => { props.handleSortTableColumn(data.value) }}
                defaultValue='name' />
              <Dropdown floating inline pointing options={[
                { key: 'ascending', text: <Icon name='sort content ascending' />, value: 'ascending' },
                { key: 'descending', text: <Icon name='sort content descending' />, value: 'descending' }
              ]}
                onChange={(e, data) => { props.handleSortTableDir(data.value) }}
                defaultValue='ascending' />
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        {/*!!props.error &&
          <Message negative compact stackable>
            <Message.Header>{props.error}</Message.Header>
          </Message>*/
        }

        <Card.Group centered doubling stackable itemsPerRow={4}>
          {props.list.map(item => (
            <Card>
              <Dimmer active={item.id == props.loadedServerId} inverted>
                <Loader inverted>Обновление...</Loader>
              </Dimmer>
              <Card.Content>
                <Card.Header>
                  <Icon name='server' />
                  <a href={`/settings/autograph/${item.id}`}>{item.host}</a>
                  <Button.Group color='blue' floated='right'>
                    {(props.permissions.indexOf('update-schema-autograph') != -1) &&
                      <Button icon='sync' onClick={() => { props.syncAutographSchema(item.id) }} />
                    }
                    <Dropdown className='button icon' floating>
                      <Dropdown.Menu>
                        {props.permissions.indexOf('delete-autograph-server') != -1 &&
                          <Button icon='delete' color='red' floated='right'
                            text='Удалить'
                            onClick={() => { props.setOpenConfirmDeleteDialog(true); props.setDeletedId(item.id) }}
                          />
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                  </Button.Group>
                </Card.Header>
                <List selection verticalAlign='middle'>
                  {item.schemas.map(schema =>
                    <List.Item >
                      <Dimmer active={schema.id == props.loadedSchemaId} inverted>
                        <Loader inverted>Обновление...</Loader>
                      </Dimmer>
                      {props.permissions.indexOf('update-device-autograph') != -1 &&
                        <List.Content floated='right'>
                          <Button color='green' icon='sync' onClick={() => { props.syncAutographDevices(schema.id) }} />
                        </List.Content>
                      }
                      <List.Content>{schema.name}</List.Content>
                    </List.Item>
                  )}
                </List>
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
        <Container textAlign='center'>
          <Pagination
            activePage={props.filter.page}
            totalPages={props.last_page}
            onPageChange={props.handlePaginationChange}
            pointing
            secondary
          />
        </Container>
        <Confirm
          open={props.openConfirmDeleteDialog}
          content="Вы уверены?"
          cancelButton='Отмена'
          confirmButton="Удалить"
          onCancel={() => props.setOpenConfirmDeleteDialog(false)}
          onConfirm={() => { props.deleteAutographServer(props.deletedId); props.setOpenConfirmDeleteDialog(false); }}
        />


      </Dimmer.Dimmable>
    </>
  )
}

export default ListView