import React from 'react'
import { Breadcrumb, Button, Container, Form, Grid, Header, Icon, Image, List, Menu, Message, Segment } from 'semantic-ui-react'
import VehicleForm from '../../Components/Form'
import WaybillsTable from '../../Components/Waybills'
import VehiclesHistoryTable from '../../Components/History'
import VehiclesComments from '../../Components/Comments'
import Dropzone, { useDropzone } from 'react-dropzone';
import SwiperCore, { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import NoImage from '../../../../Components/Images/no-image.png'
import AxiosImage from '../../Components/Attachments/View/AxiosImage'
import moment from 'moment';

SwiperCore.use([Navigation, Pagination, A11y]);
function ItemVehicle(props) {

    return (
        <Container fluid>
            <Menu secondary>
                <Breadcrumb>
                    <Breadcrumb.Section link href="/">Главная</Breadcrumb.Section>
                    <Breadcrumb.Divider />
                    <Breadcrumb.Section link href="/vehicles">Транспорт</Breadcrumb.Section>
                    <Breadcrumb.Divider />
                    <Breadcrumb.Section active>{props.vehicle && props.vehicle.name ? props.vehicle.name : props.match.params.id}</Breadcrumb.Section>
                </Breadcrumb>
            </Menu>
            <Grid>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    <Segment.Group>
                        <Message attached>
                            <Message.Header>
                                Фото
                            {props.addPhotoForm ?
                                    <Button circular color='red' icon='cancel' onClick={() => props.setAddPhotoForm(false)} floated='right' />
                                    :
                                    <Button circular color='blue' icon='upload' onClick={() => props.setAddPhotoForm(true)} floated='right' />
                                }
                            </Message.Header>
                            {props.addPhotoForm ?
                                <p>Добавить</p>
                                :
                                <p>Просмотр</p>
                            }
                        </Message>
                        <Segment>
                            {props.addPhotoForm ?
                                <Segment placeholder textAlign='center' secondary>
                                    <Dropzone
                                        multiple
                                        accept="image/*"
                                        onDrop={acceptedFiles => {
                                            var formData = new FormData();

                                            acceptedFiles.map((file, index) => {
                                                formData.append(`photos[${index}]`, file);
                                            });
                                            props.setPhotos(formData);
                                        }}>
                                        {({ acceptedFiles, getRootProps, getInputProps }) => (
                                            <section>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <Header icon>
                                                        <Icon name='photo' />
                                                Перетащите фото сюда, или нажмите для выбора...
                                            </Header>
                                                </div>
                                                <List>
                                                    {acceptedFiles.map(file => (
                                                        <List.Item>
                                                            <List.Header>{file.path}</List.Header>
                                                            <List.Description>
                                                                {file.size} байт
                                                    </List.Description>
                                                        </List.Item>
                                                    ))
                                                    }
                                                </List>
                                            </section>
                                        )}
                                    </Dropzone>
                                    <Button color='blue' fluid onClick={() => { props.handleAttachPhoto() }}> <Icon name='save' />Применить</Button>
                                </Segment>
                                :
                                <Segment basic>
                                    <Swiper
                                        navigation
                                        pagination={{ clickable: true }}
                                        spaceBetween={50}
                                        slidesPerView={1}
                                    >
                                        {props.vehicle && !!props.vehicle.photos && props.vehicle.photos.length > 0 ?
                                            props.vehicle.photos.map(photo =>
                                                <SwiperSlide>
                                                    <AxiosImage
                                                        id={photo.id}
                                                        openingOnClick = {true}
                                                    />
                                                </SwiperSlide>
                                            ) : <SwiperSlide><Image src={NoImage} centered /></SwiperSlide>
                                        }
                                    </Swiper>
                                </Segment>
                            }
                        </Segment>
                    </Segment.Group>

                    <VehiclesHistoryTable id={props.match.params.id} />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                    <VehicleForm id={props.match.params.id} />
                    {props.permissions.indexOf('read-waybill') != -1 &&
                        <WaybillsTable id={props.match.params.id} showAutographData={!!props.vehicle && !!props.vehicle.autograph_device} />
                    }
                    <VehiclesComments id={props.match.params.id} />

                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    {/* Страховой полис */}
                    <Segment.Group>
                        <Message attached>
                            <Message.Header>
                                Страховой полис
                            {(props.permissions.indexOf('update-vehicle') != -1 || (props.vehicle && props.authUserId == props.vehicle.id_user && props.permissions.indexOf('update-own-vehicle') != -1)) &&
                                    <>
                                        {props.addInsurancePolicyForm ?
                                            <Button circular color='red' icon='cancel' onClick={() => props.setAddInsurancePolicyForm(false)} floated='right' />
                                            :
                                            <Button circular color='blue' icon='upload' onClick={() => props.setAddInsurancePolicyForm(true)} floated='right' />
                                        }
                                    </>
                                }
                            </Message.Header>
                            {props.addPhotoForm ?
                                <p>Добавить</p>
                                :
                                <p>Просмотр</p>
                            }
                        </Message>
                        <Segment>
                            {props.addInsurancePolicyForm ?
                                <Segment placeholder textAlign='center' secondary>
                                    <Dropzone
                                        multiple
                                        accept="image/*"
                                        onDrop={acceptedFiles => {
                                            props.setInsurancePolicy({ ...props.insurancePolicy, files: acceptedFiles });
                                        }}>
                                        {({ acceptedFiles, getRootProps, getInputProps }) => (
                                            <section>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <Header icon>
                                                        <Icon name='photo' />
                                                Перетащите фото сюда, или нажмите для выбора...
                                            </Header>
                                                </div>
                                                <List>
                                                    {acceptedFiles.map(file => (
                                                        <List.Item>
                                                            <List.Header>{file.path}</List.Header>
                                                            <List.Description>
                                                                {file.size} байт
                                                    </List.Description>
                                                        </List.Item>
                                                    ))
                                                    }
                                                </List>
                                            </section>
                                        )}
                                    </Dropzone>
                                    <Form.Input
                                        type='date'
                                        label='Дата окончания страхового полиса:'
                                        name='expiry_date'
                                        value={props.insurancePolicy.expiry_date}
                                        onChange={(e, { name, value }) =>
                                            props.setInsurancePolicy({ ...props.insurancePolicy, [name]: value })
                                        }
                                    />
                                    <Button color='blue' fluid onClick={() => { props.handleAttachInsurancePolicy() }}> <Icon name='save' />Применить</Button>
                                </Segment>
                                :
                                <Segment basic>
                                    <Swiper
                                        navigation
                                        pagination={{ clickable: true }}
                                        spaceBetween={50}
                                        slidesPerView={1}
                                    >
                                        {props.vehicle && !!props.vehicle.insurance_policy && props.vehicle.insurance_policy.length > 0 ?
                                            props.vehicle.insurance_policy.map(photo =>
                                                <SwiperSlide>
                                                    <Header>
                                                        Дата окончания: {moment(photo.pivot.expiry_date).format('LL')}
                                                    </Header>
                                                    <AxiosImage
                                                        id={photo.id}
                                                        openingOnClick = {true}
                                                    />
                                                </SwiperSlide>
                                            ) : <SwiperSlide><Image src={NoImage} centered /></SwiperSlide>
                                        }
                                    </Swiper>
                                </Segment>
                            }
                        </Segment>
                    </Segment.Group>


                    {/* Свидетельство о регистрации */}
                    <Segment.Group>
                        <Message attached>
                            <Message.Header>
                                Свидетельство о регистрации
                                {(props.permissions.indexOf('update-vehicle') != -1 || (props.vehicle && props.authUserId == props.vehicle.id_user && props.permissions.indexOf('update-own-vehicle') != -1)) &&
                                    <>
                                        {props.addRegistrationCertificateForm ?
                                            <Button circular color='red' icon='cancel' onClick={() => props.setAddRegistrationCertificateForm(false)} floated='right' />
                                            :
                                            <Button circular color='blue' icon='upload' onClick={() => props.setAddRegistrationCertificateForm(true)} floated='right' />
                                        }
                                    </>
                                }
                            </Message.Header>
                            {props.addPhotoForm ?
                                <p>Добавить</p>
                                :
                                <p>Просмотр</p>
                            }
                        </Message>
                        <Segment>
                            {props.addRegistrationCertificateForm ?
                                <Segment placeholder textAlign='center' secondary>
                                    <Dropzone
                                        multiple
                                        accept="image/*"
                                        onDrop={acceptedFiles => {
                                            var formData = new FormData();

                                            acceptedFiles.map((file, index) => {
                                                formData.append(`photos[${index}]`, file);
                                            });
                                            props.setRegistrationCertificate(formData);
                                        }}>
                                        {({ acceptedFiles, getRootProps, getInputProps }) => (
                                            <section>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <Header icon>
                                                        <Icon name='photo' />
                                                Перетащите фото сюда, или нажмите для выбора...
                                            </Header>
                                                </div>
                                                <List>
                                                    {acceptedFiles.map(file => (
                                                        <List.Item>
                                                            <List.Header>{file.path}</List.Header>
                                                            <List.Description>
                                                                {file.size} байт
                                                    </List.Description>
                                                        </List.Item>
                                                    ))
                                                    }
                                                </List>
                                            </section>
                                        )}
                                    </Dropzone>
                                    <Button color='blue' fluid onClick={() => { props.handleAttachRegistrationCertificate() }}> <Icon name='save' />Применить</Button>
                                </Segment>
                                :
                                <Segment basic>
                                    <Swiper
                                        navigation
                                        pagination={{ clickable: true }}
                                        spaceBetween={50}
                                        slidesPerView={1}
                                    >
                                        {props.vehicle && !!props.vehicle.registration_certificate && props.vehicle.registration_certificate.length > 0 ?
                                            props.vehicle.registration_certificate.map(photo =>
                                                <SwiperSlide>
                                                    <AxiosImage
                                                        id={photo.id}
                                                        openingOnClick = {true}
                                                    />
                                                </SwiperSlide>
                                            ) : <SwiperSlide><Image src={NoImage} centered /></SwiperSlide>
                                        }
                                    </Swiper>
                                </Segment>
                            }
                        </Segment>
                    </Segment.Group>


                    {/* ПТС */}
                    <Segment.Group>
                        <Message attached>
                            <Message.Header>
                                ПТС
                                {(props.permissions.indexOf('update-vehicle') != -1 || (props.vehicle && props.authUserId == props.vehicle.id_user && props.permissions.indexOf('update-own-vehicle') != -1)) &&
                                    <>
                                        {props.addPTSForm ?
                                            <Button circular color='red' icon='cancel' onClick={() => props.setAddPTSForm(false)} floated='right' />
                                            :
                                            <Button circular color='blue' icon='upload' onClick={() => props.setAddPTSForm(true)} floated='right' />
                                        }
                                    </>
                                }
                            </Message.Header>
                            {props.addPhotoForm ?
                                <p>Добавить</p>
                                :
                                <p>Просмотр</p>
                            }
                        </Message>
                        <Segment>
                            {props.addPTSForm ?
                                <Segment placeholder textAlign='center' secondary>
                                    <Dropzone
                                        multiple
                                        accept="image/*"
                                        onDrop={acceptedFiles => {
                                            var formData = new FormData();

                                            acceptedFiles.map((file, index) => {
                                                formData.append(`photos[${index}]`, file);
                                            });
                                            props.setPts(formData);
                                        }}>
                                        {({ acceptedFiles, getRootProps, getInputProps }) => (
                                            <section>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <Header icon>
                                                        <Icon name='photo' />
                                                Перетащите фото сюда, или нажмите для выбора...
                                            </Header>
                                                </div>
                                                <List>
                                                    {acceptedFiles.map(file => (
                                                        <List.Item>
                                                            <List.Header>{file.path}</List.Header>
                                                            <List.Description>
                                                                {file.size} байт
                                                    </List.Description>
                                                        </List.Item>
                                                    ))
                                                    }
                                                </List>
                                            </section>
                                        )}
                                    </Dropzone>
                                    <Button color='blue' fluid onClick={() => { props.handleAttachPTS() }}> <Icon name='save' />Применить</Button>
                                </Segment>
                                :
                                <Segment basic>
                                    <Swiper
                                        navigation
                                        pagination={{ clickable: true }}
                                        spaceBetween={50}
                                        slidesPerView={1}
                                    >
                                        {props.vehicle && !!props.vehicle.p_t_s && props.vehicle.p_t_s.length > 0 ?
                                            props.vehicle.p_t_s.map(photo =>
                                                <SwiperSlide>
                                                    <AxiosImage
                                                        id={photo.id}
                                                        openingOnClick = {true}
                                                    />
                                                </SwiperSlide>
                                            ) : <SwiperSlide><Image src={NoImage} centered /></SwiperSlide>
                                        }
                                    </Swiper>
                                </Segment>
                            }
                        </Segment>
                    </Segment.Group>
                </Grid.Column>

            </Grid>
        </Container>
    )
}

export default ItemVehicle