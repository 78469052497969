import React from 'react';
import { Button, Dimmer, Dropdown, Form, List, Loader, Message, Modal } from 'semantic-ui-react';

export default function AddTODialog (props) {  
    return (
        <Modal
      open={props.openCreateMaintenanceDialog}
      onClose={() => props.setOpenCreateMaintenanceDialog(false)}
      onOpen={() => props.setOpenCreateMaintenanceDialog(true)}
    >
      <Modal.Header>Добавление ТО</Modal.Header>
      <Modal.Content image scrolling>
        <Modal.Description>
          { props.error && props.error.list &&
            <Message
              error
              header='Произошла ошибка:'
              list={props.error.list}
            />
          }
          <Form loading={props.isLoadingItem}>
            <Form.Field>
              <label>Ответственный:</label>
              <Dropdown 
                name='id_user'
                loading={props.isLoadingUsers} 
                selection 
                search
                placeholder="Ответственный"
                fluid
                options={props.listUsers.map(item => {return({
                                key: item.id, 
                                value: item.id,
                                text: item.name
                            })
                        })}
                onChange={(e, {name, value}) => props.setMaintenance({
                  ...props.maintenance,
                  data:{
                    ...props.maintenance.data,
                    [name]: value
                  }
                })}
                value={props.maintenance.data.id_user}
              />
            </Form.Field>
            <Form.Input
              type='date'
              label='Дата'
              name='date'
              value={props.maintenance.data.date}
              onChange={(e, {name, value}) => props.setMaintenance({
                ...props.maintenance,
                data:{
                  ...props.maintenance.data,
                  [name]: value
                }
              })}
            />
            <Form.TextArea label='Примечание' placeholder='Примечание, что было сделано...' 
              name='description'
              value={props.maintenance.data.description}
              onChange={(e, {name, value}) => props.setMaintenance({
                ...props.maintenance,
                data:{
                  ...props.maintenance.data,
                  [name]: value
                }
              })}
            />
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => props.handleCreateMaintenance()} primary>
          Добавить 
        </Button>
      </Modal.Actions>
    </Modal>
    )}