import React from 'react'
import { Button, Form, Menu, Dropdown, Message, Select, Icon, Container, Header, List, Grid, Segment } from 'semantic-ui-react'

function FormRole(props) {
    return (
        <Segment.Group>
            <Message
                attached
                header={
                    props.formType == 'edit' ?
                        <>
                            <Message.Header>
                                <Button circular color='red' icon='cancel' onClick={() => props.setFormType('show')} floated='right' />
                                    Редактирование
                                </Message.Header>
                            {'id: ' + props.role.id + ' ' + props.role.name}
                        </>
                        : props.formType == 'add' ?
                            <Message.Header>Добавление</Message.Header> :
                            <>
                                <Message.Header>
                                    { props.permissions.indexOf('update-role')!=-1 &&
                                        <Button circular color='blue' icon='edit' onClick={() => props.setFormType('edit')} floated='right' />
                                    }
                                        Просмотр
                                    </Message.Header>
                                {'id: ' + props.role.id + ' ' + props.role.name}
                            </>}
            />
            { props.error && props.error.list &&
                <Message
                    error attached
                    header='Произошла ошибка:'
                    list={props.error.list}
                />
            }
            {props.formType == 'show' ?
                <Segment>
                    <List divided relaxed>
                        <List.Item>
                            <List.Content>
                                <List.Header>Название роли</List.Header>
                                <List.Description>{props.role.name}</List.Description>
                            </List.Content>
                        </List.Item>
                        
                        
                        <List.Item>
                            <List.Content>
                                <List.Header>Права</List.Header>
                                <List.Description>
                                    <Dropdown
                                        disabled
                                        name='permissions'
                                        value={props.role.permissions}
                                        fluid
                                        multiple
                                        placeholder='Права'
                                        noResultsMessage={
                                            <>
                                                <label>Список пуст. </label>
                                            </>
                                        }
                                        loading={props.isLoadingPermissions}
                                        options={props.listPermissions.map(item => {
                                            return ({
                                                key: item.id,
                                                value: item.id,
                                                text: item.name
                                            })
                                        })}
                                    />
                                </List.Description>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content>
                                <List.Header>Пользователи</List.Header>
                                <List.Description>
                                    <Dropdown
                                        disabled
                                        name='users'
                                        value={props.role.users}
                                        fluid
                                        multiple
                                        placeholder='Пользователи'
                                        noResultsMessage={
                                            <>
                                                <label>Список пуст. </label>
                                            </>
                                        }
                                        loading={props.isLoadingUsers}
                                        options={props.listUsers.map(item => {
                                            return ({
                                                key: item.id,
                                                value: item.id,
                                                text: item.name
                                            })
                                        })}
                                    />
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    </List>
                </Segment>
                :
                <Form loading={props.isLoadingItem} className='attached fluid segment'>
                    <Form.Input
                        label='Название роли:'
                        placeholder='Название роли:'
                        name='name'
                        value={props.role.name}
                        onChange={(e, { name, value }) => props.setRole({ ...props.role, [name]: value })}
                    />
                    
                    <Form.Field>
                        <label>Права:</label>
                        <Dropdown
                            name='permissions'
                            loading={props.isLoadingRoles}
                            multiple
                            selection
                            search
                            placeholder="Права:"
                            fluid
                            options={props.listPermissions.map(item => {
                                return ({
                                    key: item.id,
                                    value: item.id,
                                    text: item.name
                                })
                            })}
                            onChange={(e, { name, value }) => props.setRole({ ...props.role, [name]: value })}
                            value={props.role.permissions}
                        >
                        </Dropdown>
                    </Form.Field>

                    <Form.Field>
                        <label>Пользователи:</label>
                        <Dropdown
                            name='users'
                            loading={props.isLoadingUsers}
                            multiple
                            selection
                            search
                            placeholder="Пользователи:"
                            fluid
                            options={props.listUsers.map(item => {
                                return ({
                                    key: item.id,
                                    value: item.id,
                                    text: item.name
                                })
                            })}
                            onChange={(e, { name, value }) => props.setRole({ ...props.role, [name]: value })}
                            value={props.role.users}
                        >
                        </Dropdown>
                    </Form.Field>
                    <Button color='blue' type='submit' fluid onClick={() => { props.handleSubmit() }}> <Icon name='save' />Применить</Button>
                </Form>
            }
        </Segment.Group>
    )
}

export default FormRole