export const GET_LIST_REQUEST = "Organizations/GET_LIST_REQUEST";
export const GET_LIST_SUCCESS = "Organizations/GET_LIST_SUCCESS";
export const GET_LIST_FAILURE = "Organizations/GET_LIST_FAILURE";

export const GET_ITEM_REQUEST = "Organizations/GET_REQUEST";
export const GET_ITEM_SUCCESS = "Organizations/GET_SUCCESS";
export const GET_ITEM_FAILURE = "Organizations/GET_FAILURE";

export const ADD_REQUEST = "Organizations/ADD_REQUEST";
export const ADD_SUCCESS = "Organizations/ADD_SUCCESS";
export const ADD_FAILURE = "Organizations/ADD_FAILURE";

export const DELETE_REQUEST = "Organizations/DELETE_REQUEST";
export const DELETE_SUCCESS = "Organizations/DELETE_SUCCESS";
export const DELETE_FAILURE = "Organizations/DELETE_FAILURE";

export const EDIT_REQUEST = "Organizations/EDIT_REQUEST";
export const EDIT_SUCCESS = "Organizations/EDIT_SUCCESS";
export const EDIT_FAILURE = "Organizations/EDIT_FAILURE";