import React from 'react'
import { Button, Form, Grid, Message, Segment, Icon, Card, Dropdown, Dimmer, Loader, Confirm, Menu, Modal, List, Breadcrumb, Popup, Label, Header } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom';

import HistoryDialog from './HistoryDialog';
import AddTODialog from './AddTODialog';
import PhotoDialog from './PhotoDialog';

import AxiosImage from '../../Components/Attachments/View/AxiosImage'
import AutographMapDialog from './AutographMapDialog';
import moment from 'moment';

const ListForm = (props) => {
  var date = new Date();
  var dtms = date.valueOf();
  var datered = new Date(dtms - ((24 * 60 * 60 * 1000) * 7));

  return (
    <>
      <Dimmer.Dimmable as={Segment} dimmed={props.isLoading}>
        <Dimmer active={props.isLoading} inverted page>
          <Loader></Loader>
        </Dimmer>

        <Breadcrumb>
          <Breadcrumb.Section link href="/">Главная</Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active>Транспорт</Breadcrumb.Section>
        </Breadcrumb>

        <Menu secondary>
          <Menu.Menu position='left'>
            {props.permissions.indexOf('create-vehicle') != -1 &&
              <Menu.Item
                as={NavLink}
                to='vehicles/create'
                name='Добавить'
              />
            }
          </Menu.Menu>

          <Menu.Menu position='right'>
            <Menu.Item>
              <Dropdown floating inline pointing options={[
                { key: 'name', text: 'Наименование', value: 'name' },
                { key: 'regNumber', text: 'Номер', value: 'regNumber' },
              ]}
                onChange={(e, data) => { props.handleSortTableColumn(data.value) }}
                defaultValue='name' />
              <Dropdown floating inline pointing options={[
                { key: 'ascending', text: <Icon name='sort content ascending' />, value: 'ascending' },
                { key: 'descending', text: <Icon name='sort content descending' />, value: 'descending' }
              ]}
                onChange={(e, data) => { props.handleSortTableDir(data.value) }}
                defaultValue='ascending' />
            </Menu.Item>
          </Menu.Menu>

          <Menu.Menu>{/* position='right' */}
            <div className='ui right aligned category search item'>
              <div className='ui transparent icon input'>
                <input
                  className='prompt'
                  type='text'
                  placeholder='Поиск...'
                />
                <i className='search link icon' />
              </div>
              <div className='results' />
            </div>
          </Menu.Menu>
        </Menu>
        { /*!!props.error &&
        <Message negative compact stackable>
          <Message.Header>{props.error}</Message.Header>
        </Message>
      */}

        <Card.Group centered doubling stackable itemsPerRow={4}>
          {props.list.map(item => (
            <Card >
              <Card.Content>
                <Card.Header>
                  <Grid>
                    <Grid.Row style={{ paddingBottom: '0px' }}>

                      <Grid.Column>

                        <NavLink to={"/vehicles/" + item.id}>
                          {item.name}
                        </NavLink>


                      </Grid.Column>

                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column >
                        <NavLink to={"/vehicles/" + item.id} style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                          <Button basic color='black' size='mini' >
                            <Header as='h5'>{item.regNumber}</Header>
                          </Button>
                        </NavLink>
                      </Grid.Column>
                      <Grid.Column floated='right'>
                        <Button.Group color='blue' floated='right'>
                          {((props.permissions.indexOf('create-waybill') != -1) || (props.authUserId == item.user.id && props.permissions.indexOf('create-own-waybill') != -1)) &&
                            <Button icon='file'
                              as={NavLink}
                              item={item}
                              to={"/vehicles/" + item.id + "/waybills/create"}
                            />
                          }
                          <Dropdown className='button icon' floating>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => { props.getVehicle(item.id); props.setOpenHistoryDialog(true) }}
                                text='История'
                                icon='archive'
                              />
                              <Dropdown.Item as={NavLink} to={"/vehicles/" + item.id} text='Редактировать' icon='edit' />
                              {((props.permissions.indexOf('delete-vehicle') != -1) || (props.authUserId == item.user.id && props.permissions.indexOf('delete-own-vehicle') != -1)) &&
                                <Dropdown.Item style={{ color: 'red' }} text='Удалить' icon='delete' onClick={() => { props.setOpenConfirmDeleteDialog(true); props.setDeletedId(item.id) }} />
                              }
                            </Dropdown.Menu>
                          </Dropdown>
                        </Button.Group>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Card.Header>
                {item.last_waybill && item.last_waybill.created_at &&
                  <Popup
                    header="Дата внесения информации: "
                    content={moment(item.last_waybill.created_at).format('LLL')}
                    trigger={
                      <Card.Meta>
                        <Label>
                          <Icon name='archive' /> {moment(item.last_waybill.created_at).format('LL')}
                        </Label>
                      </Card.Meta>}
                  />
                }
                <Grid verticalAlign='middle' centered>
                  <Grid.Column width={4} style={{ paddingRight: '0px' }}>
                    <AxiosImage
                      id={(item.photos && item.photos[0]) ? item.photos[0].id : null}
                      onClick={() => { props.setPhotos({ vehicle: item, data: { photos: [] } }); props.setOpenPhotoDialog(true) }} />
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <List divided verticalAlign='middle' size='large'>

                      <List.Item>
                        <Icon name='tachometer alternate' />
                        <List.Content>
                          <List.Header>
                            Одометр {item.odometer}
                          </List.Header>
                        </List.Content>
                      </List.Item>

                      <List.Item>
                        <Icon name='tint' />
                        <List.Content>
                          <List.Header>
                            Топливо в баке: {item.fuel}
                          </List.Header>
                        </List.Content>
                      </List.Item>

                      <List.Item>
                        <List.Content floated='right'>
                        </List.Content>
                        <Icon name='user' />
                        <List.Content>
                          <List.Header>
                            {item.user.name}
                          </List.Header>
                        </List.Content>
                      </List.Item>

                      <List.Item>
                        {((props.permissions.indexOf('create-vehicle-maintenance') != -1) || (props.authUserId == item.user.id && props.permissions.indexOf('create-own-vehicle-maintenance') != -1)) &&
                          <List.Content floated='right'>
                            <Button circular basic color='green' size='mini' icon='plus'
                              onClick={() => {
                                props.setMaintenance({
                                  vehicle: item,
                                  data: {
                                    date: new Date(),
                                    description: '',
                                    odometer: item.odometer,
                                    id_user: item.id_user
                                  }
                                })
                                props.setOpenCreateMaintenanceDialog(true)
                              }}
                            />
                          </List.Content>
                        }
                        <Icon name='calendar alternate outline' />
                        <List.Content>
                          <List.Header>
                            TO: {!!item.last_maintenance ?
                              <Popup
                                header={item.last_maintenance.date}
                                content={item.last_maintenance.description}
                                trigger={
                                  <div>
                                    {item.last_maintenance.date}
                                  </div>}
                              />
                              : "Не заполнено"}
                          </List.Header>
                        </List.Content>
                      </List.Item>

                      <List.Item>
                        {((props.permissions.indexOf('create-vehicle-repair') != -1) || (props.authUserId == item.user.id && props.permissions.indexOf('create-own-vehicle-repair') != -1)) &&
                          <List.Content floated='right'>
                            <Button circular basic color='green' size='mini' icon='plus'
                              onClick={() => {
                                props.setRepair({
                                  vehicle: item,
                                  data: {
                                    date: new Date(),
                                    description: '',
                                    id_user: item.id_user
                                  }
                                })
                                props.setOpenCreateRepairDialog(true)
                              }}
                            />
                          </List.Content>
                        }
                        <Icon name='wrench' />
                        <List.Content>
                          <List.Header>
                            Ремонт: {!!item.last_repair ?
                              <Popup
                                header={item.last_repair.date}
                                content={item.last_repair.description}
                                trigger={
                                  <div>
                                    {item.last_repair.date}
                                  </div>}
                              />
                              : "Не заполнено"}
                          </List.Header>
                        </List.Content>
                      </List.Item>

                    </List>
                  </Grid.Column>
                </Grid>
              </Card.Content>
              <Card.Content extra>
                {!!item.autograph_device &&
                  <Button
                    color={item.autograph_device == 0 ? 'red' : 'green'} icon basic
                    onClick={() => { props.setAutographMapDialogItem(item.autograph_device); props.setOpenAutographMapDialog(true) }}
                    labelPosition='left'>
                    <Icon name='map' />
                      Карта
                  </Button>
                }
              </Card.Content>
            </Card>
          ))}
        </Card.Group>

        <Confirm
          open={props.openConfirmDeleteDialog}
          content="Вы уверены?"
          cancelButton='Отмена'
          confirmButton="Удалить"
          onCancel={() => props.setOpenConfirmDeleteDialog(false)}
          onConfirm={() => { props.deleteVehicle(props.deletedId); props.setOpenConfirmDeleteDialog(false); }}
        />

        {
          //
          // ИСТОРИЯ
          //
        }

        <HistoryDialog {...props} />

        {
          //
          // Добавление ТО
          //
        }
        <AddTODialog {...props} />


        {
          //
          // Добавление Ремонта
          //
        }
        <Modal
          open={props.openCreateRepairDialog}
          onClose={() => props.setOpenCreateRepairDialog(false)}
          onOpen={() => props.setOpenCreateRepairDialog(true)}
        >
          <Modal.Header>Добавление ремонт</Modal.Header>
          <Modal.Content image scrolling>
            <Modal.Description>
              {props.error && props.error.list &&
                <Message
                  error
                  header='Произошла ошибка:'
                  list={props.error.list}
                />
              }
              <Form loading={props.isLoadingItem}>
                <Form.Field>
                  <label>Ответственный:</label>
                  <Dropdown
                    name='id_user'
                    loading={props.isLoadingUsers}
                    selection
                    search
                    placeholder="Ответственный"
                    fluid
                    options={props.listUsers.map(item => {
                      return ({
                        key: item.id,
                        value: item.id,
                        text: item.name
                      })
                    })}
                    onChange={(e, { name, value }) => props.setRepair({
                      ...props.repair,
                      data: {
                        ...props.repair.data,
                        [name]: value
                      }
                    })}
                    value={props.repair.data.id_user}
                  />
                </Form.Field>
                <Form.Input
                  type='date'
                  label='Дата'
                  name='date'
                  value={props.repair.data.date}
                  onChange={(e, { name, value }) => props.setRepair({
                    ...props.repair,
                    data: {
                      ...props.repair.data,
                      [name]: value
                    }
                  })}
                />
                <Form.TextArea label='Примечание' placeholder='Примечание, что было сделано...'
                  name='description'
                  value={props.repair.data.description}
                  onChange={(e, { name, value }) => props.setRepair({
                    ...props.repair,
                    data: {
                      ...props.repair.data,
                      [name]: value
                    }
                  })}
                />
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => props.handleCreateRepair()} primary>
              Добавить
        </Button>
          </Modal.Actions>
        </Modal>

        {
          //
          // ФОТО
          //
        }
        <PhotoDialog {...props} />

        {props.openAutographMapDialog &&
          < AutographMapDialog
            open={props.openAutographMapDialog}
            setOpen={val => props.setOpenAutographMapDialog(val)}
            {...props.AutographMapDialogItem}
          />
        }
      </Dimmer.Dimmable>
    </>
  )
}

export default ListForm