import React from 'react'
import { Button, Form, Menu, Dropdown, Message, Select, Icon, Container, Header, List, Grid, Segment } from 'semantic-ui-react'

function FormRole(props) {
    return (
        <Segment.Group>
            <Message
                attached
                header={
                    props.formType == 'edit' ?
                        <>
                            <Message.Header>
                                <Button circular color='red' icon='cancel' onClick={() => props.setFormType('show')} floated='right' />
                                    Редактирование
                                </Message.Header>
                            {'id: ' + props.autographServer.id + ' ' + props.autographServer.host}
                        </>
                        : props.formType == 'add' ?
                            <Message.Header>Добавление</Message.Header> :
                            <>
                                <Message.Header>
                                    { props.permissions.indexOf('update-autograph-server')!=-1 &&
                                        <Button circular color='blue' icon='edit' onClick={() => props.setFormType('edit')} floated='right' />
                                    }
                                        Просмотр
                                    </Message.Header>
                                {'id: ' + props.autographServer.id + ' ' + props.autographServer.host}
                            </>}
            />
            { props.error && props.error.list &&
                <Message
                    error attached
                    header='Произошла ошибка:'
                    list={props.error.list}
                />
            }
            {props.formType == 'show' ?
                <Segment>
                    <List divided relaxed>
                        <List.Item>
                            <List.Content>
                                <List.Header>Хост</List.Header>
                                <List.Description>{props.autographServer.host}</List.Description>
                            </List.Content>
                        </List.Item>
                        
                    </List>
                </Segment>
                :
                <Form loading={props.isLoadingItem} className='attached fluid segment'>
                    <Form.Input
                        label='Хост:'
                        placeholder='Хост:'
                        name='host'
                        value={props.autographServer.host}
                        onChange={(e, { name, value }) => props.setAutographServer({ ...props.autographServer, [name]: value })}
                    />

                    <Form.Input
                        label='Логин:'
                        placeholder='Логин:'
                        name='username'
                        value={props.autographServer.username}
                        onChange={(e, { name, value }) => props.setAutographServer({ ...props.autographServer, [name]: value })}
                    />

                    <Form.Input
                        label='Пароль:'
                        placeholder='Пароль:'
                        name='password'
                        value={props.autographServer.password}
                        onChange={(e, { name, value }) => props.setAutographServer({ ...props.autographServer, [name]: value })}
                    />
                    
                    <Button color='blue' type='submit' fluid onClick={() => { props.handleSubmit() }}> <Icon name='save' />Применить</Button>
                </Form>
            }
        </Segment.Group>
    )
}

export default FormRole