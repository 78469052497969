import { compose, lifecycle, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { getVehicle, attachPhoto, attachInsurancePolicy, attachRegistrationCertificate, attachPTS } from '../../../Store/Actions/Vehicles';

import View from './Components';

export default compose(
  connect(
    state => ({
      permissions: state.auth.permissions,
      authUserId: state.auth.id,
    }), 
    { getVehicle, attachPhoto, attachInsurancePolicy, attachRegistrationCertificate, attachPTS },
  ),
  withState("vehicle", "setVehicle", null),

  withState("photos", "setPhotos", null),
  withState("insurancePolicy", "setInsurancePolicy", {
    data: null,
    files: null,
    expiry_date: null,
  }),
  withState("registrationCertificate", "setRegistrationCertificate", null),
  withState("pts", "setPts", null),

  withState("addPhotoForm", "setAddPhotoForm", false),
  withState("addInsurancePolicyForm", "setAddInsurancePolicyForm", false),
  withState("addRegistrationCertificateForm", "setAddRegistrationCertificateForm", false),
  withState("addPTSForm", "setAddPTSForm", false),

  withHandlers({
    handleAttachPhoto: props => () => {
      props.attachPhoto(props.vehicle.id, props.photos).then((ret)=>{
        if(ret.status == 200) { props.setAddPhotoForm(false); props.setVehicle(ret.data) }
      })
    },
    handleAttachInsurancePolicy: props => () => {
      var formData = new FormData();
      props.insurancePolicy.files.map((file, index) => {
        formData.append(`photos[${index}]`, file);
      });
      formData.append('expiry_date', props.insurancePolicy.expiry_date);
      props.attachInsurancePolicy(props.vehicle.id, formData).then((ret)=>{
        if(ret.status == 200) { props.setAddInsurancePolicyForm(false); props.setVehicle(ret.data) }
      })
    },
    handleAttachRegistrationCertificate: props => () => {
      props.attachRegistrationCertificate(props.vehicle.id, props.registrationCertificate).then((ret)=>{
        if(ret.status == 200) { props.setAddRegistrationCertificateForm(false); props.setVehicle(ret.data) }
      })
    },
    handleAttachPTS: props => () => {
      props.attachPTS(props.vehicle.id, props.pts).then((ret)=>{
        if(ret.status == 200) { props.setAddPTSForm(false); props.setVehicle(ret.data) }
      })
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.getVehicle(this.props.match.params.id).then(data => {
        data.status == 200 && this.props.setVehicle(data.data)
      });
    }
  })
)(View);