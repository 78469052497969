import axios from 'axios';
import {
  GET_VEHICLES_LIST_REQUEST,
  GET_VEHICLES_LIST_SUCCESS,
  GET_VEHICLES_LIST_FAILURE,
  GET_VEHICLE_REQUEST,
  GET_VEHICLE_SUCCESS,
  GET_VEHICLE_FAILURE,
  ADD_DATA_VEHICLES_REQUEST,
  ADD_DATA_VEHICLES_SUCCESS,
  ADD_DATA_VEHICLES_FAILURE,
  ADD_VEHICLE_REQUEST,
  ADD_VEHICLE_SUCCESS,
  ADD_VEHICLE_FAILURE,
  EDIT_VEHICLE_REQUEST,
  EDIT_VEHICLE_FAILURE,
  EDIT_VEHICLE_SUCCESS,
  DELETE_VEHICLE_REQUEST,
  DELETE_VEHICLE_SUCCESS,
  DELETE_VEHICLE_FAILURE, ADD_MAINTENANCE_REQUEST, ADD_MAINTENANCE_SUCCESS, ADD_MAINTENANCE_FAILURE, ADD_REPAIR_FAILURE, ADD_REPAIR_SUCCESS, ADD_REPAIR_REQUEST,
  ATTACH_REQUEST, ATTACH_SUCCESS, ATTACH_FAILURE
} from '../Constants/Vehicles';

  export const getVehicle = (id) => dispatch => {
    dispatch({type: GET_VEHICLE_REQUEST});
    return axios.get(`/api/vehicles/`+id)
    .then(res => {
          dispatch({
            type: GET_VEHICLE_SUCCESS, 
            data: res.data
          });
          return res;
        }).catch(function (error) {
          if(error.response && error.response.data)
            dispatch({type: GET_VEHICLE_FAILURE, error: error.response.data});
          if(error.request.status === 401){
            localStorage.removeItem("user");
            window.location.href = '/login'
          }
          return error.response;
      });
  };

export const getVehiclesList = (filter) => dispatch => {
  dispatch({type: GET_VEHICLES_LIST_REQUEST});
  axios.get(`/api/vehicles`, {
    params: {
      sortColumn: filter.sort.column,
      sortDirection: filter.sort.direction,
    }
  }).then(res => {
        dispatch({
          type: GET_VEHICLES_LIST_SUCCESS, 
          data: res.data
        });
      }).catch(function (error) {
        if(error.request.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        dispatch({type: GET_VEHICLES_LIST_FAILURE});
    });
};

export const addVehicle = (data) => dispatch => {
  dispatch({type: ADD_VEHICLE_REQUEST});
  return axios.post('/api/vehicles', data)
      .then(res => {
        dispatch({type: ADD_VEHICLE_SUCCESS, item: res.data});
        return res;
      }).catch(function (error) {
        if(error.response && error.response.data)
          dispatch({type: ADD_VEHICLE_FAILURE, error: error.response.data});
          if(error.response && error.response.status === 401){
            localStorage.removeItem("user");
            window.location.href = '/login'
          }
          return error.response;
    });
};

export const deleteVehicle = (id) => dispatch => {
  dispatch({ type: DELETE_VEHICLE_REQUEST });
  axios.delete('/api/vehicles/'+id)
      .then(res => {
        dispatch({ type: DELETE_VEHICLE_SUCCESS, id: id });
      }).catch(function (error) {
        if(error.request.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        dispatch({ type: DELETE_VEHICLE_FAILURE });
    });
};

export const editVehicle = (item) => dispatch => {
  dispatch({type: EDIT_VEHICLE_REQUEST});
  return axios.put('/api/vehicles/'+item.id, item)
      .then(res => {
        dispatch({type: EDIT_VEHICLE_SUCCESS});
        return res;
      }).catch(function (error) {
        dispatch({type: EDIT_VEHICLE_FAILURE, error: error.response.data});
        if(error.request.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        return error.response;
    });
};

export const createRepair = (id, data) => dispatch => {
  dispatch({type: ADD_REPAIR_REQUEST});
  return axios.post('/api/vehicles/'+id+'/repairs', data)
      .then(res => {
        dispatch({type: ADD_REPAIR_SUCCESS, id: id, item: res.data});
        return true
      }).catch(function (error) {
        if(error.response && error.response.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        dispatch({type: ADD_REPAIR_FAILURE, error: error.response.data});
        return false
    });
};

export const createMaintenance = (id, data) => dispatch => {
  dispatch({type: ADD_MAINTENANCE_REQUEST});
  return axios.post('/api/vehicles/'+id+'/maintenances', data)
      .then(res => {
        dispatch({type: ADD_MAINTENANCE_SUCCESS, id: id, item: res.data});
        return true
      }).catch(function (error) {
        if(error.response && error.response.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        dispatch({type: ADD_MAINTENANCE_FAILURE, error: error.response.data});
        return false
    });
};

export const attachPhoto = (id, data) => dispatch => {
  dispatch({type: ATTACH_REQUEST});
  return axios.post('/api/vehicles/'+id+'/photos', data, {
    headers: {
        'content-type': 'multipart/form-data'
    }
  })
      .then(res => {
        dispatch({type: ATTACH_SUCCESS, id: id, item: res.data});
        return res
      }).catch(function (error) {
        if(error.response && error.response.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        dispatch({type: ATTACH_FAILURE, error: error.response.data});
        return error.response
    });
};

export const attachInsurancePolicy = (id, data) => dispatch => {
  dispatch({type: ATTACH_REQUEST});
  return axios.post('/api/vehicles/'+id+'/insurance_policy', data, {
    headers: {
        'content-type': 'multipart/form-data'
    }
  })
      .then(res => {
        dispatch({type: ATTACH_SUCCESS, id: id, item: res.data});
        return res
      }).catch(function (error) {
        if(error.response && error.response.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        dispatch({type: ATTACH_FAILURE, error: error.response.data});
        return error.response
    });
};

export const attachRegistrationCertificate = (id, data) => dispatch => {
  dispatch({type: ATTACH_REQUEST});
  return axios.post('/api/vehicles/'+id+'/registration_certificate', data, {
    headers: {
        'content-type': 'multipart/form-data'
    }
  })
      .then(res => {
        dispatch({type: ATTACH_SUCCESS, id: id, item: res.data});
        return res
      }).catch(function (error) {
        if(error.response && error.response.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        dispatch({type: ATTACH_FAILURE, error: error.response.data});
        return error.response
    });
};

export const attachPTS = (id, data) => dispatch => {
  dispatch({type: ATTACH_REQUEST});
  return axios.post('/api/vehicles/'+id+'/pts', data, {
    headers: {
        'content-type': 'multipart/form-data'
    }
  })
      .then(res => {
        dispatch({type: ATTACH_SUCCESS, id: id, item: res.data});
        return res
      }).catch(function (error) {
        if(error.response && error.response.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        dispatch({type: ATTACH_FAILURE, error: error.response.data});
        return error.response
    });
};