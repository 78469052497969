import {
  GET_VEHICLES_LIST_REQUEST,
  GET_VEHICLES_LIST_SUCCESS,
  GET_VEHICLES_LIST_FAILURE,
  ADD_VEHICLE_REQUEST,
  ADD_VEHICLE_SUCCESS,
  ADD_VEHICLE_FAILURE,
  EDIT_VEHICLE_SUCCESS,
  DELETE_VEHICLE_REQUEST,
  DELETE_VEHICLE_SUCCESS,
  DELETE_VEHICLE_FAILURE,
  GET_VEHICLE_SUCCESS, GET_VEHICLE_REQUEST, GET_VEHICLE_FAILURE, ADD_MAINTENANCE_REQUEST, ADD_MAINTENANCE_SUCCESS, ADD_MAINTENANCE_FAILURE, ADD_REPAIR_FAILURE, ADD_REPAIR_SUCCESS, ADD_REPAIR_REQUEST,
  ATTACH_REQUEST, ATTACH_SUCCESS, ATTACH_FAILURE, EDIT_VEHICLE_FAILURE
} from '../Constants/Vehicles';

export const initialState = {
  current_page: 1,
  current: null,
  list: [],
  total: 0,
  isLoading: false,
  isLoadingItem: false,
  error: null
};

export default function WeldersReducer(state = initialState, action) {
  switch (action.type) {
    case GET_VEHICLE_SUCCESS:
      return {
        ...state,
        current: action.data,
        isLoading: false
      };
    case GET_VEHICLE_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case GET_VEHICLE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.data.error
      };

    case GET_VEHICLES_LIST_SUCCESS:
      return {
        ...state,
        current_page: action.data.current_page,
        list: action.data.data,
        total: action.data.total,
        isLoading: false
      };
    case GET_VEHICLES_LIST_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case GET_VEHICLES_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.data.error
      };

    case ADD_VEHICLE_SUCCESS:
      state.list.push(action.user);
      return {
        ...state,
        list: state.list,
        isLoading: false,
        error: null
      };
    case ADD_VEHICLE_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false
      };

    case EDIT_VEHICLE_SUCCESS:
      return {
        ...state,
        //list: state.list.map( user => (user.id == action.user.id)?action.user:user ),
        isLoading: false,
        error: null
      };
    case EDIT_VEHICLE_FAILURE:
      return {
        ...state,
        //list: state.list.map( user => (user.id == action.user.id)?action.user:user ),
        error: action.error,
        isLoading: false
      };

    case DELETE_VEHICLE_SUCCESS:
      var dev = [];
      state.list.map(item => {
        if (Number(item.id) !== Number(action.id))
          dev.push(item);
      });
      return {
        ...state,
        isLoading: false,
        list: dev,
      };
    case ADD_MAINTENANCE_REQUEST:
      return {
        ...state,
        isLoadingItem: true
      }
    case ADD_MAINTENANCE_SUCCESS:
      return {
        ...state,
        isLoadingItem: false,
        list: state.list.map(item => (item.id == action.id) ? { ...item, last_maintenance: action.item } : item)
      }
    case ADD_MAINTENANCE_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoadingItem: false
      };
    case ADD_REPAIR_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoadingItem: false
      };
    case ADD_REPAIR_SUCCESS:
      return {
        ...state,
        isLoadingItem: false,
        list: state.list.map(item => (item.id == action.id) ? { ...item, last_repair: action.item } : item)
      }
    case ADD_REPAIR_REQUEST:
      return {
        ...state,
        isLoadingItem: true
      }

    case ATTACH_REQUEST:
      return {
        ...state,
        isLoadingItem: true
      }
    case ATTACH_SUCCESS:
      return {
        ...state,
        isLoadingItem: false,
        list: state.list.map(item => (item.id == action.id) ? action.item : item)
      }
    case ATTACH_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoadingItem: false
      };

    default:
      return state;
  }
}
