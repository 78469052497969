import { compose, lifecycle, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import { getRolesList, deleteRole } from '../../../../Store/Actions/Roles';

import View from './Components';


export default compose(
  connect(
    state => ({
      permissions: state.auth.permissions,
      isAuthenticated: state.auth.isAuthenticated,
      current_page: state.roles.current_page,
      current: state.roles.current,
      list: state.roles.list,
      total: state.roles.total,
      last_page: state.roles.last_page,
      isLoading: state.roles.isLoading,
      error: state.roles.error
    }), { getRolesList, deleteRole },
  ),
  withState("filter", "setFilter", {
    sort: {
      column: 'updated_at',
      direction: 'ascending'
    },
    page: 1
  }),
  
  withState("openConfirmDeleteDialog", "setOpenConfirmDeleteDialog", false),
  withState("deletedId", "setDeletedId", -1),
  withHandlers({
    handleSortTableColumn: props => (column) => {
        props.setFilter({
            ...props.filter,
            sort: {
                ...props.filter.sort,
                column: column,
                //direction: props.filter.sort.direction === 'ascending' ? 'descending' : 'ascending'
            }
        });
        props.getRolesList(props.filter);
    },
    handleSortTableDir: props => (dir) => {
      props.setFilter({
          ...props.filter,
          sort: {
              ...props.filter.sort,
              //column: column,
              direction: dir//props.filter.sort.direction === 'ascending' ? 'descending' : 'ascending'
          }
      });
      props.getRolesList(props.filter);
  },
  handlePaginationChange: props => (e, { activePage }) => {
      props.setFilter({...props.filter, page: activePage},() => { props.getRolesList({...props.filter, page: activePage}) })
  }
  }),
  lifecycle({
    componentDidMount() {
      this.props.getRolesList(this.props.filter);
    }
  })
)(View);