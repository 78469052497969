import { compose, lifecycle, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import { addDataWelder } from '../../../Store/Actions/Welders';

import View from './Components';

export default compose(
  connect(
    state => ({
      isAuthenticated: state.auth.isAuthenticated,
      isLoading: state.welders.isLoading,
      error: state.welders.error
    }), { addDataWelder },
  ),
  withState("countAllWelds", "setCountAllWelds", 0),
  withHandlers({
    handleSubmit: props => () => {
      props.addDataWelder(props.match.params.id, {
        countAllWelds: props.countAllWelds
      })
    }
  }),
  lifecycle({
    componentDidMount(props) {
      //console.log(this.props.math.params.id);
      //this.props.getWeldersList(this.props.filter);
    }
  })
)(View);