import React from 'react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { Header, Icon, Modal } from 'semantic-ui-react';
import moment from 'moment';

import 'leaflet/dist/leaflet.css';
import L from "leaflet";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});


export default function AutographMapDialog(props) {
    return (
        <Modal
            open={props.open}
            onClose={() => props.setOpen(false)}
            onOpen={() => props.setOpen(true)}
        >
            <Header as='h2'>
                {props.CurrLocation}
                <Header.Subheader>
                    {`Данный получены: ${moment(props.LastData).add(5, 'h').format('LLL')}`}
                </Header.Subheader>
                <Header.Subheader><Icon name='signal' /> {props.signalGSM + " S/N " + props.serial}</Header.Subheader>  
            </Header>

            <Modal.Content image>
                <Modal.Description>
                    <MapContainer style={{ minHeight: '400px', width: '100%' }} center={[props.lat, props.lng]} zoom={13} scrollWheelZoom={false}>
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={[props.lat, props.lng]} />
                    </MapContainer>
                </Modal.Description>
            </Modal.Content>
        </Modal>
    )
}