import React, { useState } from 'react'
import { Button, Container, Dropdown, Form, Header, Icon, Menu, TextArea } from 'semantic-ui-react'

/*
PROPS

    header
    route
    setRoute(name, value)
    createSettlement(name)
    isLoadingSettlements
    listSettlements
*/

function WaybillRouteForm(props) { 
    const [searchQuery, setSearchQuery] = useState('');

    return (
        <Container>
            <Menu secondary>
                <Header as='h2'>{ props.header }</Header>
            </Menu>
            <Form>
                <Form.Input
                    type='date'
                    label='Дата:'
                    placeholder='Дата:'
                    name='date'
                    value={props.route.date}
                    onChange={(e, {name, value}) => props.setRoute(name, value)}
                />
                <Form.Field >
                    <Dropdown
                            label='Место отправления'
                            name='id_settlement_from'
                            value={props.route.id_settlement_from}
                            fluid
                            search
                            selection
                            placeholder='Место отправления'
                            noResultsMessage={
                                <>
                                    <label>Список пуст. </label>
                                    { searchQuery !== '' && <Button basic onClick={() => props.createSettlement(searchQuery, 'id_settlement_from')}><Icon name='plus' />Добавить '{searchQuery}'</Button>}
                                </>
                            }
                            loading={props.isLoadingSettlements}
                            options={props.listSettlements.map(item => {
                                return ({
                                    key: item.id,
                                    value: item.id,
                                    text: item.name
                                })
                            })}
                            onChange={(e, {name, value }) => { 
                                props.setRoute(name, value); 
                            }}
                            onSearchChange={(e, { searchQuery }) => setSearchQuery(searchQuery)}
                            value={props.route.id_settlement_from}
                        />
                </Form.Field>
                <Form.Field >
                    <Dropdown
                            label='Место назначения'
                            name='id_settlement_to'
                            value={props.route.id_settlement_to}
                            fluid
                            search
                            selection
                            placeholder='Место назначения'
                            noResultsMessage={
                                <>
                                    <label>Список пуст. </label>
                                    { searchQuery !== '' && <Button basic onClick={() => props.createSettlement(searchQuery , 'id_settlement_to')}><Icon name='plus' />Добавить '{searchQuery}'</Button>}
                                </>
                            }
                            loading={props.isLoadingSettlements}
                            options={props.listSettlements.map(item => {
                                return ({
                                    key: item.id,
                                    value: item.id,
                                    text: item.name
                                })
                            })}
                            onChange={(e, {name, value}) => { 
                                props.setRoute(name, value); 
                            }}
                            onSearchChange={(e, { searchQuery }) => setSearchQuery(searchQuery)}
                            value={props.route.id_settlement_to}
                        />
                </Form.Field>
                <label>Цель поездки:</label>
                <TextArea
                    name='description'
                    value={props.route.description}
                    onChange={(e, {name, value}) => props.setRoute(name, value)}
                />
                <Form.Input
                    label='Пройдено, км:'
                    name='mileage'
                    value={props.route.mileage}
                    onChange={(e, {name, value}) => props.setRoute(name, value)}
                />
                
	            <label>Время выезда:</label>
                <Form.Field>
                    <Form.Input
                        label='Час:'
                        name='time_from_hour'
                        value={props.route.time_from_hour}
                        onChange={(e, {name, value}) => props.setRoute(name, value)}
                    />
                    <Form.Input
                        label='Мин:'
                        name='time_from_min'
                        value={props.route.time_from_min}
                        onChange={(e, {name, value}) => props.setRoute(name, value)}
                    />
                </Form.Field>
                <label>Время возвращения:</label>
                <Form.Field>
                    <Form.Input
                        label='Час:'
                        name='time_to_hour'
                        value={props.route.time_to_hour}
                        onChange={(e, {name, value}) => props.setRoute(name, value)}
                    />
                    <Form.Input
                        label='Мин:'
                        name='time_to_min'
                        value={props.route.time_to_min}
                        onChange={(e, {name, value}) => props.setRoute(name, value)}
                    />
                </Form.Field>
                <Button color='red' onClick={()=>{props.handleCancel()}} floated='left'><Icon name='cancel'/>Отмена</Button>
                <Button type='submit' onClick={()=>{props.handleSubmit()}} floated='right'><Icon name='save'/>Применить</Button>
            </Form>
        </Container>     
    )
}

export default WaybillRouteForm