import React from 'react'
import { Button, Container, Form, Header, Icon, Menu } from 'semantic-ui-react'

function AddDataWelder(props) { 
    return (
        <Container>
            <Menu secondary>
                <Menu.Menu position='right'>
                    <Menu.Item>
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
            <Menu secondary>
                <Header as='h2'>Добавление</Header>
            </Menu>
            <Form>
                <Form.Input
                    label='Общее кол-во сварок:'
                    placeholder='Общее кол-во сварок:'
                    name='countAllWelds'
                    value={props.countAllWelds}
                    onChange={(e, {name, value}) => props.setCountAllWelds(value)}
                />
                <Button type='submit' onClick={()=>{props.handleSubmit()}}><Icon name='save'/>Применить</Button>
            </Form>
        </Container>     
    )
}

export default AddDataWelder