import axios from 'axios';
import { GET_LIST_REQUEST, GET_LIST_SUCCESS, GET_LIST_FAILURE } from "../Constants/AutographDevices";

export const getAutographDevicesList = (filter = {
  page: 1
}, all=false) => dispatch => {
  dispatch({ type: GET_LIST_REQUEST });
  axios.get(`/api/autograph-devices`, {
    params: {
      page: filter.page,
      all:all
    }
  }).then(res => {
    dispatch({
      type: GET_LIST_SUCCESS,
      data: res.data
    });
  }).catch(function (error) {
    if (error.request.status === 401) {
      localStorage.removeItem("user");
      window.location.href = '/login'
    }
    dispatch({ type: GET_LIST_FAILURE });
  });
};
