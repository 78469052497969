import React from 'react';
import Dropzone, { useDropzone } from 'react-dropzone';
import { Button, Header, Icon, Image, List, Message, Modal, Segment } from 'semantic-ui-react';
import NoImage from '../../../../Components/Images/no-image.png'
import AxiosImage from '../../Components/Attachments/View/AxiosImage'

import SwiperCore, { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

SwiperCore.use([Navigation, Pagination, A11y]);

export default function PhotoDialog(props) {
  return (
    <Modal
      basic
      size='fullscreen'
      open={props.openPhotoDialog}
      onClose={() => props.setOpenPhotoDialog(false)}
      onOpen={() => props.setOpenPhotoDialog(true)}
    >
      <Modal.Content>
        <Modal.Description>
          {props.error && props.error.list &&
            <Message
              error
              header='Произошла ошибка:'
              list={props.error.list}
            />
          }
          {props.openAddPhotoDialog ?
            <>

              <Segment placeholder textAlign='center' secondary>
                <Dropzone
                  multiple
                  accept="image/*"
                  onDrop={acceptedFiles => {
                    var formData = new FormData();

                    acceptedFiles.map((file, index) => {
                      formData.append(`photos[${index}]`, file);
                    });
                    props.setPhotos({
                      ...props.photos,
                      data: formData
                    });
                  }}>
                  {({ acceptedFiles, getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Header icon>
                          <Icon name='photo' />
                      Перетащите фото сюда, или нажмите для выбора...
                    </Header>
                      </div>
                      <List>
                        {acceptedFiles.map(file => (
                          <List.Item>
                            <List.Header>{file.path}</List.Header>
                            <List.Description>
                              {file.size} байт
                      </List.Description>
                          </List.Item>
                        ))
                        }
                      </List>
                    </section>
                  )}
                </Dropzone>
              </Segment>
              <Button negative floated='left' onClick={() => props.setOpenAddPhotoDialog(false)}>
                Отмена
            </Button>
              <Button primary floated='right' onClick={() => props.handleAttachPhoto()}>
                Сохранить
            </Button>

            </>
            :
            <>
              <Segment basic>
                <Swiper
                  navigation
                  pagination={{ clickable: true }}
                  spaceBetween={50}
                  slidesPerView={1}
                >
                  {props.photos.vehicle && !!props.photos.vehicle.photos && props.photos.vehicle.photos.length > 0 ?
                    props.photos.vehicle.photos.map(photo =>
                      <SwiperSlide>
                        <AxiosImage
                          id={photo.id}
                          openingOnClick = {true}
                        />
                      </SwiperSlide>
                    ) : <SwiperSlide><Image src={NoImage} centered /></SwiperSlide>
                  }
                </Swiper>
              </Segment>
              <Button floated='right' onClick={() => props.setOpenAddPhotoDialog(true)} primary>
                Загрузить...
              </Button>
            </>
          }
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>

      </Modal.Actions>
    </Modal>
  )
}