import axios from 'axios';
import { GET_LIST_REQUEST, GET_LIST_SUCCESS, GET_LIST_FAILURE } from "../Constants/Permissions";

export const getPermissionsList = (id, filter = {
    page: 1
  }) => dispatch => {
    dispatch({type: GET_LIST_REQUEST});
    axios.get(`/api/permissions`, {
      params: {
        page: filter.page,
      }
    }).then(res => {
          dispatch({
            type: GET_LIST_SUCCESS, 
            data: res.data
          });
        }).catch(function (error) {
          if(error.request.status === 401){
            localStorage.removeItem("user");
            window.location.href = '/login'
          }
          dispatch({type: GET_LIST_FAILURE});
      });
  };