import axios from 'axios';
import {
  GET_LIST_REQUEST,
  GET_LIST_SUCCESS,
  GET_LIST_FAILURE,
  ADD_REQUEST,
  ADD_SUCCESS,
  ADD_FAILURE,
  EDIT_REQUEST,
  EDIT_SUCCESS,
  EDIT_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_FAILURE,
  GET_ITEM_SUCCESS, GET_ITEM_REQUEST, GET_ITEM_FAILURE
} from '../Constants/Organizations';

  export const getOrganization = (id) => dispatch => {
    dispatch({type: GET_ITEM_REQUEST});
    axios.get(`/api/organizations/`+id)
    .then(res => {
          dispatch({
            type: GET_ITEM_SUCCESS, 
            data: res.data
          });
        }).catch(function (error) {
          if(error.response && error.response.data)
            dispatch({type: GET_ITEM_FAILURE, error: error.response.data});
          if(error.request.status === 401){
            localStorage.removeItem("user");
            window.location.href = '/login'
          }
      });
  };

export const getOrganizationsList = (filter = {
  sort: {
    column: 'name',
    direction: 'ascending'
  }
}, all=false) => dispatch => {
  dispatch({type: GET_LIST_REQUEST});
  axios.get(`/api/organizations`, {
    params: {
      sortColumn: filter.sort.column,
      sortDirection: filter.sort.direction,
      all: all
    }
  }).then(res => {
        dispatch({
          type: GET_LIST_SUCCESS, 
          data: res.data
        });
      }).catch(function (error) {
        if(error.request.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        dispatch({type: GET_LIST_FAILURE});
    });
};

export const addOrganization = (data) => dispatch => {
  dispatch({type: ADD_REQUEST});
  return axios.post('/api/organizations', data)
      .then(res => {
        dispatch({type: ADD_SUCCESS, item: res.data});
        return res
      }).catch(function (error) {
        if(error.response && error.response.data)
          dispatch({type: ADD_FAILURE, error: error.response.data});
        if(error.response && error.response.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        return error.response
    });
};

export const deleteOrganization = (id) => dispatch => {
  dispatch({ type: DELETE_REQUEST });
  axios.delete('/api/organizations/'+id)
      .then(res => {
        dispatch({ type: DELETE_SUCCESS, id: id });
      }).catch(function (error) {
        if(error.request.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        dispatch({ type: DELETE_FAILURE });
    });
};

export const editOrganization = (item) => dispatch => {
  dispatch({type: EDIT_REQUEST});
  axios.put('/api/organizations/'+item.id, item)
      .then(res => {
        dispatch({type: EDIT_SUCCESS});
        window.location.href = '/';
      }).catch(function (error) {
        dispatch({type: EDIT_FAILURE});
        if(error.request.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        //dispatch(addDeviceFailure());
    });
};
