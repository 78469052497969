import React from 'react'
import { Button, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react'

function Error(props) {
  return (
  <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
    <Grid.Column style={{ maxWidth: 450 }}>
        <Message negative>
          <Message.Header>404</Message.Header>
          <p>Страница не существует</p>
        </Message>
    </Grid.Column>
  </Grid>
)
  }

export default Error