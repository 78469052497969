import {
    GET_WELDERS_LIST_REQUEST,
    GET_WELDERS_LIST_SUCCESS,
    GET_WELDERS_LIST_FAILURE,
    ADD_DATA_WELDERS_REQUEST,
    ADD_DATA_WELDERS_SUCCESS,
    ADD_DATA_WELDERS_FAILURE,
    ADD_WELDER_REQUEST,
    ADD_WELDER_SUCCESS,
    ADD_WELDER_FAILURE,
    EDIT_WELDER_SUCCESS,
    DELETE_WELDER_REQUEST,
    DELETE_WELDER_SUCCESS,
    DELETE_WELDER_FAILURE,
    GET_WELDER_SUCCESS, GET_WELDER_REQUEST, GET_WELDER_FAILURE
  } from '../Constants/Welders';
  
  export const initialState = {
    current_page: 1,
    current: null,
    list: [],
    total: 0,
    isLoading: false,
    error: null
  };
  
  export default function WeldersReducer(state = initialState, action) {
    switch (action.type) {
      case GET_WELDER_SUCCESS:
        return {
          ...state,
          current: action.data,
          isLoading: false
        };
      case GET_WELDER_REQUEST:
        return {
          ...state,
          isLoading: true
        };
      case GET_WELDER_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.data.error
        };
 
      case GET_WELDERS_LIST_SUCCESS:
        return {
          ...state,
          current_page: action.data.current_page,
          list: action.data.data,
          total: action.data.total,
          isLoading: false
        };
      case GET_WELDERS_LIST_REQUEST:
        return {
          ...state,
          isLoading: true
        };
      case GET_WELDERS_LIST_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.data.error
        };
      case ADD_DATA_WELDERS_SUCCESS:
        return {
          ...state,
          isLoading: false
        };
      case ADD_DATA_WELDERS_REQUEST:
        return {
          ...state,
          isLoading: true
        };
      case ADD_DATA_WELDERS_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: true
        };
      case ADD_WELDER_SUCCESS:
        state.list.push(action.user);
        return {
          ...state,
          list: state.list,
          isLoading: false
        };
      case ADD_WELDER_FAILURE:
        return {
          ...state,
          error: action.error,
          isLoading: false
        };
      case EDIT_WELDER_SUCCESS:
        return {
          ...state,
          //list: state.list.map( user => (user.id == action.user.id)?action.user:user ),
          isLoading: false
        };
      case DELETE_WELDER_SUCCESS:
        var dev = [];
        state.list.map(item => {
          if (Number(item.id) !== Number(action.id))
            dev.push(item);
        });
        return {
          ...state,
          isLoading: false,
          list: dev,
        };
      default:
        return state;
    }
  }
  