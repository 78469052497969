
import {
  GET_LIST_REQUEST,
  GET_LIST_SUCCESS,
  GET_LIST_FAILURE,
  GET_ITEM_REQUEST,
  GET_ITEM_SUCCESS,
  GET_ITEM_FAILURE,
  ADD_REQUEST,
  ADD_SUCCESS,
  ADD_FAILURE,
  EDIT_REQUEST,
  EDIT_SUCCESS,
  EDIT_FAILURE,
  DELETE_SUCCESS
} from '../Constants/Users';

export const initialState = {
  current_page: 1,
  last_page: 1,
  current: null,
  list: [],
  total: 0,
  isLoading: false,
  isLoadingItem: false,
  error: null
};

export default function UsersReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_SUCCESS:
      return {
        ...state,
        current_page: action.data.current_page,
        last_page: action.data.last_page,
        list: action.data.data,
        total: action.data.total,
        isLoading: false
      };
    case GET_LIST_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case GET_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.data.error
      };

    case GET_ITEM_SUCCESS:
      return {
        ...state,
        current: action.data,
        isLoadingItem: false
      };
    case GET_ITEM_REQUEST:
      return {
        ...state,
        isLoadingItem: true
      };
    case GET_ITEM_FAILURE:
      return {
        ...state,
        isLoadingItem: false,
        error: action.data.error
      };

    case ADD_SUCCESS:
      state.list.push(action.item);
      return {
        ...state,
        list: state.list,
        isLoading: false
      };
    case ADD_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false
      };

    case DELETE_SUCCESS:
      var dev = [];
      state.list.map(user => {
        if (Number(user.id) !== Number(action.id))
          dev.push(user);
      });
      return {
        ...state,
        isLoading: false,
        list: dev,
      };
    
    default:
      return state;
  }
}
