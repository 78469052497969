import {
    GET_LIST_REQUEST,
    GET_LIST_SUCCESS,
    GET_LIST_FAILURE,
    ADD_WAYBILL_REQUEST,
    ADD_WAYBILL_SUCCESS,
    ADD_WAYBILL_FAILURE,
} from '../Constants/Waybills';
  
  export const initialState = {
    current_page: 1,
    last_page: 1,
    current: null,
    list: [],
    total: 0,
    isLoading: false,
    isLoadingItem: false,
    error: null
  };
  
  export default function WaybillsReducer(state = initialState, action) {
    switch (action.type) {  
      case GET_LIST_SUCCESS:
        return {
          ...state,
          current_page: action.data.current_page,
          list: action.data.data,
          total: action.data.total,
          last_page: action.data.last_page,
          isLoading: false
        };
      case GET_LIST_REQUEST:
        return {
          ...state,
          isLoading: true
        };
      case GET_LIST_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.data.error
        };

      case ADD_WAYBILL_SUCCESS:
        return {
          ...state,
          isLoading: false
        };
      case ADD_WAYBILL_REQUEST:
        return {
          ...state,
          isLoading: true
        };
      case ADD_WAYBILL_FAILURE:
        return {
          ...state,
          error: action.error,
          isLoading: false
        };

      default:
        return state;
    }
  }
  