import React from 'react'
import { Button, Form, Message, Icon, Container, Header, List, Segment, Divider, Pagination, Card, Item } from 'semantic-ui-react'
import moment from 'moment';
import WaybillAutographData from '../../WaybillAutographData';
import Axios from 'axios';

function WaybillsTable(props) {
    return (
        <Segment.Group>
            <Message
                attached
                header={
                    props.openFilter ?
                        <>
                            <Message.Header>
                                <Button circular color='red' icon='cancel' onClick={() => props.setOpenFilter(false)} floated='right' />
                                    Путевые листы
                            </Message.Header>
                            <Form size="mini" >
                                <Form.Group widths='equal'>
                                    <label>Дата</label>
                                    <Form.Input
                                        type='date'
                                        label='c:'
                                        name='dtfrom'
                                        value={props.filter.dtfrom}
                                        onChange={(e, { name, value }) => props.setFilter({
                                            ...props.filter,
                                            [name]: value
                                        })}
                                    />
                                    <Form.Input
                                        type='date'
                                        label='до:'
                                        name='dtto'
                                        value={props.filter.dtto}
                                        onChange={(e, { name, value }) => props.setFilter({
                                            ...props.filter,
                                            [name]: value
                                        })}
                                    />

                                </Form.Group>
                                <Button color='blue' icon='search' type='submit' onClick={() => { props.getWaybillsList(props.id, props.filter) }}>Поиск</Button>
                                <Divider hidden />
                            </Form>
                        </>
                        :
                        <>
                            <Message.Header>
                                <Button circular color='blue' icon='filter' onClick={() => props.setOpenFilter(true)} floated='right' />
                                <Button icon labelPosition='left' color='blue' size='mini' onClick={() => {
                                    Axios.get(`${process.env.REACT_APP_API_URL}/api/vehicles/${props.id}/waybills/download`, {
                                        method: 'GET',
                                        responseType: 'blob',
                                        params: {
                                            dtfrom: props.filter.dtfrom,
                                            dtto: props.filter.dtto,
                                        }
                                    }).then((response) => {
                                        const url = window.URL.createObjectURL(new Blob([response.data]));
                                        const link = document.createElement('a');
                                        link.href = url;
                                        link.setAttribute('download', `отчет.xlsx`); //or any other extension
                                        document.body.appendChild(link);
                                        link.click();
                                    });
                                }
                                }>
                                    <Icon name='file word' />
                                Загрузить
                            </Button>
                                    Путевые листы
                            </Message.Header>
                            Всего: {props.total}
                        </>
                }
            />
            { props.error && props.error.list &&
                <Message
                    error attached
                    header='Произошла ошибка:'
                    list={props.error.list}
                />
            }

            { (props.total == 0) &&
                <Segment textAlign='center' loading={props.isLoading} >
                    <Header icon>
                        <Icon name='ban' />
                        Список пуст.
                    </Header>
                </Segment>
            }


            {props.list && (props.total != 0) &&
                <>
                    <Segment loading={props.isLoading} >
                        {props.list.map(item =>
                            <Card fluid>
                                <Card.Content header={
                                    <List horizontal>
                                        <List.Item>
                                            <strong>id: </strong>
                                            {item.id}
                                        </List.Item>
                                        <List.Item>
                                            <strong>Дата: </strong>
                                            {moment(item.date).format('L')}
                                        </List.Item>
                                        <List.Item>
                                            <strong>Период: </strong>
                                            { (moment(moment(item.date).format('0000-MM-DD')).isBetween(item.norm.winter_to, item.norm.winter_from, undefined, '[]')) ? 'летний' : 'зимний'}
                                        </List.Item>
                                        <List.Item>
                                            <strong>Ответственный: </strong>
                                            {item.user.name}
                                        </List.Item>
                                    </List>
                                } />
                                <Card.Content description={
                                    <>
                                        <List horizontal relaxed>
                                            <List.Item>
                                                <strong>Показания спидометра до выезда: </strong>
                                                {item.odometer_start} км
                                </List.Item>
                                            <List.Item>
                                                <strong>Показания спидометра въезд: </strong>
                                                {item.odometer_end} км
                                </List.Item>
                                            <List.Item>
                                                <strong>Пробег: </strong>
                                                {item.odometer_end - item.odometer_start} км
                                </List.Item>
                                        </List>

                                        <List horizontal>
                                            <List.Item>
                                                <strong>Заправка: </strong>
                                                {item.fuel_add} л
                                </List.Item>
                                            {props.permissions.indexOf('read-calc-norm-waybill') != -1 &&
                                                <>
                                                    <List.Item>
                                                        <strong>По норме: </strong>
                                                        { (moment(moment(item.date).format('0000-MM-DD')).isBetween(item.norm.winter_to, item.norm.winter_from, undefined, '[]')) ?
                                                            (item.is_city == 0) ? (item.odometer_end - item.odometer_start) * item.norm.winter_city / 100 : (item.odometer_end - item.odometer_start) * item.norm.summer_city / 100
                                                            : (item.is_city == 0) ? (item.odometer_end - item.odometer_start) * item.norm.winter_highway / 100 : (item.odometer_end - item.odometer_start) * item.norm.winter_city / 100
                                                        }
                                                    </List.Item>
                                                    <List.Item>
                                                        <strong>Факт: </strong>
                                                        {item.fuel_start + item.fuel_add - item.fuel_end}
                                                    </List.Item>
                                                    <List.Item>
                                                        <strong>Отклонения: </strong>
                                                        {(moment(moment(item.date).format('0000-MM-DD')).isBetween(item.norm.winter_to, item.norm.winter_from, undefined, '[]')) ?
                                                            (item.is_city == 0) ?
                                                                ((item.fuel_start + item.fuel_add - item.fuel_end) - ((item.odometer_end - item.odometer_start) * item.norm.winter_city / 100)) 
                                                                : ((item.fuel_start + item.fuel_add - item.fuel_end) - (item.odometer_end - item.odometer_start) * item.norm.summer_city / 100)
                                                            : (item.is_city == 0) ? 
                                                                ((item.fuel_start + item.fuel_add - item.fuel_end) - ((item.odometer_end - item.odometer_start) * item.norm.winter_highway / 100)) 
                                                                : ((item.fuel_start + item.fuel_add - item.fuel_end) - ((item.odometer_end - item.odometer_start) * item.norm.winter_city / 100))
                                                        }
                                                    </List.Item>
                                                </>
                                            }

                                        </List>

                                        <List horizontal>
                                            <List.Item>
                                                <strong>Остаток начальный: </strong>
                                                {item.fuel_start} л
                                    </List.Item>
                                            <List.Item>
                                                <strong>Остаток конечный: </strong>
                                                {item.fuel_end} л
                                    </List.Item>
                                        </List>

                                        <Divider />
                                        <List horizontal>
                                            <List.Item>
                                                <strong>Цель поездки: </strong>
                                                {item.comment}
                                            </List.Item>
                                        </List>
                                        {props.showAutographData && props.permissions.indexOf('read-device-data-autograph') != -1 &&
                                            <>
                                                <Divider />
                                                <List.Item>
                                                    <strong>Autograph: </strong>
                                                    <WaybillAutographData id={item.id} />
                                                </List.Item>
                                            </>
                                        }
                                        {item.route_list && item.route_list.length != 0 &&
                                            <>
                                                <Divider />
                                                <List>
                                                    {item.route_list &&
                                                        item.route_list.map(route =>
                                                            <List.Item>
                                                                <List.Content>
                                                                    <List.Header>
                                                                        {`${route.date} (${route.time_from_hour}:${route.time_from_min} - ${route.time_to_hour}:${route.time_to_min}) ${route.settlement_from.name}-${route.settlement_to.name} ${route.mileage} км. `}

                                                                    </List.Header>
                                                                    <List.Description>{route.description}</List.Description>

                                                                </List.Content>
                                                            </List.Item>
                                                        )
                                                    }
                                                </List>
                                            </>
                                        }
                                    </>
                                } />
                                <Card.Content extra>
                                    <Button icon labelPosition='left' color='blue' size='mini' onClick={() => {
                                        Axios({
                                            url: `${process.env.REACT_APP_API_URL}/api/waybills/${item.id}/download`,
                                            method: 'GET',
                                            responseType: 'blob'
                                        }).then((response) => {
                                            const url = window.URL.createObjectURL(new Blob([response.data]));
                                            const link = document.createElement('a');
                                            link.href = url;
                                            link.setAttribute('download', `${moment(item.date).format('L')}.docx`); //or any other extension
                                            document.body.appendChild(link);
                                            link.click();
                                        });
                                    }
                                    }>
                                        <Icon name='file word' />
                                Загрузить
                            </Button>
                                </Card.Content>
                            </Card>

                        )}

                    </Segment>
                    <Message
                        attached='bottom'
                    >
                        <Container textAlign='center'>
                            <Pagination
                                activePage={props.filter.page}
                                totalPages={props.last_page}
                                onPageChange={props.handleWaybillsPaginationChange}
                                pointing
                                secondary
                            />
                        </Container>
                    </Message>
                </>
            }
        </Segment.Group>
    )
}

export default WaybillsTable