export const GET_VEHICLES_LIST_REQUEST = "VEHICLES/GET_LIST_REQUEST";
export const GET_VEHICLES_LIST_SUCCESS = "VEHICLES/GET_LIST_SUCCESS";
export const GET_VEHICLES_LIST_FAILURE = "VEHICLES/GET_LIST_FAILURE";

export const GET_VEHICLE_REQUEST = "VEHICLES/GET_REQUEST";
export const GET_VEHICLE_SUCCESS = "VEHICLES/GET_SUCCESS";
export const GET_VEHICLE_FAILURE = "VEHICLES/GET_FAILURE";

export const ADD_VEHICLE_REQUEST = "VEHICLES/ADD_REQUEST";
export const ADD_VEHICLE_SUCCESS = "VEHICLES/ADD_SUCCESS";
export const ADD_VEHICLE_FAILURE = "VEHICLES/ADD_FAILURE";

export const DELETE_VEHICLE_REQUEST = "VEHICLES/DELETE_REQUEST";
export const DELETE_VEHICLE_SUCCESS = "VEHICLES/DELETE_SUCCESS";
export const DELETE_VEHICLE_FAILURE = "VEHICLES/DELETE_FAILURE";

export const EDIT_VEHICLE_REQUEST = "VEHICLES/EDIT_REQUEST";
export const EDIT_VEHICLE_SUCCESS = "VEHICLES/EDIT_SUCCESS";
export const EDIT_VEHICLE_FAILURE = "VEHICLES/EDIT_FAILURE";

export const ADD_REPAIR_REQUEST = "VEHICLES/ADD_REPAIR_REQUEST";
export const ADD_REPAIR_SUCCESS = "VEHICLES/ADD_REPAIR_SUCCESS";
export const ADD_REPAIR_FAILURE = "VEHICLES/ADD_REPAIR_FAILURE";

export const ADD_MAINTENANCE_REQUEST = "VEHICLES/ADD_MAINTENANCE_REQUEST";
export const ADD_MAINTENANCE_SUCCESS = "VEHICLES/ADD_MAINTENANCE_SUCCESS";
export const ADD_MAINTENANCE_FAILURE = "VEHICLES/ADD_MAINTENANCE_FAILURE";

export const ATTACH_REQUEST = "VEHICLES/ATTACH_REQUEST";
export const ATTACH_SUCCESS = "VEHICLES/ATTACH_SUCCESS";
export const ATTACH_FAILURE = "VEHICLES/ATTACH_FAILURE";