import { compose, lifecycle, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import { getVehicleCommentsList, addVehicleComment } from '../../../../Store/Actions/Comments';

import View from './View';

export default compose(
    connect(
        state => ({
            isAuthenticated: state.auth.isAuthenticated,
            list: state.comments.list,
            isLoading: state.comments.isLoading,
            total: state.comments.total,
            current_page: state.comments.current_page,
            last_page: state.comments.last_page,
            error: state.comments.error
        }), { getVehicleCommentsList, addVehicleComment },
    ),
    withState("filter", "setFilter", {page: 1}),
    withState("isOnDrag", "setIsOnDrag", false),
    withState("comment", "setComment", {
        parentItem: null,
        attachments: null,
        message: ''
    }),
    
    withHandlers({
        handleSubmitFilter: props => () => {
        },
        handleAddComment: props => () => {
            var formData = new FormData();
            if(!!props.comment.attachments && props.comment.attachments.length!=0)
                props.comment.attachments.map((file, index) => {
                    formData.append(`attachments[${index}]`, file);
                });
            if(props.comment.parentItem && props.comment.parentItem.id)
                formData.append('id_parent', props.comment.parentItem.id);
            formData.append('message', props.comment.message);
            
            props.addVehicleComment(props.id, formData).then((ret) => {
                if(ret.status == 200) {props.getVehicleCommentsList(props.id, props.filter)}
            });
            props.setComment({
                parentItem: null,
                message: ''
            });
        },
        handlePaginationChange: props => (e, { activePage }) => {
            props.setFilter({...props.filter, page: activePage})
            props.getVehicleCommentsList(props.id, {...props.filter, page: activePage})
        }
    }),
    lifecycle({
        componentDidMount() {
            if(this.props.id){
                this.props.getVehicleCommentsList(this.props.id, this.props.filter)
            } 
        }
    })
)(View);