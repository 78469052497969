import React from 'react'
import { Breadcrumb, Button, Checkbox, Container, Form, Header, Icon, List, Menu, Message, Modal, Segment, TextArea } from 'semantic-ui-react'
import WaybillRouteForm from './WaybillRouteForm'

function AddDataWelder(props) {
    return (
        <Container>
            <Menu secondary>
                <Breadcrumb>
                    <Breadcrumb.Section link href="/">Главная</Breadcrumb.Section>
                    <Breadcrumb.Divider />
                    <Breadcrumb.Section link href="/vehicles">Транспорт</Breadcrumb.Section>
                    <Breadcrumb.Divider />
                    <Breadcrumb.Section active>Добавление путевого листа</Breadcrumb.Section>
                </Breadcrumb>
            </Menu>
            {props.error && props.error.list &&
                <Message
                    attached
                    error
                    header='Произошла ошибка:'
                    list={props.error.list}
                />
            }
            <Form>
                <Form.Input
                    type='date'
                    label='Дата:'
                    placeholder='Дата:'
                    name='date'
                    value={props.waybill.date}
                    onChange={(e, { name, value }) => props.setWaybill({
                        ...props.waybill,
                        [name]: value
                    })}
                />
                <Form.Input
                    label='Показания одометра на конец дня (км.):'
                    placeholder='Км.:'
                    name='odometer'
                    value={props.waybill.odometer}
                    onChange={(e, { name, value }) => props.setWaybill({
                        ...props.waybill,
                        [name]: value
                    })}
                />
               
               <Form.Input
                    label='Фактический расход топлива(л.):'
                    placeholder='Л.:'
                    name='fuel'
                    value={props.waybill.fuel}
                    onChange={(e, { name, value }) => props.setWaybill({
                        ...props.waybill,
                        [name]: value
                    })}
                />
                <Form.Input
                    label='Холостой ход(час.):'
                    placeholder='Час.:'
                    name='idle'
                    value={props.waybill.idle}
                    onChange={(e, { name, value }) => props.setWaybill({
                        ...props.waybill,
                        [name]: value
                    })}
                />
                <Form.Input
                    label='Заправка (л.):'
                    placeholder='л.'
                    name='fuel_add'
                    value={props.waybill.fuel_add}
                    onChange={(e, { name, value }) => props.setWaybill({
                        ...props.waybill,
                        [name]: value
                    })}
                />
                <label>Цель поездки:</label>
                <TextArea
                    label='Цель поездки:'
                    name='comment'
                    value={props.waybill.comment}
                    onChange={(e, { name, value }) => props.setWaybill({
                        ...props.waybill,
                        [name]: value
                    })}
                />
                <Form.Field>
                    <Checkbox
                        radio
                        label='Город'
                        name='isCity'
                        onChange={(e, { name, value }) => props.setWaybill({
                            ...props.waybill,
                            [name]: true
                        })}
                        checked={props.waybill.isCity}
                    />
                    <Checkbox
                        radio
                        label='Трасса'
                        name='isCity'
                        onChange={(e, { name, value }) => props.setWaybill({
                            ...props.waybill,
                            [name]: false
                        })}
                        checked={!props.waybill.isCity}
                    />
                </Form.Field>

                <Segment.Group>
                    <Message
                        attached
                        header={
                            <>
                                <Message.Header>
                                    <Button circular color='blue' icon='plus' onClick={() => {
                                        props.setNewRoute({
                                            id: props.waybill.route_list.length + 1,
                                            date: new Date().getTime(),
                                            id_settlement_from: null,
                                            id_settlement_to: null,
                                            description: '',
                                            mileage: 0,
                                            time_from_hour: 0,
                                            time_from_min: 0,
                                            time_to_hour: 0,
                                            time_to_min: 0
                                        });
                                        props.getSettlementsList(); props.setOpenWaybillRouteForm(true)
                                    }} floated='right' />
                                    Список
                            </Message.Header>
                            Всего: {props.waybill.route_list.length}
                            </>
                        }
                    />
                    <Segment>
                        {props.waybill.route_list.length == 0 ?
                            <Segment textAlign='center' basic >
                                <Header icon>
                                    <Icon name='ban' />
                                Список пуст.
                            </Header>
                            </Segment>
                            :
                            <List>
                                {props.waybill.route_list &&
                                    props.waybill.route_list.map(item =>
                                        <List.Item>
                                            <List.Content>
                                                <List.Header>
                                                    {`${item.date} (${item.time_from_hour}:${item.time_from_min} - ${item.time_to_hour}:${item.time_to_min}) ${props.listSettlements.filter(settl => settl.id == item.id_settlement_from)[0].name}-${props.listSettlements.filter(settl => settl.id == item.id_settlement_to)[0].name} ${item.mileage} км. `}
                                                    <Button circular color='red' icon='remove' onClick={() => {
                                                        props.setWaybill({
                                                            ...props.waybill,
                                                            route_list: props.waybill.route_list.filter(route => route.id != item.id)
                                                        });
                                                    }} floated='right' />
                                                </List.Header>
                                                <List.Description>{item.description}</List.Description>

                                            </List.Content>
                                        </List.Item>
                                    )
                                }
                            </List>
                        }
                    </Segment>
                </Segment.Group>
                { props.vehicle && props.waybill.odometer && props.vehicle.odometer &&
                    <Message>
                        <Message.Header>Расход топлива</Message.Header>
                        <Message.List>
                            { props.waybill.odometer && props.vehicle.odometer &&
                                <Message.Item>Пробег: <strong>{props.waybill.odometer-props.vehicle.odometer} км.</strong></Message.Item>
                            }
                            { props.waybill.odometer && props.vehicle.odometer && props.waybill.fuel &&
                                <Message.Item>Фактический расход на 100 км: <strong>{ (props.waybill.fuel / (props.waybill.odometer-props.vehicle.odometer)) * 100 } л.</strong></Message.Item>
                            }
                        </Message.List>
                    </Message>
                }
                <Button type='submit' onClick={() => { props.handleSubmit() }}><Icon name='save' />Применить</Button>
            </Form>
            <Modal
                onClose={() => props.setOpenWaybillRouteForm(false)}
                onOpen={() => props.setOpenWaybillRouteForm(true)}
                open={props.openWaybillRouteForm}
            >
                <Modal.Content>
                    <WaybillRouteForm
                        header="Добавить"
                        route={props.newRoute}
                        setRoute={(name, value) => { props.setNewRoute({ ...props.newRoute, [name]: value }) }}
                        createSettlement={(name, id) => {
                            props.addSettlement({ name: name })
                                .then(data => { data.status == 200 && props.setNewRoute({ ...props.newRoute, [id]: data.data.id }) })
                        }}
                        isLoadingSettlements={props.isLoadingSettlements}
                        listSettlements={props.listSettlements}
                        handleCancel={() => { props.setOpenWaybillRouteForm(false) }}
                        handleSubmit={() => {
                            props.setWaybill({
                                ...props.waybill,
                                route_list: props.waybill.route_list.concat([props.newRoute])
                            });
                            props.setOpenWaybillRouteForm(false)
                        }}
                    /></Modal.Content>
            </Modal>
        </Container>
    )
}

export default AddDataWelder