import { compose, lifecycle, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import { getVehicle, editVehicle, addVehicle } from '../../../../Store/Actions/Vehicles';
import { getUsersList } from '../../../../Store/Actions/Users';
import { getSettlementsList, addSettlement } from '../../../../Store/Actions/Settlements';
import { getOrganizationsList, addOrganization } from '../../../../Store/Actions/Organizations';
import { getAutographDevicesList } from '../../../../Store/Actions/AutographDevices';


import View from './View';

export default compose(
    connect(
        state => ({
            permissions: state.auth.permissions,
            authUserId: state.auth.id,
            isAuthenticated: state.auth.isAuthenticated,
            listUsers: state.users.list,
            isLoadingUsers: state.users.isLoading,
            isLoading: state.vehicles.isLoading,
            isLoadingItem: state.vehicles.isLoadingItem,
            listSettlements: state.settlements.list,
            isLoadingSettlements: state.settlements.isLoading,
            listOrganizations: state.organizations.list,
            isLoadingOrganizations: state.organizations.isLoading,
            listAutographDevices: state.autographDevices.list,
            isLoadingAutographDevices: state.autographDevices.isLoading,
            error: state.vehicles.error
        }), {
            getVehicle, editVehicle, addVehicle,
        getUsersList,
        getSettlementsList, addSettlement,
        getOrganizationsList, addOrganization,
        getAutographDevicesList
    },
    ),
    withState("vehicle", "setVehicle", {
        id: null,
        AutographDevice_id: null,
        vehicle_name: '',
        norm_name: '',
        fuel_type: '',
        regNumber: '',
        id_user: '',
        id_settlement: '',
        id_organization: '',
        winter_from_day: '',
        winter_from_month: '',
        winter_to_day: '',
        winter_to_month: '',
        winter_highway: '',
        winter_city: '',
        summer_highway: '',
        summer_city: '',
        idle: '',
    }),
    withState("user", "setUser", { id: null, name: null }),
    withState("autograph", "setAutograph", null),
    withState("organization", "setOrganization", { id: null, name: null }),
    withState("settlement", "setSettlement", { id: null, name: null }),
    withState("dropdownSettlements", "setDropdownSettlements", { searchQuery: '' }),
    withState("dropdownOrganizations", "setDropdownOrganizations", { searchQuery: '' }),
    withState("formType", "setFormType", "show"),
    withHandlers({
        refreshVehicle: props => (id) => {
            props.getVehicle(id).then(data => {
                if (data.status == 200) {
                    props.setVehicle({
                        id: data.data.id,
                        AutographDevice_id: data.data.AutographDevice_id,
                        vehicle_name: data.data.name,
                        fuel_type: data.data.fuel_type,
                        norm_name: data.data.norm.name,
                        regNumber: data.data.regNumber,
                        id_user: data.data.user.id,
                        id_settlement: data.data.id_settlement,
                        id_organization: data.data.id_organization,
                        winter_from_day: data.data.norm.winter_from.slice(8),
                        winter_from_month: data.data.norm.winter_from.slice(5, 7),
                        winter_to_day: data.data.norm.winter_to.slice(8),
                        winter_to_month: data.data.norm.winter_to.slice(5, 7),
                        winter_highway: data.data.norm.winter_highway,
                        winter_city: data.data.norm.winter_city,
                        summer_highway: data.data.norm.summer_highway,
                        summer_city: data.data.norm.summer_city,
                        idle: data.data.norm.idle,
                    });
                    props.setUser(data.data.user);
                    props.setAutograph(data.data.autograph_device);
                    props.setSettlement(data.data.settlement);
                    props.setOrganization(data.data.organization);
                }
            });
        },
        handleSubmit: props => () => {
            switch (props.formType) {
                case 'add':
                    props.addVehicle(props.vehicle).then(res => { if (res.status == 200 || res.status == 201) window.location.href = `/vehicles/${res.data.id}` });
                    break;
                case 'edit':
                    props.editVehicle(props.vehicle).then(res => {
                        if (res.status == 200 || res.status == 201) {
                            props.setFormType('show');
                            props.getVehicle(props.id).then(data => {
                                if (data.status == 200) {
                                    props.setVehicle({
                                        id: data.data.id,
                                        AutographDevice_id: data.data.AutographDevice_id,
                                        vehicle_name: data.data.name,
                                        fuel_type: data.data.fuel_type,
                                        norm_name: data.data.norm.name,
                                        regNumber: data.data.regNumber,
                                        id_user: data.data.user.id,
                                        id_settlement: data.data.id_settlement,
                                        id_organization: data.data.id_organization,
                                        winter_from_day: data.data.norm.winter_from.slice(8),
                                        winter_from_month: data.data.norm.winter_from.slice(5, 7),
                                        winter_to_day: data.data.norm.winter_to.slice(8),
                                        winter_to_month: data.data.norm.winter_to.slice(5, 7),
                                        winter_highway: data.data.norm.winter_highway,
                                        winter_city: data.data.norm.winter_city,
                                        summer_highway: data.data.norm.summer_highway,
                                        summer_city: data.data.norm.summer_city,
                                        idle: data.data.norm.idle,
                                    });
                                    props.setUser(data.data.user);
                                    props.setAutograph(data.data.autograph_device);
                                    props.setSettlement(data.data.settlement);
                                    props.setOrganization(data.data.organization);
                                }
                            });
                        }
                    });
                    break;
            }
        },
        createSettlement: props => () => {
            props.addSettlement({ name: props.dropdownSettlements.searchQuery }).then(
                data => { data.status == 200 && props.setVehicle({ ...props.vehicle, id_settlement: data.data.id }) }
            );
        },
        createOrganization: props => () => {
            props.addOrganization({ name: props.dropdownOrganizations.searchQuery }).then(
                data => { data.status == 200 && props.setVehicle({ ...props.vehicle, id_organization: data.data.id }) }
            );
        }
    }),
    lifecycle({
        componentDidMount() {
            if (this.props.id) {
                //this.props.getVehicle(this.props.match.params.id).then(data => {
                this.props.refreshVehicle(this.props.id);
            } else {
                this.props.setFormType('add');
            }

            this.props.getUsersList({ sort: { column: null, direction: null }, page: 1 }, true);
            this.props.getSettlementsList({ sort: { column: 'name', direction: 'ascending' } }, true);
            this.props.getAutographDevicesList({ page: 1 }, true);
            this.props.getOrganizationsList({ sort: { column: 'name', direction: 'ascending' } }, true)
        }
    })
)(View);