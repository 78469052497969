import {
    compose,
    lifecycle,
    withState,
    withHandlers
} from 'recompose';
import {
    connect
} from 'react-redux';

import {
    addAutographServer,
    editAutographServer,
    getAutographServer
} from '../../../../../Store/Actions/AutographServers';

import View from './View';

export default compose(
    connect(
        state => ({
            permissions: state.auth.permissions,
            isAuthenticated: state.auth.isAuthenticated,
            isLoadingItem: state.autographServers.isLoadingItem,
            error: state.autographServers.error
        }), {
            addAutographServer,
            editAutographServer,
            getAutographServer
        },
    ),
    withState("autographServer", "setAutographServer", {
        id: null,
        host: '',
        username: '',
        password: ''
    }),
    withState("formType", "setFormType", "show"),
    withHandlers({
        handleSubmit: props => () => {
            switch (props.formType) {
                case 'add':
                    props.addAutographServer(props.autographServer).then(res => {
                        if (res.status == 200 || res.status == 201) window.location.href = `/settings/autograph/${res.data.id}`
                    });
                    break;
                case 'edit':
                    props.editAutographServer(props.autographServer).then(res => {
                        if (res.status == 200 || res.status == 201) props.setFormType('show')
                    });
                    break;
            }
        }
    }),
    lifecycle({
        componentDidMount() {
            if (this.props.id) {
                this.props.getAutographServer(this.props.id).then(data => {
                    if (data.status == 200) {
                        this.props.setAutographServer({
                            id: data.data.id,
                            host: data.data.host,
                            username: '',
                            password: ''
                        });
                    }
                });
            } else {
                this.props.setFormType('add');
            }
        }
    })
)(View);