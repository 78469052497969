import {
  START_LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  RESET_ERROR,
  LOGIN_USER,
  SIGN_OUT_SUCCESS
} from '../Constants/Auth';



const initialState = {
  isLoading: false,
  isAuthenticated: null,
  id: null,
  name: null,
  email: null,
  token: null,
  token_type: null,
  permissions: [],
  error: null
}

export default function AuthReducer(state = initialState, { type, payload }) {
  switch (type) {
    case START_LOGIN:
      return {
        ...state,
        isLoading: true
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        id: payload.id,
        name: payload.name,
        email: payload.email,
        token: payload.access_token,
        token_type: payload.token_type,
        permissions: payload.permissions,
        error: null
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        name: null,
        email: null,
        token: null,
        token_type: null,
        permissions: [],
        error: payload.error
      };
    case RESET_ERROR:
      return {
        ...state,
        error: null
      };
    case SIGN_OUT_SUCCESS:
      return {
        isLoading: false,
        isAuthenticated: false,
        name: null,
        email: null,
        token: null,
        token_type: null,
        permissions: [],
        error: null
      };
    default:
      return state;
  }
}
