import axios from 'axios';
import { GET_LIST_REQUEST, GET_LIST_SUCCESS, GET_LIST_FAILURE, GET_ITEM_REQUEST, GET_ITEM_SUCCESS, GET_ITEM_FAILURE, ADD_REQUEST, ADD_SUCCESS, ADD_FAILURE, EDIT_REQUEST, EDIT_SUCCESS, EDIT_FAILURE, DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE } from "../Constants/AutographServers";

export const getAutographServersList = (id, filter = {
  page: 1
}) => dispatch => {
  dispatch({ type: GET_LIST_REQUEST });
  axios.get(`/api/autograph-servers`, {
    params: {
      page: filter.page,
    }
  }).then(res => {
    dispatch({
      type: GET_LIST_SUCCESS,
      data: res.data
    });
  }).catch(function (error) {
    if (error.request.status === 401) {
      localStorage.removeItem("user");
      window.location.href = '/login'
    }
    dispatch({ type: GET_LIST_FAILURE });
  });
};

export const getAutographServer = (id) => dispatch => {
  dispatch({ type: GET_ITEM_REQUEST });
  return axios.get(`/api/autograph-servers/${id}`)
    .then(res => {
      dispatch({
        type: GET_ITEM_SUCCESS,
        data: res.data
      });
      return res;
    }).catch(function (error) {
      if (error.request.status === 401) {
        localStorage.removeItem("user");
        window.location.href = '/login'
      }
      dispatch({ type: GET_ITEM_FAILURE });
      return error.request;
    });
};

export const addAutographServer = (item) => dispatch => {
  dispatch({ type: ADD_REQUEST });
  return axios.post('/api/autograph-servers', item)
    .then(res => {
      dispatch({ type: ADD_SUCCESS, item: res.data });
      return res;
    }).catch(function (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("user");
        window.location.href = '/login'
      }
      console.log(error.response)
      dispatch({ type: ADD_FAILURE, error: error.response.data });
      return error.response;
    });
};

export const editAutographServer = (item) => dispatch => {
  dispatch({ type: EDIT_REQUEST });
  return axios.put('/api/autograph-servers/' + item.id, item)
    .then(res => {
      dispatch({ type: EDIT_SUCCESS, item: res.data });
      return res;
    }).catch(function (error) {
      if (error.request.status === 401) {
        localStorage.removeItem("user");
        window.location.href = '/login'
      }
      dispatch({ type: EDIT_FAILURE });
      return error.request;
    });
};

export const deleteAutographServer = (id) => dispatch => {
  dispatch({ type: DELETE_REQUEST });
  return axios.delete('/api/autograph-servers/' + id)
    .then(res => {
      dispatch({ type: DELETE_SUCCESS, id: id });
      return res;
    }).catch(function (error) {
      if (error.request.status === 401) {
        localStorage.removeItem("user");
        window.location.href = '/login'
      }
      dispatch({ type: DELETE_FAILURE });
      return error.request;
    });
};

export const syncSchemas = (id) => dispatch => {
  dispatch({ type: EDIT_REQUEST });
  return axios.post(`/api/autograph-servers/${id}/syncSchemas`)
    .then(res => {
      dispatch({ type: EDIT_SUCCESS, item: res.data });
      return res;
    }).catch(function (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("user");
        window.location.href = '/login'
      }
      console.log(error.response)
      dispatch({ type: EDIT_FAILURE, error: error.response.data });
      return error.response;
    });
};

export const syncDevices = (id) => dispatch => {
  dispatch({ type: EDIT_REQUEST });
  return axios.post(`/api/autograph-schemas/${id}/syncDevices`)
    .then(res => {
      dispatch({ type: EDIT_SUCCESS, item: res.data });
      return res;
    }).catch(function (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("user");
        window.location.href = '/login'
      }
      console.log(error.response)
      dispatch({ type: EDIT_FAILURE, error: error.response.data });
      return error.response;
    });
};