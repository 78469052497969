import React from 'react'
import { NavLink } from 'react-router-dom'
import { Menu, Container, Button } from 'semantic-ui-react'

function CreateVehicle(props) {

  return (

    <Container>
      
      <Menu icon='labeled' secondary>
        {(props.permissions.indexOf('read-vehicle') != -1 || props.permissions.indexOf('read-own-vehicle') != -1) &&
          <Menu.Item
            as={NavLink}
            to='/welders'
            icon='table'
            name='Сварочники'
          />
        }
        {(props.permissions.indexOf('read-vehicle') != -1 || props.permissions.indexOf('read-own-vehicle') != -1) &&
          <Menu.Item
            as={NavLink}
            to='/vehicles'
            name='Транспорт'
            icon='car'
          />
        }
        {props.permissions.indexOf('read-user') != -1 &&
          <Menu.Item
            as={NavLink}
            to='/settings/users'
            icon='user'
            name='Пользователи'
          />
        }
        {props.permissions.indexOf('read-role') != -1 &&
          <Menu.Item
            as={NavLink}
            to='/settings/roles'
            icon='address card outline'
            name='Роли'
          />
        }
        {props.permissions.indexOf('read-autograph-server') != -1 &&
          <Menu.Item
            as={NavLink}
            to='/settings/autograph'
            icon='server'
            name='Autograph'
          />
        }
      </Menu>
    </Container>
  )
}

export default CreateVehicle