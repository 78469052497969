export const GET_LIST_REQUEST = "Users/GET_USERS_LIST_REQUEST";
export const GET_LIST_SUCCESS = "Users/GET_USERS_LIST_SUCCESS";
export const GET_LIST_FAILURE = "Users/GET_USERS_LIST_FAILURE";

export const GET_ITEM_REQUEST = "Users/GET_USERS_ITEM_REQUEST";
export const GET_ITEM_SUCCESS = "Users/GET_USERS_ITEM_SUCCESS";
export const GET_ITEM_FAILURE = "Users/GET_USERS_ITEM_FAILURE";

export const ADD_REQUEST = "Users/ADD_USER_REQUEST";
export const ADD_SUCCESS = "Users/ADD_USER_SUCCESS";
export const ADD_FAILURE = "Users/ADD_USER_FAILURE";

export const EDIT_REQUEST = "Users/EDIT_REQUEST";
export const EDIT_SUCCESS = "Users/EDIT_SUCCESS";
export const EDIT_FAILURE = "Users/EDIT_FAILURE";

export const DELETE_REQUEST = "Users/DELETE_USER_REQUEST";
export const DELETE_SUCCESS = "Users/DELETE_USER_SUCCESS";
export const DELETE_FAILURE = "Users/DELETE_USER_FAILURE";


