import axios from 'axios';
import {
  GET_WELDERS_LIST_REQUEST,
  GET_WELDERS_LIST_SUCCESS,
  GET_WELDERS_LIST_FAILURE,
  GET_WELDER_REQUEST,
  GET_WELDER_SUCCESS,
  GET_WELDER_FAILURE,
  ADD_DATA_WELDERS_REQUEST,
  ADD_DATA_WELDERS_SUCCESS,
  ADD_DATA_WELDERS_FAILURE,
  ADD_WELDER_REQUEST,
  ADD_WELDER_SUCCESS,
  ADD_WELDER_FAILURE,
  EDIT_WELDER_REQUEST,
  EDIT_WELDER_FAILURE,
  EDIT_WELDER_SUCCESS,
  DELETE_WELDER_REQUEST,
  DELETE_WELDER_SUCCESS,
  DELETE_WELDER_FAILURE,
} from '../Constants/Welders';

  export const getWelder = (id) => dispatch => {
    dispatch({type: GET_WELDER_REQUEST});
    return axios.get(`/api/welders/`+id)
    .then(res => {
          dispatch({
            type: GET_WELDER_SUCCESS, 
            data: res.data
          });
          return res;
        }).catch(function (error) {
          if(error.response && error.response.data)
            dispatch({type: GET_WELDER_FAILURE, error: error.response.data});
          if(error.request.status === 401){
            localStorage.removeItem("user");
            window.location.href = '/login'
          }
          return error.response
      });
  };

export const getWeldersList = (filter) => dispatch => {
  dispatch({type: GET_WELDERS_LIST_REQUEST});
  axios.get(`/api/welders`, {
    params: {
      sortColumn: filter.sort.column,
      sortDirection: filter.sort.direction,
    }
  }).then(res => {
        dispatch({
          type: GET_WELDERS_LIST_SUCCESS, 
          data: res.data
        });
      }).catch(function (error) {
        if(error.request.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        dispatch({type: GET_WELDERS_LIST_FAILURE});
    });
};

export const addDataWelder = (id, data) => dispatch => {
  dispatch({type: ADD_DATA_WELDERS_REQUEST});
  axios.post('/api/welders/'+id+'/addData', data)
      .then(res => {
        dispatch({type: ADD_DATA_WELDERS_SUCCESS, item: res.data});
        window.location.href = '/welders';
      }).catch(function (error) {
        if(error.response && error.response.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        dispatch({type: ADD_DATA_WELDERS_FAILURE});
    });
};

export const addWelder = (data) => dispatch => {
  dispatch({type: ADD_WELDER_REQUEST});
  axios.post('/api/welders', data)
      .then(res => {
        dispatch({type: ADD_WELDER_SUCCESS, item: res.data});
        window.location.href = '/welders';
      }).catch(function (error) {
        if(error.response && error.response.data)
          dispatch({type: ADD_WELDER_FAILURE, error: error.response.data});
        if(error.response && error.response.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
    });
};

export const deleteWelder = (id) => dispatch => {
  dispatch({ type: DELETE_WELDER_REQUEST });
  axios.delete('/api/welders/'+id)
      .then(res => {
        dispatch({ type: DELETE_WELDER_SUCCESS, id: id });
      }).catch(function (error) {
        if(error.request.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        dispatch({ type: DELETE_WELDER_FAILURE });
    });
};

export const editWelder = (item) => dispatch => {
  dispatch({type: EDIT_WELDER_REQUEST});
  axios.put('/api/welders/'+item.id, item)
      .then(res => {
        dispatch({type: EDIT_WELDER_SUCCESS});
        window.location.href = '/welders';
      }).catch(function (error) {
        dispatch({type: EDIT_WELDER_FAILURE});
        if(error.request.status === 401){
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        //dispatch(addDeviceFailure());
    });
};
