import React from 'react'
import { Button, Form, Message, Container, Header, Segment, Pagination, Comment, Dimmer, Loader, List, Icon } from 'semantic-ui-react'
import moment from 'moment';
import NoImage from '../../../../../Components/Images/no-image.png'
import Dropzone from 'react-dropzone';
import Axios from 'axios';

/* 
comments
*/

function Comments(props) {
    return (
        <>
            <Segment.Group>
                <Segment>
                    <Dimmer active={props.isLoading} inverted>
                        <Loader inverted />
                    </Dimmer>

                    <Comment.Group >
                        <Header as='h3' dividing>
                            Коментарии
                        </Header>

                        {props.list && props.list.map(comment =>
                            <Comment>
                                <Comment.Avatar src={NoImage} />
                                <Comment.Content>
                                    <Comment.Author as='a'>{comment.user.name}</Comment.Author>
                                    <Comment.Metadata>
                                        {moment(comment.created_at).fromNow()}
                                    </Comment.Metadata>
                                    <Comment.Text>{comment.message}</Comment.Text>

                                    {comment.attachments && comment.attachments.length != 0 &&
                                        <List relaxed divided selection>
                                            {comment.attachments.map(item =>
                                                <List.Item onClick={() => {
                                                    Axios({
                                                        url: `${process.env.REACT_APP_API_URL}/api/attachments/${item.id}`,
                                                        method: 'GET',
                                                        responseType: 'blob'
                                                    }).then((response) => {
                                                        const url = window.URL.createObjectURL(new Blob([response.data]));
                                                        const link = document.createElement('a');
                                                        link.href = url;
                                                        link.setAttribute('download', item.client_filename); //or any other extension
                                                        document.body.appendChild(link);
                                                        link.click();
                                                    });
                                                }
                                                } >
                                                    <List.Icon name='file' />
                                                    <List.Content>
                                                        <List.Header as='a' download>
                                                            {item.client_filename}

                                                        </List.Header>
                                                        <List.Description>
                                                            {item.size} байт
                                                                </List.Description>
                                                    </List.Content>
                                                </List.Item>
                                            )}
                                        </List>
                                    }
                                    <Comment.Actions>
                                        <Comment.Action onClick={() => props.setComment({
                                            ...props.comment,
                                            parentItem: comment
                                        })}>Ответить</Comment.Action>
                                    </Comment.Actions>
                                </Comment.Content>

                                {!!comment.comments && comment.comments.length != 0 &&
                                    <Comment.Group>
                                        {comment.comments.map(subcomment =>
                                            <Comment>
                                                <Comment.Avatar src={NoImage} />
                                                <Comment.Content>
                                                    <Comment.Author as='a'>{subcomment.user.name}</Comment.Author>
                                                    <Comment.Metadata>
                                                        {moment(subcomment.created_at).fromNow()}
                                                    </Comment.Metadata>
                                                    <Comment.Text>{subcomment.message}</Comment.Text>

                                                </Comment.Content>
                                            </Comment>)
                                        }
                                    </Comment.Group>
                                }
                            </Comment>
                        )}

                        <Form reply>
                            {props.comment.parentItem &&
                                <List relaxed>
                                    <List.Item>
                                        <List.Icon name='reply' />
                                        <List.Content>
                                            <List.Header as='a'>
                                                {props.comment.parentItem.user.name}
                                                <Button floated='right' basic color='red' icon='remove' onClick={() => { props.setComment({ ...props.comment, parentItem: null }) }} />
                                            </List.Header>
                                            <List.Description>
                                                {moment(props.comment.parentItem.created_at).fromNow()}
                                            </List.Description>
                                            {props.comment.parentItem.message}

                                        </List.Content>
                                    </List.Item>
                                </List>
                            }
                            <Dropzone
                                onDragEnter={() => props.setIsOnDrag(true)}
                                onDragLeave={() => props.setIsOnDrag(false)}
                                onDrop={(acceptedFiles) => {
                                    props.setComment({
                                        ...props.comment,
                                        attachments: acceptedFiles
                                    })
                                    props.setIsOnDrag(false)
                                }}
                                noClick
                            >
                                {({ getRootProps, getInputProps, open }) => (
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        {props.isOnDrag ?
                                            <div style={{ border: "4px dashed gray", textAlign: "center", padding: '15px' }}>
                                                <Header icon>
                                                    <Icon name='file' />
                                                    Перетащите сюда, или нажмите для выбора...
                                                </Header>
                                            </div>
                                            :
                                            <>
                                                <Form.TextArea rows={2}
                                                    value={props.comment.message}
                                                    onChange={(e, { value }) => {
                                                        props.setComment({
                                                            ...props.comment,
                                                            message: value
                                                        })
                                                    }}
                                                />
                                                { props.comment.attachments && props.comment.attachments.length != 0 &&
                                                    <List relaxed>
                                                        {props.comment.attachments.map(item =>
                                                            <List.Item>
                                                                <List.Icon name='file' />
                                                                <List.Content>
                                                                    <List.Header as='a'>
                                                                        {item.path}
                                                                        <Button floated='right' basic color='red' icon='remove'
                                                                            onClick={() => {
                                                                                props.setComment({ ...props.comment, attachments: props.comment.attachments.filter(file => file != item) })
                                                                            }
                                                                            } />
                                                                    </List.Header>
                                                                    <List.Description>
                                                                        {item.size} байт
                                                                </List.Description>
                                                                </List.Content>
                                                            </List.Item>
                                                        )}
                                                    </List>
                                                }
                                            </>
                                        }
                                        <Button content='Прикрепить' labelPosition='left' icon='attach' onClick={open}/>
                                        <Button
                                            content='Добавить'
                                            disabled={!props.comment.message}
                                            labelPosition='left'
                                            icon='edit' primary
                                            onClick={() => props.handleAddComment()} />
                                    </div>
                                )}


                            </Dropzone>

                        </Form>

                    </Comment.Group>
                </Segment>
                <Message
                    attached='bottom'
                >
                    <Container textAlign='center'>
                        <Pagination
                            activePage={props.filter.page}
                            totalPages={props.last_page}
                            onPageChange={props.handlePaginationChange}
                            pointing
                            secondary
                        />
                    </Container>
                </Message>
            </Segment.Group>
        </>
    )
}

export default Comments