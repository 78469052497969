import React from 'react';
import { BrowserRouter, Route, Switch, NavLink } from 'react-router-dom';

//import Header from '../../../Components/Header';

// pages
import Home from '../../../Pages/Home';

import Error404 from '../../../Pages/Error/404';
import Error403 from '../../../Pages/Error/403';

import WeldersList from '../../../Pages/Welders/List';
import WeldersAddData from '../../../Pages/Welders/AddData';
import WeldersCreate from '../../../Pages/Welders/Create';
import WeldersEdit from '../../../Pages/Welders/Edit';

import VehiclesList from '../../../Pages/Vehicles/List';
import VehiclesCreate from '../../../Pages/Vehicles/Create';
import WaybillsCreate from '../../../Pages/Vehicles/CreateWaybill';
import VehiclesItem from '../../../Pages/Vehicles/Item';

import UsersList from '../../../Pages/Settings/Users/List';
import UsersCreate from '../../../Pages/Settings/Users/Create';
import UsersItem from '../../../Pages/Settings/Users/Item';

import RolesList from '../../../Pages/Settings/Roles/List';
import RolesCreate from '../../../Pages/Settings/Roles/Create';
import RolesItem from '../../../Pages/Settings/Roles/Item';

import AutographList from '../../../Pages/Settings/Autograph/List';
import AutographCreate from '../../../Pages/Settings/Autograph/Create';
import AutographItem from '../../../Pages/Settings/Autograph/Item';
//import { ToastContainer } from "react-toastify";
//import 'react-toastify/dist/ReactToastify.css';
import { Sidebar, Menu, Icon, Accordion, Image } from 'semantic-ui-react';
import logo from '../../../Components/Images/logo.jpg'
import logoIcon from '../../../Components/Images/logo-icon.png'

const View = (props) => {
  return (
    <>
      <BrowserRouter>



        <Sidebar.Pushable page>
          <Sidebar
            as={Menu}
            animation='overlay'
            icon='labeled'
            inverted
            onHide={() => props.setSidebarOpen(false)}
            vertical
            visible={props.sidebarOpen}
            width='thin'
          >
            <Image
              fluid
              src={logo}
            />
            <Menu.Item
              as={NavLink}
              to='/'
              icon='home'
              name='Главная'
              onClick={() => props.setSidebarOpen(false)}
            />
            {(props.permissions.indexOf('read-welder') != -1 || props.permissions.indexOf('read-own-welder') != -1) &&
              <Menu.Item
                as={NavLink}
                to='/welders'
                icon='table'
                name='Сварочники'
                onClick={() => props.setSidebarOpen(false)}
              />
            }
            {(props.permissions.indexOf('read-vehicle') != -1 || props.permissions.indexOf('read-own-vehicle') != -1) &&
              <Menu.Item
                as={NavLink}
                to='/vehicles'
                name='Транспорт'
                icon='car'
                onClick={() => props.setSidebarOpen(false)}
              />
            }
            {(props.permissions.indexOf('read-user') != -1 || props.permissions.indexOf('read-role') != -1) &&
              <Menu.Item>
                <Accordion>
                  <Accordion.Title
                    active={props.activeIndexAccordion === 1}
                    index={1}
                    onClick={() => { props.setActiveIndexAccordion((props.activeIndexAccordion === 1) ? 0 : 1) }}
                  >
                    <Menu.Item
                      icon='settings'
                      name='Настройки'
                    />
                  </Accordion.Title>
                  <Accordion.Content active={props.activeIndexAccordion === 1}>
                    <Menu.Menu>
                      {props.permissions.indexOf('read-user') != -1 &&
                        <Menu.Item
                          as={NavLink}
                          to='/settings/users'
                          icon='user'
                          name='Пользователи'
                          onClick={() => props.setSidebarOpen(false)}
                        />
                      }
                      {props.permissions.indexOf('read-role') != -1 &&
                        <Menu.Item
                          as={NavLink}
                          to='/settings/roles'
                          icon='address card outline'
                          name='Роли'
                          onClick={() => props.setSidebarOpen(false)}
                        />
                      }
                      {props.permissions.indexOf('read-autograph-server') != -1 &&
                        <Menu.Item
                          as={NavLink}
                          to='/settings/autograph'
                          icon='server'
                          name='Autograph'
                          onClick={() => props.setSidebarOpen(false)}
                        />
                      }
                    </Menu.Menu>
                  </Accordion.Content>

                </Accordion>

              </Menu.Item>
              
            }
          </Sidebar>

          <Sidebar.Pusher dimmed={props.sidebarOpen} style={{ minHeight: "100vh" }}>
            <Menu following bar light fixed='top'>

              <Menu.Item
                onClick={() => props.setSidebarOpen(true)}
                active={false}
              >
                <Icon name='bars' />
              </Menu.Item>

              <Menu.Item fitted>
                <img src={logoIcon} />
              </Menu.Item>

              <Menu.Menu position='right'>
                <Menu.Item
                  onClick={() => props.signOut()}
                  name='Выход'
                />
              </Menu.Menu>
            </Menu>
            <Menu secondary>

            </Menu>
            <React.Fragment>
              <Switch>
                <Route exact path="/" component={Home} />

                <Route exact path="/welders" component={(props.permissions.indexOf('read-welder') != -1 || props.permissions.indexOf('read-own-welder') != -1) ? WeldersList : Error403} />
                <Route exact path="/welders/create" component={(props.permissions.indexOf('create-welder') != -1) ? WeldersCreate : Error403} />
                <Route exact path="/welders/addData/:id" component={(props.permissions.indexOf('add-data-welder') != -1 || props.permissions.indexOf('add-data-own-welder') != -1) ? WeldersAddData : Error403} />
                <Route exact path="/welders/edit/:id" component={(props.permissions.indexOf('update-welder') != -1 || props.permissions.indexOf('update-own-welder') != -1) ? WeldersEdit : Error403} />

                <Route exact path="/vehicles" component={(props.permissions.indexOf('read-vehicle') != -1 || props.permissions.indexOf('read-own-vehicle') != -1) ? VehiclesList : Error403} />
                <Route exact path="/vehicles/create" component={(props.permissions.indexOf('create-vehicle') != -1) ? VehiclesCreate : Error403} />
                <Route exact path="/vehicles/:id" component={(props.permissions.indexOf('read-vehicle') != -1 || props.permissions.indexOf('read-own-vehicle') != -1) ? VehiclesItem : Error403} />
                <Route exact path="/vehicles/:id/waybills/create" component={(props.permissions.indexOf('create-waybill') != -1 || props.permissions.indexOf('create-own-waybill') != -1) ? WaybillsCreate : Error403} />

                <Route exact path="/settings/users" component={(props.permissions.indexOf('read-user') != -1) ? UsersList : Error403} />
                <Route exact path="/settings/users/create" component={(props.permissions.indexOf('create-user') != -1) ? UsersCreate : Error403} />
                <Route exact path="/settings/users/:id" component={(props.permissions.indexOf('read-user') != -1) ? UsersItem : Error403} />

                <Route exact path="/settings/roles" component={(props.permissions.indexOf('read-role') != -1) ? RolesList : Error403} />
                <Route exact path="/settings/roles/create" component={(props.permissions.indexOf('create-role') != -1) ? RolesCreate : Error403} />
                <Route exact path="/settings/roles/:id" component={(props.permissions.indexOf('read-role') != -1) ? RolesItem : Error403} />

                <Route exact path="/settings/autograph" component={(props.permissions.indexOf('read-autograph-server') != -1) ? AutographList : Error403} />
                <Route exact path="/settings/autograph/create" component={(props.permissions.indexOf('create-autograph-server') != -1) ? AutographCreate : Error403} />
                <Route exact path="/settings/autograph/:id" component={(props.permissions.indexOf('read-autograph-server') != -1) ? AutographItem : Error403} />

                <Route component={Error404} />
              </Switch>
            </React.Fragment>
          </Sidebar.Pusher>
        </Sidebar.Pushable>



      </BrowserRouter>
    </>
  )
};

export default View;
