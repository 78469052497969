import axios from 'axios';
import {
    GET_LIST_REQUEST,
    GET_LIST_SUCCESS,
    GET_LIST_FAILURE,
    ADD_WAYBILL_REQUEST,
    ADD_WAYBILL_SUCCESS,
    ADD_WAYBILL_FAILURE,
  } from '../Constants/Waybills';

  export const getWaybillsList = (id, filter) => dispatch => {
    dispatch({type: GET_LIST_REQUEST});
    axios.get(`/api/vehicles/${id}/waybills`, {
      params: {
        page: filter.page,
        dtfrom: filter.dtfrom,
        dtto: filter.dtto,
      }
    }).then(res => {
          dispatch({
            type: GET_LIST_SUCCESS, 
            data: res.data
          });
        }).catch(function (error) {
          if(error.request.status === 401){
            localStorage.removeItem("user");
            window.location.href = '/login'
          }
          dispatch({type: GET_LIST_FAILURE});
      });
  };
    
  export const getWaybillAutographData = (id) => dispatch => {
    //dispatch({type: GET_VEHICLE_REQUEST});
    return axios.get(`api/waybills/${id}/autograph-data`)
    .then(res => {
          /*dispatch({
            type: GET_VEHICLE_SUCCESS, 
            data: res.data
          });*/
          return res;
        }).catch(function (error) {
          //if(error.response && error.response.data)
            //dispatch({type: GET_VEHICLE_FAILURE, error: error.response.data});
          if(error.response.status === 401){
            localStorage.removeItem("user");
            window.location.href = '/login'
          }
          return error.response;
      });
  };

  export const createWaybill = (id, data) => dispatch => {
    dispatch({type: ADD_WAYBILL_REQUEST});
    axios.post('/api/vehicles/'+id+'/waybills', data)
        .then(res => {
          dispatch({type: ADD_WAYBILL_SUCCESS, item: res.data});
          window.location.href = '/vehicles';
        }).catch(function (error) {
          if(error.response && error.response.status === 401){
            localStorage.removeItem("user");
            window.location.href = '/login'
          }
          dispatch({type: ADD_WAYBILL_FAILURE, error: error.response.data});
      });
  };