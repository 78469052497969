import React from 'react'
import { Button, Form, Grid, Header, Image, Message, Segment, Table, Icon, Card, Label, Input, Select, Dropdown, Dimmer, Loader, Confirm, Menu, Modal, List, Breadcrumb } from 'semantic-ui-react'
import { Link, NavLink } from 'react-router-dom';
import moment from 'moment';

const LoginForm = (props) => {
  var date = new Date(); // сегодня
  var dtms = date.valueOf();
  var datered = new Date(dtms - ((24 * 60 * 60 * 1000) * 7));
  return (
    <>
      <Dimmer.Dimmable as={Segment} dimmed={props.isLoading} page>
        <Dimmer active={props.isLoading} inverted>
          <Loader></Loader>
        </Dimmer>

        <Breadcrumb>
          <Breadcrumb.Section link href="/">Главная</Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active>Сварочники</Breadcrumb.Section>
        </Breadcrumb>

        <Menu secondary>
          {props.permissions.indexOf('create-welder') != -1 &&
            <Menu.Menu position='left'>
              <Menu.Item
                as={NavLink}
                to='welders/create'
                name='Добавить'
              />
            </Menu.Menu>
          }

          <Menu.Menu position='right'>
            <Menu.Item>
              <Dropdown floating inline pointing options={[
                { key: 'name', text: 'Наименование', value: 'name' },
                { key: 'serial', text: 'S/N', value: 'serial' },
                { key: 'countAllWelds', text: 'Общее кол-во сварок', value: 'countAllWelds' },
                { key: 'growth', text: 'Прирост', value: 'growth' },
                { key: 'updated_at', text: 'Дата внесения информации', value: 'updated_at' },
                { key: 'last_updated_at', text: 'Дата предпоследнего внесения информации', value: 'last_updated_at' }
              ]}
                onChange={(e, data) => { props.handleSortTableColumn(data.value) }}
                defaultValue='updated_at' />
              <Dropdown floating inline pointing options={[
                { key: 'ascending', text: <Icon name='sort content ascending' />, value: 'ascending' },
                { key: 'descending', text: <Icon name='sort content descending' />, value: 'descending' }
              ]}
                onChange={(e, data) => { props.handleSortTableDir(data.value) }}
                defaultValue='ascending' />
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        {!!props.error &&
          <Message negative compact stackable>
            <Message.Header>{props.error}</Message.Header>
          </Message>
        }

        <Card.Group centered doubling stackable itemsPerRow={4}>
          {props.list.map(welder => (
            <Card color={new Date(welder.updated_at) < datered ? 'red' : ''}>
              <Card.Content>
                <Card.Header>
                  {new Date(welder.updated_at) < datered &&
                    <Icon style={{ color: 'red' }} name='attention' />
                  }
                  {welder.name + " S/N " + welder.serial}

                  <Button.Group color='blue' floated='right'>
                    {((props.permissions.indexOf('add-data-welder') != -1) || (props.authUserId == welder.user.id && props.permissions.indexOf('add-data-own-welder') != -1)) &&
                      <Button icon='file'
                        as={NavLink}
                        welder={welder}
                        to={"/welders/addData/" + welder.id}
                      />
                    }
                    <Dropdown className='button icon' floating>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => { props.getWelder(welder.id); props.setOpenHistoryDialog(true) }}
                          text='История'
                          icon='archive'
                        />
                        {((props.permissions.indexOf('update-welder') != -1) || (props.authUserId == welder.user.id && props.permissions.indexOf('update-own-welder') != -1)) &&
                          <Dropdown.Item as={NavLink} to={"/welders/edit/" + welder.id} text='Редактировать' icon='edit' />
                        }
                        {((props.permissions.indexOf('update-welder') != -1) || (props.authUserId == welder.user.id && props.permissions.indexOf('update-own-welder') != -1)) &&
                          <Dropdown.Item style={{ color: 'red' }} text='Удалить' icon='delete' onClick={() => { props.setOpenConfirmDeleteDialog(true); props.setDeletedId(welder.id) }} />
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                  </Button.Group>
                </Card.Header>
                <Card.Meta>Общее кол-во сварок {welder.countAllWelds}. Прирост {welder.growth}</Card.Meta>
                <Card.Description>
                  <p><strong>Дата внесения информации</strong> {`${moment(welder.updated_at).format("DD.MM.yyyy")} (${moment(welder.updated_at).fromNow()})`} </p>
                  <p><strong>Дата предпоследнего внесения информации</strong> {moment(welder.last_updated_at).format("DD.MM.yyyy")} </p>
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <Label>
                  <Icon name='user' /> {welder.user.name}
                </Label>
              </Card.Content>
            </Card>
          ))}
        </Card.Group>

        <Confirm
          open={props.openConfirmDeleteDialog}
          content="Вы уверены?"
          cancelButton='Отмена'
          confirmButton="Удалить"
          onCancel={() => props.setOpenConfirmDeleteDialog(false)}
          onConfirm={() => { props.deleteWelder(props.deletedId); props.setOpenConfirmDeleteDialog(false); }}
        />

        <Modal
          open={props.openHistoryDialog}
          onClose={() => props.setOpenHistoryDialog(false)}
          onOpen={() => props.setOpenHistoryDialog(true)}
        >
          <Modal.Header>История</Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              {props.isLoading &&
                <Dimmer active inverted>
                  <Loader inverted content='Loading' />
                </Dimmer>
              }
              {props.current && props.current.history &&
                <List divided relaxed>
                  {props.current.history.map(item =>
                    <List.Item>
                      <List.Content>
                        <List.Header>{item.user.name + ' ' + item.created_at}</List.Header>
                        {item.message}
                      </List.Content>
                    </List.Item>
                  )}
                </List>
              }
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => props.setOpenHistoryDialog(false)} primary>
              Закрыть
        </Button>
          </Modal.Actions>
        </Modal>

      </Dimmer.Dimmable>
    </>
  )
}

export default LoginForm