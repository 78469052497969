import { combineReducers } from 'redux';

import auth from './Reducers/Auth'
import users from './Reducers/Users'
import welders from './Reducers/Welders'
import vehicles from './Reducers/Vehicles'
import comments from './Reducers/Comments'
import waybills from './Reducers/Waybills'
import settlements from './Reducers/Settlements'
import organizations from './Reducers/Organizations'
import history from './Reducers/History'
import roles from './Reducers/Roles'
import permissions from './Reducers/Permissions'
import autographServers from './Reducers/AutographServers'
import autographDevices from './Reducers/AutographDevices'

export default combineReducers({
  auth,
  users,
  welders,
  vehicles,
  comments,
  waybills,
  settlements,
  organizations,
  history,
  roles,
  permissions,
  autographServers,
  autographDevices
});