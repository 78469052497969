export const GET_WELDERS_LIST_REQUEST = "Welders/GET_LIST_REQUEST";
export const GET_WELDERS_LIST_SUCCESS = "Welders/GET_LIST_SUCCESS";
export const GET_WELDERS_LIST_FAILURE = "Welders/GET_LIST_FAILURE";

export const GET_WELDER_REQUEST = "Welders/GET_REQUEST";
export const GET_WELDER_SUCCESS = "Welders/GET_SUCCESS";
export const GET_WELDER_FAILURE = "Welders/GET_FAILURE";

export const ADD_DATA_WELDERS_REQUEST = "Welders/ADD_DATA_REQUEST";
export const ADD_DATA_WELDERS_SUCCESS = "Welders/ADD_DATA_SUCCESS";
export const ADD_DATA_WELDERS_FAILURE = "Welders/ADD_DATA_FAILURE";

export const ADD_WELDER_REQUEST = "Welders/ADD_REQUEST";
export const ADD_WELDER_SUCCESS = "Welders/ADD_SUCCESS";
export const ADD_WELDER_FAILURE = "Welders/ADD_FAILURE";

export const DELETE_WELDER_REQUEST = "Welders/DELETE_REQUEST";
export const DELETE_WELDER_SUCCESS = "Welders/DELETE_SUCCESS";
export const DELETE_WELDER_FAILURE = "Welders/DELETE_FAILURE";

export const EDIT_WELDER_REQUEST = "Welders/EDIT_REQUEST";
export const EDIT_WELDER_SUCCESS = "Welders/EDIT_SUCCESS";
export const EDIT_WELDER_FAILURE = "Welders/EDIT_FAILURE";