import React from 'react'
import { Button, Form, Menu, Dropdown, Message, Icon, Container, Header, Breadcrumb } from 'semantic-ui-react'

function CreateWelder(props) { 
    return (
        <Container>
            <Menu secondary>
            <Breadcrumb>
          <Breadcrumb.Section link href="/">Главная</Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section  link href="/welders">Сварочники</Breadcrumb.Section>
          <Breadcrumb.Section  active>Добавление</Breadcrumb.Section>
        </Breadcrumb>
            </Menu>
            <Menu secondary>
                <Header as='h2'>Добавление</Header>
            </Menu>
            { props.error && props.error.list &&
                <Message
                    error
                    header='Произошла ошибка:'
                    list={props.error.list}
                />
            }
            <Form>
                <Form.Input
                    label='Наименование:'
                    placeholder='Наименование:'
                    name='name'
                    value={props.welder.name}
                    onChange={(e, {name, value}) => props.setWelder({ ...props.welder, [name]: value })}
                />
                <Form.Input
                    label='Серийный номер:'
                    placeholder='S/N:'
                    name='serial'
                    value={props.welder.serial}
                    onChange={(e, {name, value}) => props.setWelder({ ...props.welder, [name]: value })}
                />
                <Form.Input>
                    <Dropdown 
                        loading={props.isLoadingUsers} 
                        selection 
                        header="Ответственный:"
                        placeholder="Ответственный:"
                        text={props.user && props.user.name} 
                        fluid
                    >
                        <Dropdown.Menu>
                            {props.listUsers &&
                                props.listUsers.map(user => 
                                    <Dropdown.Item
                                        selected = {props.welder.id_user==user.id}
                                        text={user.name}
                                        value={user.id}
                                        onClick={(e, data) => {
                                                props.setWelder({
                                                    ...props.welder,
                                                    id_user: data.value
                                                });
                                                props.setUser(user)
                                            }
                                        }
                                    />
                                )
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </Form.Input>
                <Button type='submit' onClick={()=>{props.handleSubmit()}}><Icon name='save'/>Применить</Button>
            </Form>
        </Container>     
    )
}

export default CreateWelder