import React from 'react';
import { Button, Dimmer, List, Loader, Modal } from 'semantic-ui-react';

export default function HistoryDialog(props) {
  return (
    <Modal
      open={props.openHistoryDialog}
      onClose={() => props.setOpenHistoryDialog(false)}
      onOpen={() => props.setOpenHistoryDialog(true)}
    >
      <Modal.Header>История</Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          {props.isLoading &&
            <Dimmer active inverted>
              <Loader inverted content='Loading' />
            </Dimmer>
          }
          {props.current && props.current.history &&
            <List divided relaxed>
              {props.current.history.map(item =>
                <List.Item>
                  <List.Content>
                    <List.Header>{item.user.name + ' ' + item.created_at}</List.Header>
                    {item.message}
                  </List.Content>
                </List.Item>
              )}
            </List>
          }
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => props.setOpenHistoryDialog(false)} primary>
          Закрыть
        </Button>
      </Modal.Actions>
    </Modal>
  )
}