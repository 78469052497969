import {
    GET_LIST_REQUEST,
    GET_LIST_SUCCESS,
    GET_LIST_FAILURE
  } from '../Constants/History';
  
  export const initialState = {
    current_page: 1,
    last_page: 1,
    list: [],
    total: 0,
    isLoading: false,
    error: {
        message: null,
        list: null
    }
  };
  
  export default function SettlementsReducer(state = initialState, action) {
    switch (action.type) {
      case GET_LIST_SUCCESS:
        return {
          ...state,
          current_page: action.data.current_page,
          last_page: action.data.last_page,
          list: action.data.data,
          total: action.data.total,
          isLoading: false
        };
      case GET_LIST_REQUEST:
        return {
          ...state,
          isLoading: true
        };
      case GET_LIST_FAILURE:
        return {
          ...state,
          current_page: 1,
          last_page: 1,
          list: [],
          total: 0,
          isLoading: false,
          error: action.data.error
        };
      default:
        return state;
    }
  }
  