import React from 'react'
import { Container, Header, Table } from 'semantic-ui-react'



function DiffTable(props) {

    function getAllObjects(old_value, new_value) {
        var arr = [];
        if (!!old_value)
            arr = arr.concat(Object.keys(old_value))
        if (!!new_value)
            arr = arr.concat(Object.keys(new_value))
        arr = arr.filter((item, pos) => arr.indexOf(item) === pos)
        return arr
    }

    function getAllObjectsFiled(old_value, new_value, objName) {
        var arr = [];
        if (!!old_value && old_value[objName])
            arr = arr.concat(Object.keys(old_value[objName]))
        if (!!new_value && new_value[objName])
            arr = arr.concat(Object.keys(new_value[objName]))
        arr = arr.filter((item, pos) => arr.indexOf(item) === pos)
        return arr
    }

    function checkObj(oldObj, newObj) {
        if (!oldObj && !newObj) return false;
        if (!!oldObj && !newObj) return true;
        if (!oldObj && !!newObj) return true;

        getAllObjects(oldObj, newObj).map(name => {
            if (oldObj[name] !== newObj[name]) return false;
        })
        return true
    }

    function getObjectLabel(name) {
        switch (name) {
            case 'norm':
                return 'Норма'
            case 'vehicle':
                return 'Транспорт'
            case 'settlement':
                return 'Населенный пункт'
            case 'user':
                return 'Ответственный'
            default:
                return name
        }
    }

    function getFiledLabel(objName, filedName) {
        switch (objName) {
            case 'norm':
                switch (filedName) {
                    case 'name':
                        return 'Наименование'
                    case 'winter_from':
                        return 'Зима c'
                    case 'winter_to':
                        return 'Зима до'
                    case 'winter_city':
                        return 'B зимний период л/100 км в городском цикле'
                    case 'winter_highway':
                        return 'B зимний период л/100 км в загородном цикле'
                    case 'summer_city':
                        return 'B летний период л/100 км в городском цикле'
                    case 'summer_highway':
                        return 'B летний период л/100 км в загородном цикле'
                    default:
                        return filedName
                };
            case 'vehicle':
                switch (filedName) {
                    case 'name':
                        return 'Наименование'
                    case 'regNumber':
                        return 'Рег. номер'
                    default:
                        return filedName
                };
            case 'settlement':
                switch (filedName) {
                    case 'name':
                        return 'Наименование'
                    default:
                        return filedName
                };
            case 'user':
                switch (filedName) {
                    case 'name':
                        return 'Имя'
                    default:
                        return filedName
                };
            default:
                return filedName
        }
    }

    function in_array(value, array) {
        for (var i = 0; i < array.length; i++) {
            if (value == array[i]) return true;
        }
        return false;
    }

    function tableRow(objName, filedName) {
        var notShow = ['id', 'updated_at', 'created_at', 'id_user', 'id_settlement', 'id_norm', 'user', 'settlement', 'norm', 'email_verified_at', 'is_admin', 'fuel', 'odometer', 'deleted_at'];
        return (
            !in_array(filedName, notShow) &&
            <Table.Row>
                <Table.Cell>
                    {getFiledLabel(objName, filedName)}
                </Table.Cell>
                {props.old_value &&
                    <Table.Cell negative>
                        {props.old_value[objName] && props.old_value[objName][filedName] && typeof props.old_value[objName][filedName] !== 'object' &&
                            props.old_value[objName][filedName]
                        }
                    </Table.Cell>
                }
                {props.new_value &&
                    <Table.Cell positive>
                        {props.new_value[objName] && props.new_value[objName][filedName] && typeof props.new_value[objName][filedName] !== 'object' &&
                            props.new_value[objName][filedName]
                        }
                    </Table.Cell>
                }
            </Table.Row>
        )
    }

    var allObj = getAllObjects(props.old_value, props.new_value).filter(item => checkObj(props.old_value, props.new_value));
    return (
        <Container textAlign='center'>
            { allObj.map(objName => (
                <>
                    <Header>
                        {getObjectLabel(objName)}
                    </Header>

                    <Table basic='very' celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Поле</Table.HeaderCell>
                                {props.old_value &&
                                    <Table.HeaderCell>Старое значение</Table.HeaderCell>
                                }
                                {props.new_value &&
                                    <Table.HeaderCell>Новое значение</Table.HeaderCell>
                                }
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {getAllObjectsFiled(props.old_value, props.new_value, objName).map(filedName => (
                                tableRow(objName, filedName)
                            ))}
                        </Table.Body>
                    </Table>
                </>
            )
            )}
        </Container>
    )
}

export default DiffTable