import React from 'react'
import { Message, Card, Loader } from 'semantic-ui-react'

function WaybillAutographData(props) {
    return (
        <>
        { props.error &&
                <Message
                    error attached
                    header='Произошла ошибка:'
                >
                    {props.error}
                </Message>
            }

            <Loader active={props.isLoading} />

                {props.autographData &&
                  <>
                            <Card.Header>{`${props.autographData.TotalDistance} км.`}</Card.Header>
                            <Card.Meta>{`${props.autographData.FirstLocation} - ${props.autographData.LastLocation}`}</Card.Meta>
                    </>
                }
            
        </>
    )
}

export default WaybillAutographData