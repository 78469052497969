import { compose, lifecycle, withState } from 'recompose';
import { connect } from 'react-redux';

import { signOut } from '../../Store/Actions/Auth';

import LayoutView from './Components';


export default compose(
  connect(
    state => ({
      //isSidebarOpened: state.layout.isSidebarOpened,
      isAuthenticated: state.auth.isAuthenticated,
      permissions: state.auth.permissions
    }), { signOut },
  ),
  withState("sidebarOpen", "setSidebarOpen", false),
  withState("activeIndexAccordion", "setActiveIndexAccordion", 0)
)(LayoutView);