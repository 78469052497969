import {
    GET_LIST_REQUEST,
    GET_LIST_SUCCESS,
    GET_LIST_FAILURE,
    ADD_REQUEST,
    ADD_SUCCESS,
    ADD_FAILURE,
    EDIT_SUCCESS,
    DELETE_REQUEST,
    DELETE_SUCCESS,
    DELETE_FAILURE,
    GET_ITEM_SUCCESS, GET_ITEM_REQUEST, GET_ITEM_FAILURE
  } from '../Constants/Organizations';
  
  export const initialState = {
    current_page: 1,
    current: null,
    list: [],
    total: 0,
    isLoading: false,
    error: null
  };
  
  export default function OrganizationsReducer(state = initialState, action) {
    switch (action.type) {
      case GET_ITEM_SUCCESS:
        return {
          ...state,
          current: action.data,
          isLoading: false
        };
      case GET_ITEM_REQUEST:
        return {
          ...state,
          isLoading: true
        };
      case GET_ITEM_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.data.error
        };
 
      case GET_LIST_SUCCESS:
        return {
          ...state,
          current_page: action.data.current_page,
          list: action.data.data,
          total: action.data.total,
          isLoading: false
        };
      case GET_LIST_REQUEST:
        return {
          ...state,
          isLoading: true
        };
      case GET_LIST_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.data.error
        };
      case ADD_SUCCESS:
        state.list.push(action.item);
        return {
          ...state,
          list: state.list,
          isLoading: false
        };
      case ADD_FAILURE:
        return {
          ...state,
          error: action.error,
          isLoading: false
        };
      case EDIT_SUCCESS:
        return {
          ...state,
          //list: state.list.map( user => (user.id == action.user.id)?action.user:user ),
          isLoading: false
        };
      case DELETE_SUCCESS:
        var dev = [];
        state.list.map(item => {
          if (Number(item.id) !== Number(action.id))
            dev.push(item);
        });
        return {
          ...state,
          isLoading: false,
          list: dev,
        };
      default:
        return state;
    }
  }
  