import { compose, lifecycle, withState } from 'recompose';
import { connect } from 'react-redux';

import ViewComponent from './Components';

export default compose(
  connect(
    state => ({
      isAuthenticated: state.auth.isAuthenticated,
      permissions: state.auth.permissions
    }),// { signOut },
  ),
  lifecycle({
    componentDidMount() {
      
    }
  })
)(ViewComponent);