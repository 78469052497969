export const GET_LIST_REQUEST = "Roles/GET_LIST_REQUEST";
export const GET_LIST_SUCCESS = "Roles/GET_LIST_SUCCESS";
export const GET_LIST_FAILURE = "Roles/GET_LIST_FAILURE";

export const GET_ITEM_REQUEST = "Roles/GET_ITEM_REQUEST";
export const GET_ITEM_SUCCESS = "Roles/GET_ITEM_SUCCESS";
export const GET_ITEM_FAILURE = "Roles/GET_ITEM_FAILURE";

export const ADD_REQUEST = "Roles/ADD_REQUEST";
export const ADD_SUCCESS = "Roles/ADD_SUCCESS";
export const ADD_FAILURE = "Roles/ADD_FAILURE";

export const EDIT_REQUEST = "Roles/EDIT_REQUEST";
export const EDIT_SUCCESS = "Roles/EDIT_SUCCESS";
export const EDIT_FAILURE = "Roles/EDIT_FAILURE";

export const DELETE_REQUEST = "Roles/DELETE_REQUEST";
export const DELETE_SUCCESS = "Roles/DELETE_SUCCESS";
export const DELETE_FAILURE = "Roles/DELETE_FAILURE";